import React, { useState } from 'react';
import { PiFilePdf, PiX, PiCheck, PiWarningCircle } from "react-icons/pi";

const InformacoesGerais = ({ evento, outrosGastos, comprovanteInscricao, comprovanteProgramacao, correcoesFeedback, updateCorrecoesFeedback, statusPeticao, verificado, verificado_comprovante_incricao, verificado_comprovante_programacao }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTipo, setModalTipo] = useState(null);

  const abreModal = (content, type) => {
    setModalContent(content);
    setModalTipo(type);
    setShowModal(true);
  }

  const closeModal = (feedbackType) => {
    if (feedbackType) {
      updateCorrecoesFeedback(feedbackType);
    }
    setModalContent(null);
    setModalTipo(null);
    setShowModal(false);
  }

  const calcularNumeroDias = (dataInicial, dataFinal) => {
    const umDia = 24 * 60 * 60 * 1000;
    
    const normalizarData = (data) => {
      const novaData = new Date(data);
      novaData.setHours(0, 0, 0, 0);
      return novaData;
    };

    const primeiraData = normalizarData(dataInicial);
    const segundaData = normalizarData(dataFinal);

    const diffDias = Math.round(Math.abs((segundaData - primeiraData) / umDia)) + 1;
    return diffDias;
  }

  const calcularCustoTotal = () => {
    const numeroDias = calcularNumeroDias(evento.data_inicial, evento.data_final);
    const custoDeslocamento = parseFloat(evento.custo_deslocamento) || 0.00;
    const custoInscricao = parseFloat(evento.custo_inscricao) || 0.00;
    const custoHospedagem = parseFloat(evento.custo_hospedagem) || 0.00;
    const custoAlimentacao = parseFloat(evento.custo_alimentacao) || 0.00;
    const outrosGastosValor = parseFloat(outrosGastos.valor) || 0.00;
    
    const custoTotal = (numeroDias * custoDeslocamento) +
                      (numeroDias * custoInscricao) +
                      (numeroDias * custoHospedagem) +
                      (numeroDias * custoAlimentacao) +
                      (numeroDias * outrosGastosValor);
    
    return custoTotal.toFixed(2);
  }

  const formatarData = (data) => {
    const dataFormatada = new Date(data + 'T00:00:00'); // Adiciona 'T00:00:00' para garantir que a data seja interpretada corretamente
    const options = { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'UTC' };
    return dataFormatada.toLocaleDateString('pt-BR', options);
  }

  const formatarDinheiro = (valor) => {
    return parseFloat(valor).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });
  }

  const custoTotal = calcularCustoTotal();
  
  return (
    <div className='flex flex-col bg-white h-auto my-3 pt-5 px-12 rounded-lg shadow-[0px_0px_10px_7px_rgba(0,0,0,0.2)]'>
      <div>
        <div className='flex flex-row justify-center w-fit gap-2'>
          <h1 className='bg-[#0DB30B] py-1 px-20 rounded-lg text-white text-center ml-10'>Informações gerais </h1>
          {(correcoesFeedback.includes('Informações gerais e/ou custos do evento') || verificado === false) && <PiWarningCircle color='red' size={25} className='mt-1'/>}
        </div>
        <h1 className='font-bold -ml-7 mt-3'>Nome do evento:<span className='font-normal'> {evento.nome}</span></h1>
        <h1 className='font-bold -ml-7 mt-3 mb-2'>Data do evento:<span className='font-normal'> {formatarData(evento.data_inicial)} até {formatarData(evento.data_final)}</span></h1>
      </div>
      <div className='border-b border-gray-300 -ml-10 -mr-10'></div>
      <div>
        <div className='flex flex-row justify-center w-fit gap-2'>
          <h1 className='bg-[#0DB30B] py-1 px-14 rounded-lg text-white mt-3 text-center ml-10'>Custos do evento ({calcularNumeroDias(evento.data_inicial, evento.data_final)} dias) </h1>
          {(correcoesFeedback.includes('Informações gerais e/ou custos do evento') || verificado === false) && <PiWarningCircle color='red' size={25} className='mt-[14px]'/>}
        </div>
        <h1 className='font-bold flex flex-row justify-end mr-3 mt-1'>Valor <span className='ml-12'> Subtotal</span></h1>
        <h1 className='font-bold -ml-7 mt-1 flex flex-row items-center'>Custo com inscrição:<span className='ml-[45px] mr-4 bg-gray-300 border-gray-400 border rounded-md p-2 w-24 flex flex-row'> {formatarDinheiro(evento.custo_inscricao || 0)}</span> <span className='bg-gray-300 border-gray-400 border rounded-md p-2 w-24'>{formatarDinheiro(evento.custo_inscricao) || 0}</span></h1>
        <h1 className='font-bold -ml-7 mt-3 flex flex-row items-center'>Custo com hospedagem:<span className='ml-[14px] mr-4 bg-gray-300 border-gray-400 border rounded-md p-2 w-24 flex flex-row'> {formatarDinheiro(evento.custo_hospedagem || 0)}</span> <span className='bg-gray-300 border-gray-400 border rounded-md p-2 w-24'>{formatarDinheiro(evento.custo_hospedagem * calcularNumeroDias(evento.data_inicial, evento.data_final) || 0)}</span></h1>
        <h1 className='font-bold -ml-7 mt-3 flex flex-row items-center'>Custo com alimentação:<span className='ml-[20px] mr-4 bg-gray-300 border-gray-400 border rounded-md p-2 w-24 flex flex-row'> {formatarDinheiro(evento.custo_alimentacao || 0)}</span> <span className='bg-gray-300 border-gray-400 border rounded-md p-2 w-24'>{formatarDinheiro(evento.custo_alimentacao * calcularNumeroDias(evento.data_inicial, evento.data_final) || 0)}</span></h1>
        <h1 className='font-bold -ml-7 mt-3 flex flex-row items-center'>Custo com deslocamento:<span className='ml-[6px] mr-4 bg-gray-300 border-gray-400 border rounded-md p-2 w-24 flex flex-row'> {formatarDinheiro(evento.custo_deslocamento || 0)}</span> <span className='bg-gray-300 border-gray-400 border rounded-md p-2 w-24'>{formatarDinheiro(evento.custo_deslocamento * calcularNumeroDias(evento.data_inicial, evento.data_final) || 0)}</span></h1>
        <h1 className='font-bold -ml-7 mt-3 flex flex-row items-center'>Custo com outros gastos:<span className='ml-[9px] mr-4 bg-gray-300 border-gray-400 border rounded-md p-2 w-24 flex flex-row'> {formatarDinheiro(outrosGastos.valor || 0)}</span> <span className='bg-gray-300 border-gray-400 border rounded-md p-2 w-24'>{formatarDinheiro(outrosGastos.valor * calcularNumeroDias(evento.data_inicial, evento.data_final) || 0)}</span></h1>
        <h1 className='font-bold -ml-7 mt-3 flex flex-row items-center'>Custo total:<span className='ml-5'> {formatarDinheiro(custoTotal || 0)}</span></h1>
        <textarea className="-ml-7 -mr-5 mt-2 mb-3 p-2 w-[110%] bg-[#DDDDDD] rounded-lg" style={{ resize: 'none' }} disabled={true} value={outrosGastos.descricao}></textarea>
      </div>
      <div className='border-b border-gray-300 -ml-10 -mr-10'></div>
      <div>
        <h1 className='bg-[#0DB30B] py-1 rounded-lg text-white mt-3 text-center'>Comprovantes </h1>
        <div className='flex flex-row'>  
          <h1 className='flex flex-row justify-between items-center font-bold mt-3'>Comprovante de inscrição
            <span className='font-normal'> 
              <button className='ml-3 flex flex-row items-center justify-center border border-black rounded-lg border-spacing-13 p-2' onClick={() => abreModal(comprovanteInscricao, 'Comprovante de inscrição')}>
                <PiFilePdf color='red' size={35} className='ml-1'/>
                <h1 className='mr-2 text-gray-500 text-sm'>Acessar</h1>
                {(correcoesFeedback.includes('Comprovante de inscrição') || verificado_comprovante_incricao === false) && <PiWarningCircle color='red' size={20} className='absolute ml-44'/>}
              </button>
            </span>
          </h1>
        </div>
        <h1 className='flex flex-row font-bold mt-6'>Programação do evento
          <span className='font-normal'> 
            <button className='ml-7 -mt-4 flex flex-row items-center justify-center border border-black rounded-lg border-spacing-13 p-2 mb-4' onClick={() => abreModal(comprovanteProgramacao, 'Programação do evento')}>
              <PiFilePdf color='red' size={35} className='ml-1'/>
              <h1 className='mr-2 text-gray-500 text-sm'>Acessar</h1>
              {(correcoesFeedback.includes('Programação do evento') || verificado_comprovante_programacao === false) && <PiWarningCircle color='red' size={20} className='absolute ml-44'/>}
            </button>
          </span>
        </h1>
      </div>
      {(showModal) && (
        <div className='fixed top-0 left-0 bg-black bg-opacity-50 w-full h-screen flex items-center justify-center z-50'>
          <div className='bg-[#333333] w-1/2 h-[70%] rounded-lg -mt-36'>
            <div className='flex justify-end'>
            {(statusPeticao !== 'Em análise' || correcoesFeedback.includes(modalTipo)) && (
              <>
                <button onClick={() => closeModal(null)} className='mr-5 my-5 text-white'>X</button>
              </>
            )}
            </div>
            <div className='flex justify-center w-full h-full'>
              <iframe title="PDF Viewer" src={modalContent} className="w-full"></iframe>
            </div>
            <div className='flex justify-end bg-black rounded-b-lg'>
            {(statusPeticao === 'Em análise' && !correcoesFeedback.includes(modalTipo)) && (
              <>
                <h1 className='flex items-center text-white text-lg mr-10'>Esse documento é válido?</h1>
                <button onClick={() => closeModal(`${modalTipo}`)} className='mr-5 my-4 py-3 px-5 rounded-lg text-white bg-red-600'>
                  <PiX size={25} />
                </button>
                <button onClick={() => closeModal(null)} className='mr-5 my-4 py-3 px-5 rounded-lg text-white bg-green-600'>
                  <PiCheck size={25} />
                </button>
              </>
            )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InformacoesGerais;
