import React from 'react';
import { BsPersonBoundingBox } from "react-icons/bs";

const TabelaChamada = ({ alunos, presencaAlunos, verificarTick, receberNome }) => {
    return (
        <table className="w-full table-auto mt-2">
            <thead>
                <tr>
                    <th>Nome</th>
                    <th>Presença</th>
                </tr>
            </thead>
            <tbody>
                {alunos.map((aluno) => (
                    <tr key={aluno.id_aluno}>
                        <td className={`border-b-4 text-start w-1/4 text-sm p-2 ${presencaAlunos[aluno.id_aluno] ? 'bg-green-400' : 'bg-gray-300'}`}
                            onClick={() => {
                                verificarTick(aluno.id_aluno);
                                receberNome(aluno.nome_aluno);
                            }}>
                            <div className='flex flex-row justify-between'>
                                {aluno.nome_aluno}
                                {aluno.camera && <BsPersonBoundingBox size={15}/>}
                            </div>
                        </td>
                        <td className={`border-b-4 text-start w-1/4 text-sm p-2 ${presencaAlunos[aluno.id_aluno] ? 'bg-green-400' : 'bg-gray-300'}`}>
                            {presencaAlunos[aluno.id_aluno] ? 'Presente' : 'Ausente'}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default TabelaChamada;
