import { useEffect, useState, useRef } from "react";
import { Radar, Doughnut } from 'react-chartjs-2';
import Skeleton from 'react-loading-skeleton';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend, ArcElement } from 'chart.js';
import { PiGraduationCap, PiChartLine, PiArrowLeft, PiChartDonut, PiClipboardText, PiUsersThree, PiSmiley ,PiSmileySad, PiSmileyMeh } from "react-icons/pi";
import Card from "../../../components/ui/cardGenerico";
import Etapas from "./etapas";
import LancarNotas from "./lancar_notas";
import Desempenho from "./desempenho";
import MenuTopo from '../../../components/ui/menuTopo';
import CalcularEtapa from './calcularEtapa';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend, ArcElement);

const Disciplinas = () => {
    const token = localStorage.getItem('token');
    const [diarios, setDiarios] = useState([]);
    const [infoDisciplina, setInfoDisciplina] = useState([]);
    const [disciplinaSelecionada, setDisciplinaSelecionada] = useState(null);
    const [vizualizarDesempenho, setVizualizarDesempenho] = useState(false);
    const [lancarNotas, setLancarNotas] = useState(false);
    const [dadosAvaliacao, setDadosAvaliacao] = useState(null);
    const [carregando, setCarregando] = useState(true);
    const [vizualizarAvaliacao, setVizualizarAvaliacao] = useState(false);
    const [posiçãoAtualDados, setPosiçãoAtualDados] = useState(0);
    const [posiçãoAtualSemestre, setPosiçãoAtualSemestre] = useState(0);
    const [toqueStart, setToqueStart] = useState(0);
    const [etapas, setEtapas] = useState([]);
    const [resultadoFinal, setResultadoFinal] = useState([]);
    const [dadosTurma, setDadosTurma] = useState([]);
    const [renderRosquinha, setRenderRosquinha] = useState(false);
    const [anoSemestres, setAnoSemestres] = useState([]);
    const [calcularEtapa, setCalcularEtapa] = useState(false);
    const [avaliacaoSelecionada, setAvaliacaoSelecionada] = useState(null);
    const [, setDesempenho] = useState([]);
    const touchRef = useRef(null);

    useEffect(() => {
        fetch('https://suapuno.app:8080/api/informacoes/etapas-disciplina/', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        .then((resposta) => {
            if (!resposta.ok) {
                throw new Error('Falha ao acessar a API');
            }
            return resposta.json();
        })
        .then((dados) => {
            setInfoDisciplina(dados);
        })
        .catch((erro) => console.error(erro));

        fetch('https://suapuno.app:8080/api/diarios/', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        .then((resposta) => {
            if (!resposta.ok) {
                throw new Error('Falha ao acessar a API');
            }
            return resposta.json();
        })
        .then((dados) => {
            const anoSemestres = new Set();

            dados.forEach(disciplina => {
                anoSemestres.add(disciplina.ano_semestre);
            });
            
            // Converte o Set de volta para um array
            const anoSemestresArray = Array.from(anoSemestres);
        
            // Armazena os dados das diarios e o array de ano_semestres
            setDiarios(dados);
            setCarregando(false);
            setAnoSemestres(anoSemestresArray);
        })
        .catch((erro) => console.error(erro));
        
    }, [token]);

    useEffect(() => {
        if (!touchRef.current) return;
    
        const handleTouchMove = (event) => {
            if (toqueStart - event.changedTouches[0].clientX > 150) {
                handleDireita();
            } else if (toqueStart - event.changedTouches[0].clientX < -150) {
                handleEsquerda();
            }
            setToqueStart(0);
        };
    
        touchRef.current.addEventListener('touchmove', handleTouchMove);
    });
    
    useEffect(() => {
        const fetchData = async () => {
            let allEtapas = [];
            const resultadoFinalTemp = [];
    
            for (const disciplina of diarios) {
                try {
                    const resposta = await fetch(`https://suapuno.app:8080/api/diarios/${disciplina.id_diario}/desempenho/`, {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': `Token ${token}`
                        }
                    });
    
                    if (!resposta.ok) {
                        throw new Error('Falha ao acessar a API');
                    }
    
                    const dados = await resposta.json();
    
                    let etapas = [];
                    dados.forEach((aluno) => {
                        aluno.etapas.forEach((etapa) => {
                            if (!etapas.includes(etapa.etapa)) {
                                etapas.push(etapa.etapa);
                            }
                        });
                    });
    
                    const medias = {};
                    etapas.forEach((etapa, index) => {
                        medias[index + 1] = 0;
                        let count = 0;
                        dados.forEach((aluno) => {
                            aluno.etapas.forEach((e) => {
                                if (e.etapa === etapa && e.media_etapa !== null) {
                                    medias[index + 1] += e.media_etapa;
                                    count++;
                                }
                            });
                        });
                        if (count > 0) {
                            medias[index + 1] /= count;
                        }
                    });
    
                    const resultadoDisciplina = {
                        ano: disciplina.ano_semestre
                    };
                    Object.keys(medias).forEach((etapa) => {
                        resultadoDisciplina[etapa] = medias[etapa].toFixed(2);
                    });
    
                    resultadoFinalTemp.push(resultadoDisciplina);
    
                    allEtapas = [...allEtapas, ...etapas];
                } catch (erro) {
                    console.error(erro);
                }
            }
    
            // Remover duplicatas
            allEtapas = [...new Set(allEtapas)];
            setEtapas(allEtapas);
            setResultadoFinal(resultadoFinalTemp);
        };
    
        fetchData();
    }, [token, diarios]);
     
    const getDesempenho = (disciplinaSelecionada) => {
        setDisciplinaSelecionada(disciplinaSelecionada);
    
        fetch(`https://suapuno.app:8080/api/diarios/${disciplinaSelecionada.id_diario}/desempenho/`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        .then((resposta) => {
            if (!resposta.ok) {
                throw new Error('Falha ao acessar a API');
            }
            return resposta.json();
        })
        .then((dados) => {    
            setDesempenho(dados)
            let etapas = [];
            let medias = {};
            let classificacoesPorEtapa = {};
    
            // Inicializar as estruturas de dados para cada etapa
            dados.forEach((aluno) => {
                aluno.etapas.forEach((etapa) => {
                    if (!etapas.includes(etapa.etapa)) {
                        etapas.push(etapa.etapa);
                    }
                    if (!medias[etapa.etapa]) {
                        medias[etapa.etapa] = {
                            total: 0,
                            count: 0,
                            insatisfatorio: 0,
                            regular: 0,
                            excelente: 0
                        };
                    }
                });
            });
    
            // Processar os dados dos alunos
            dados.forEach((aluno) => {
                aluno.etapas.forEach((etapa) => {
                    if (etapa.media_etapa !== null) {
                        medias[etapa.etapa].total += etapa.media_etapa;
                        medias[etapa.etapa].count += 1;
    
                        // Classificar as notas por etapa
                        if (etapa.media_etapa <= 5) {
                            medias[etapa.etapa].insatisfatorio += 1;
                        } else if (etapa.media_etapa <= 7) {
                            medias[etapa.etapa].regular += 1;
                        } else {
                            medias[etapa.etapa].excelente += 1;
                        }
                    }
                });
            });
    
            // Calcular as médias para cada etapa
            Object.keys(medias).forEach((etapa) => {
                medias[etapa].media = medias[etapa].total / medias[etapa].count;
            });
    
            // Agrupar as classificações por etapa
            etapas.forEach((etapa) => {
                classificacoesPorEtapa[etapa] = {
                    insatisfatorio: medias[etapa].insatisfatorio,
                    regular: medias[etapa].regular,
                    excelente: medias[etapa].excelente,
                    media: medias[etapa].media.toFixed(2)
                };
            });
    
            // Calcular a média geral da turma
            let totalMediaGeral = 0;
            let countMediaGeral = 0;
            etapas.forEach((etapa) => {
                if (!isNaN(medias[etapa].media) && medias[etapa].media !== 0) {
                    totalMediaGeral += medias[etapa].media;
                    countMediaGeral += 1;
                }
            });
    
            let mediaGeralTurma = totalMediaGeral / countMediaGeral;
    
            // Salvando os dados
            setDadosTurma((prev) => ({
                ...prev,
                etapas: etapas,
                medias: medias,
                classificacoesPorEtapa: classificacoesPorEtapa,
                mediaGeralTurma: mediaGeralTurma.toFixed(2)
            }));
    
            setRenderRosquinha(true);
        })
        .catch((erro) => console.error(erro));
    };
    
    const handleDireita = (tipo) => {
        if (tipo === 'etapa') {
            if (posiçãoAtualDados < etapas.length - 1) {
                setPosiçãoAtualDados(prev => prev + 1);
            } else {
                setPosiçãoAtualDados(0);
            }
        } else if(tipo === 'ano') {
            if (posiçãoAtualSemestre < anoSemestres.length - 1) {
                setPosiçãoAtualSemestre(prev => prev + 1);
            } else {
                setPosiçãoAtualSemestre(0);
            }
        }
    };
    
    const handleEsquerda = (tipo) => {
        if (tipo === 'etapa') {
            if (posiçãoAtualDados > 0) {
                setPosiçãoAtualDados(prev => prev - 1);
            } else {
                setPosiçãoAtualDados(etapas.length - 1);
            }
        } else if(tipo === 'ano')  {
            if (posiçãoAtualSemestre < anoSemestres.length - 1) {
                setPosiçãoAtualSemestre(prev => prev + 1);
            } else {
                setPosiçãoAtualSemestre(0);
            }
        }
    };
    
    const handleToque = (event) => {
        setToqueStart(event.touches[0].clientX);
    };
    
    const handleToqueEnd = (event, tipo) => {
        const toqueEnd = event.changedTouches[0].clientX;
        const diff = toqueStart - toqueEnd;
        if (diff > 150) {
            handleDireita(tipo);
        } else if (diff < -150) {
            handleEsquerda(tipo);
        }
    };

    const handleAvaliacoesClick = (disciplina) => {
        setDisciplinaSelecionada(disciplina);

        fetch(`https://suapuno.app:8080/api/diarios/${disciplina.id_diario}/avaliacoes/`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        .then((resposta) => {
            if (!resposta.ok) {
                throw new Error('Falha ao acessar a API');
            }
            return resposta.json();
        })
        .then((dados) => {
            setDisciplinaSelecionada(prev => ({ ...prev, avaliacoes: dados }));
            setVizualizarAvaliacao(true);
        })
        .catch((erro) => console.error(erro));
    };

    const handleDesempenhoClick = () => {
        setVizualizarDesempenho(true);
    }

    const handleLancarNotasClick = (dadosAvaliacao) => {
        setLancarNotas(true);
        setDadosAvaliacao(dadosAvaliacao);
    }

    const calcularEtapaClick = (dados) => {
        setCalcularEtapa(true);
        setAvaliacaoSelecionada(dados)
    }

    const handleVoltar = () => {
        setPosiçãoAtualDados(0)
        if(calcularEtapa){
            setCalcularEtapa(false);
        }else if (lancarNotas) {
            setLancarNotas(false);
        } else if (vizualizarDesempenho) {
            setVizualizarDesempenho(false);
        } else if (vizualizarAvaliacao) {
            setVizualizarAvaliacao(false);
        } else {
            setDisciplinaSelecionada(null);
        }
    };

    const getEtapaId = (etapa) => {
        const etapaObj = infoDisciplina.find(e => e.etapa === etapa);
        return etapaObj ? etapaObj.id_etapa : null;
    };

    const datasetIndex = posiçãoAtualDados + 1;

    const graficoRadar = {
        labels: diarios.filter(disciplina => disciplina.ano_semestre === anoSemestres[posiçãoAtualSemestre]).map(item => item.disciplina),
        datasets: [
            {
                data: resultadoFinal.filter(disciplina => disciplina.ano === anoSemestres[posiçãoAtualSemestre]).map(item => parseFloat(item[`${datasetIndex}`])),
                backgroundColor: 'rgba(120, 251, 120, 0.5)',
                borderColor: 'rgba(92, 227, 91)',
                borderWidth: 2,
                pointRadius: 1,
            }
        ],
    };

    const configGraficoRadar = {
        scales: {
            r: {
                min: 0,
                max: 10,
                beginAtZero: true,
                borderCapStyle: false,
                grid: {
                    color: 'rgba(0, 0, 0, 0.3)',
                    circular: true
                },
                angleLines: {
                    color: 'rgba(0, 0, 0, 0.3)',
                },
                ticks: {
                    stepSize: 2.5,
                    display: false
                }
            }
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                displayColors: false,
                callbacks: {
                    label: function(tooltipItem) {
                        const rawValue = tooltipItem.raw;
                        if (typeof rawValue === 'number') {
                            return rawValue.toFixed(1);
                        }
                        return rawValue;
                    },
                    title: function() {
                        return '';
                    },
                }
            }
        }
    };

    let graficoRosquinha;
    let configRosquinha;

    if(renderRosquinha){
        graficoRosquinha = {
            labels: ['Insatisfatório', 'Regular', 'Excelente'],
            datasets: [
                {
                    data: [dadosTurma.medias[etapas[datasetIndex - 1]].insatisfatorio, dadosTurma.medias[etapas[datasetIndex - 1]].regular, dadosTurma.medias[etapas[datasetIndex - 1]].excelente],
                    backgroundColor: ['rgb(232,47,50)', 'rgb(216,198,43)', 'rgb(44,220,42)', ]
                }
            ]
        };
        
        configRosquinha = {
            responsive: true,
            cutout: '70%',
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    displayColors: false,
                }
            }
        };
    }

    return (
        <div className="flex flex-col justify-center items-center">
            {disciplinaSelecionada && (
                <div className="w-full top-0 left-0 bg-white h-[48px] flex items-center">
                    <div className="flex justify-between w-full h-full items-center">
                        <button className="ml-2" onClick={handleVoltar}>
                            <PiArrowLeft size={30}/>
                        </button>
                        { !lancarNotas && vizualizarAvaliacao && !calcularEtapa &&  (
                            <div className="font-bold text-center">
                                <h1>Avaliações</h1>
                                <h1 className="font-normal text-sm italic">{disciplinaSelecionada.descricao_turma}</h1>
                            </div>
                        )}
                        {lancarNotas && (
                            <div className="font-bold text-center">
                                <h1>{dadosAvaliacao.descricao_avaliacao} - {dadosAvaliacao.etapa}</h1>
                                <h1 className="font-normal text-sm italic">{disciplinaSelecionada.descricao_turma}</h1>
                            </div>
                        )}
                        {calcularEtapa && (
                            <div className="font-bold text-center">
                                <h1 className="font-normal text-sm italic">{disciplinaSelecionada.descricao_turma}</h1>
                            </div>
                        )}
                        <button className="w-[30px]"></button>
                    </div>
                </div>
            )}
            {!disciplinaSelecionada && (
                <div className="w-full">
                    <div className="top-0 left-0 w-full"><MenuTopo /></div>
                    <div className="flex flex-col justify-center items-center mx-3">
                        <div className="w-full mx-4 mt-4">
                                <div onTouchStart={handleToque} onTouchEnd={(event) => handleToqueEnd(event, 'ano')} className="flex flex-col">
                                    <div className="flex flex-row items-start w-[100%]">
                                        {anoSemestres.map((ano_semestre, index) => (
                                            <div key={index} onClick={() => setPosiçãoAtualSemestre(index)} className="flex flex-col text-sm">
                                                <h1 className={`${anoSemestres[posiçãoAtualSemestre] === ano_semestre ? 'bg-white shadow-md ' : 'bg-gray-300'} ${anoSemestres[posiçãoAtualSemestre] === ano_semestre ? 'text-black' : 'text-gray-400'} text-center max-w-36 rounded-t-md px-3`}>{ano_semestre}</h1>
                                            </div>
                                        ))}
                                    </div>
                                    <Card
                                        icone={<PiGraduationCap size={23} />}
                                        titulo="Suas disciplinas"
                                        redondoTopo={false}
                                        blockMovimento = {true}
                                        conteudo={
                                            carregando ? (
                                                <Skeleton width={100} count={3} />
                                              ) : (
                                            diarios
                                                .filter(disciplina => disciplina.ano_semestre === anoSemestres[posiçãoAtualSemestre])
                                                .map((disciplina) => (
                                                    <div key={disciplina.id_diario} className='border-gray-300 border-2 rounded-xl mb-2' onClick={() => getDesempenho(disciplina)}>    
                                                        <h1 className="my-1 text-start ml-3 font-semibold"> {disciplina.disciplina} </h1>
                                                        <h1 className="text-sm mx-3 mb-1 text-gray-500"> {disciplina.curso} - {disciplina.ano_semestre} </h1>
                                                    </div>    
                                                )
                                            ))
                                        }
                                    />
                                </div>
                                <div onTouchStart={handleToque} onTouchEnd={(event) => handleToqueEnd(event, 'etapa')} className="flex flex-col -mt-8">
                                    <Card
                                        icone={<PiChartLine size={23} />}
                                        titulo="Desempenho da Turma"
                                        redondoTopo={false}
                                        blockMovimento = {true}
                                        conteudo={
                                            <div className="flex flex-col">
                                                {carregando ? (
                                                    <Skeleton width={100} height={300} />
                                                ) : (
                                                    <div className="flex justify-center flex-row w-[100%] gap-4">
                                                        {etapas.map((etapa, index) => (
                                                            <div key={index} onClick={() => setPosiçãoAtualDados(index)} className="text-sm font-semibold">
                                                                <h1 className={`${etapas[datasetIndex - 1] === etapa ? 'bg-green-600' : 'bg-white-300'} ${etapas[datasetIndex - 1] === etapa ? 'text-white' : 'text-black'} text-center max-w-36 rounded-md py-2 px-5`}>{etapa}</h1>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                                {carregando ? (
                                                    <Skeleton width={100} height={150}  />
                                                ) : (
                                                    diarios.filter(disciplina => disciplina.ano_semestre === anoSemestres[posiçãoAtualSemestre]).length > 0 && (
                                                        <div className="flex justify-center items-center">
                                                            {etapas[datasetIndex - 1] && (
                                                                <div>
                                                                    <Radar data={graficoRadar} options={configGraficoRadar} />
                                                                </div>
                                                            )}
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {disciplinaSelecionada && !vizualizarDesempenho && !lancarNotas && !vizualizarAvaliacao && (
                    <div className="w-[100%] mt-4">                  
                        <div className="flex flex-col justify-center items-center mx-3">
                            <div className="w-full mx-4">
                                <Card
                                    icone={<PiGraduationCap size={23} />}
                                    titulo={disciplinaSelecionada.turma}
                                    blockMovimento = {true}
                                    conteudo={
                                        <div>
                                            <div className="flex items-center gap-2">
                                                <div className="h-1 w-1 bg-black rounded-full"></div>
                                                <h1 className="flex text-start font-bold gap-1">Semestre: <p className="font-normal">{disciplinaSelecionada.ano_semestre}</p></h1>
                                            </div>
                                            <div className="flex gap-2">
                                                <div className="flex justify-center">
                                                    <div className="h-1 w-1 mt-[10px] bg-black rounded-full"/>
                                                    <h1 className="font-bold ml-2">
                                                        Horários:
                                                    </h1>
                                                </div>
                                                <h1 className="flex items-center text-start font-bold gap-1">
                                                    <div className="font-normal">
                                                        {disciplinaSelecionada.horarios.map((horario, index) => (
                                                            <h1 className="text-sm" key={index}>
                                                                {horario.local} -  {horario.hora_inicio} - {horario.hora_fim} ({horario.dia_semana}) <br/>
                                                            </h1>
                                                        ))}
                                                    </div>
                                                </h1>
                                            </div>
                                            <div className="flex items-center gap-2">
                                                <div className="h-1 w-1 bg-black rounded-full"></div>
                                                <h1 className="flex text-start font-bold gap-1">Turno: <p className="font-normal">{disciplinaSelecionada.turno}</p></h1>
                                            </div>
                                            <div>
                                                {dadosTurma.mediaGeralTurma < 5 && (
                                                    <div className="flex flex-col justify-center items-center mt-2 text-sm">
                                                        <PiSmileySad color="red" size={20}/>
                                                        <h1 className="text-red-500">O desempenho dos alunos nessa matéria está insatisfatório.</h1>
                                                    </div>
                                                )}
                                                {(dadosTurma.mediaGeralTurma > 5 && dadosTurma.mediaGeralTurma <= 7) && (
                                                    <div className="flex flex-col justify-center items-center mt-2 text-sm">
                                                        <PiSmileyMeh color="yellow" size={20}/>
                                                        <h1 className="text-yellow-400">O desempenho dos alunos nessa matéria está regular.</h1>
                                                    </div>
                                                )}
                                                {dadosTurma.mediaGeralTurma > 7 && (
                                                    <div className="flex flex-col justify-center items-center mt-2 text-sm">
                                                        <PiSmiley color="green" size={20}/>
                                                        <h1 className="text-green-500">O desempenho dos alunos nessa matéria está excelente.</h1>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="flex flex-col gap-2 mt-4">
                                                <div className="flex justify-center py-1 items-center gap-2 text-white bg-green-700 mx-3 rounded-lg" onClick={() => {handleAvaliacoesClick(disciplinaSelecionada)}}>
                                                    <PiClipboardText/> Gerenciar notas
                                                </div>
                                                <div className="flex justify-center py-1 items-center gap-2 text-white bg-green-700 mx-3 rounded-lg" onClick={handleDesempenhoClick}>
                                                    <PiUsersThree/> Visualizar desempenho
                                                </div>
                                            </div>
                                        </div>
                                    }
                                />
                                <div onTouchStart={handleToque} onTouchEnd={(event) => handleToqueEnd(event, 'etapa')}  className="flex flex-col text-sm w-full">
                                    <div className="flex flex-row w-[100%]">
                                        {etapas.map((etapa, index) => (
                                            <div key={index} onClick={() => setPosiçãoAtualDados(index)} className="flex flex-col text-sm font-semibold">
                                                <h1 className={`${etapas[datasetIndex - 1] === etapa ? 'bg-white shadow-md' : 'bg-gray-300'} ${etapas[datasetIndex - 1] === etapa ? 'text-black' : 'text-gray-400'} text-center max-w-36 rounded-t-md px-3 h-7 flex items-center`}>{etapa}</h1>
                                            </div>
                                        ))}
                                    </div>
                                    <Card
                                        icone={<PiChartDonut size={23} />}
                                        titulo="Resumo da Disciplina"
                                        redondoTopo={false}
                                        blockMovimento = {true}
                                        conteudo={
                                            <div className="flex flex-col relative">
                                                {renderRosquinha && (
                                                    <div className="flex">
                                                        <div className="h-[38%] w-[38%] relative">
                                                            <Doughnut data={graficoRosquinha} options={configRosquinha} />
                                                            <div className="absolute inset-0 flex flex-col justify-center items-center text-center text-[10px]">
                                                                {isNaN(dadosTurma.medias[etapas[datasetIndex - 1]].media) || dadosTurma.medias[etapas[datasetIndex - 1]].media == null ? (
                                                                    <p className="-mb-8 mt-2">Sem média disponível</p>
                                                                ) : (
                                                                    <p className="text-2xl font-bold -mb-5">{dadosTurma.medias[etapas[datasetIndex - 1]].media.toLocaleString('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</p>
                                                                )}
                                                                <br />{isNaN(dadosTurma.medias[etapas[datasetIndex - 1]].media) || dadosTurma.medias[etapas[datasetIndex - 1]].media == null ? '' : 'é a média da turma'}<br /> nessa etapa
                                                            </div>
                                                        </div>
                                                        <div className="justify-center items-center font-bold text-sm mt-7 ml-5">
                                                            <div className="flex items-center text center w-full mb-2">
                                                                <div className="h-[15px] w-[15px] rounded bg-[#e82f32]"></div>
                                                                <h1 className="ml-1">{dadosTurma.medias[etapas[datasetIndex - 1]].insatisfatorio} em situação Insatisfatório</h1>
                                                            </div>
                                                            <div className="flex items-center text center w-full  mb-2">
                                                                <div className="h-[15px] w-[15px] rounded bg-[#d8c62b]"></div>
                                                                <h1 className="ml-1">{dadosTurma.medias[etapas[datasetIndex - 1]].regular} em situação Regular</h1>
                                                            </div>
                                                            <div className="flex items-center text center w-full">
                                                                <div className="h-[15px] w-[15px] rounded bg-[#2cdc2a]"></div>
                                                                <h1 className="ml-1">{dadosTurma.medias[etapas[datasetIndex - 1]].excelente} em situação Excelente</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {vizualizarAvaliacao && !lancarNotas && !vizualizarDesempenho && !calcularEtapa && (
                    <div className="flex flex-col justify-center items-center w-screen font-bold mb-12 ">
                        <div className="flex flex-wrap justify-center items-start gap-4">
                            {(disciplinaSelecionada.avaliacoes || []).map((avaliacao, index) => (
                                <Etapas
                                    index={index}
                                    key={avaliacao.etapa}
                                    dados={avaliacao}
                                    etapaId={getEtapaId(avaliacao.etapa)}
                                    discId={disciplinaSelecionada.id_diario}
                                    handleLancarNotasClick={handleLancarNotasClick}
                                    calcularEtapaClick={calcularEtapaClick}
                                />
                            ))}
                        </div>
                    </div>
                )}
                {vizualizarDesempenho && (
                    <div className="flex justify-center">
                        <Desempenho
                            etapas={etapas}
                            disciplinaSelecionada={disciplinaSelecionada} 
                        />
                    </div>
                )}
                {lancarNotas && (
                    <div className="flex flex-col justify-center mt-4 w-[calc(100%-32px)]">
                        <LancarNotas
                            disciplinaSelecionada={disciplinaSelecionada}
                            dadosAvaliacao={dadosAvaliacao}
                        />
                    </div>
                )}
                {calcularEtapa && (
                    <div className="flex flex-col justify-center mt-4 w-[calc(100%-32px)]">
                        <CalcularEtapa
                            dadosAvaliacao={dadosAvaliacao}
                            dados={avaliacaoSelecionada}
                            discId={disciplinaSelecionada.id_diario}
                            etapaId={getEtapaId(avaliacaoSelecionada.etapa)}
                        />
                    </div>
                )}
            </div>
        );
};

export default Disciplinas;