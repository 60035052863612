import React, { useEffect, useState } from 'react';
import { PiStudent, PiGraduationCap, PiWarning } from "react-icons/pi";
import Card from "../../../components/ui/cardGenerico";

const LancarNotas = ({ dadosAvaliacao }) => {
    const token = localStorage.getItem('token');
    const [alunos, setAlunos] = useState([]);
    const [notificacao, setNotification] = useState({ show: false, mensagem: '', tipo: '' });
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (dadosAvaliacao?.id_avaliacao) {
            fetch(`https://suapuno.app:8080/api/avaliacao/${dadosAvaliacao.id_avaliacao}/notas`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${token}`
                }
            })
            .then((resposta) => {
                if (!resposta.ok) {
                    throw new Error('Falha ao acessar a API');
                }
                return resposta.json();
            })
            .then((dados) => {
                setAlunos(dados);
            })
            .catch((error) => {
                console.error('Erro ao buscar dados da API:', error);
            });
        }
    }, [dadosAvaliacao, token]);

    const handleNotaChange = (index, newNota) => {
        const updatedAlunos = alunos.map((aluno, i) => (
            i === index ? { ...aluno, nota: newNota ? parseFloat(newNota) : '' } : aluno
        ));
        setAlunos(updatedAlunos);
    };

    const verificarAlunosSemNota = () => {
        return alunos.some(aluno => aluno.nota === undefined || aluno.nota === null || aluno.nota === '');
    };

    const handleSave = () => {
        if (verificarAlunosSemNota()) {
            setShowModal(true);
        } else {
            salvarNotas();
        }
    };

    const salvarNotas = () => {
        const notasParaSalvar = alunos.map(aluno => ({
            id_aluno: aluno.id_aluno,
            nota: aluno.nota || 0.00,
            comentario: ''
        }));

        fetch(`https://suapuno.app:8080/api/avaliacao/${dadosAvaliacao.id_avaliacao}/notas/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify(notasParaSalvar)
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Falha ao salvar as notas');
            }
            return response.json();
        })
        .then(() => {
            setNotification({ show: true, mensagem: 'Alterações salvas com sucesso!', tipo: 'success' });
            setTimeout(() => {
                setNotification({ show: false, mensagem: '', tipo: '' });
            }, 2000);
        })
        .catch((error) => {
            console.error('Erro ao salvar as notas:', error);
            setNotification({ show: true, mensagem: 'Erro ao salvar, tente novamente!', tipo: 'error' });
            setTimeout(() => {
                setNotification({ show: false, mensagem: '', tipo: '' });
            }, 2000);
        });
    };

    const handleCancel = () => {
        setShowModal(false);
    };

    const handleFillZeros = () => {
        const updatedAlunos = alunos.map(aluno => (
            { ...aluno, nota: aluno.nota === undefined || aluno.nota === null || aluno.nota === '' ? 0.00 : aluno.nota }
        ));
        setAlunos(updatedAlunos);
        setShowModal(false);
        salvarNotas();
    };

    useEffect(() => {
        const handleClick = () => {
            setNotification({ show: false, mensagem: '', tipo: '' });
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    return (
        <div className="">
            <div className="flex justify-end mb-3">
                <button onClick={handleSave} className="text-white bg-green-700 py-1 px-8 rounded-lg">
                    Gravar notas
                </button>
            </div>
            {notificacao.show && (
                <div className={`absolute top-0 left-0 right-0 text-white text-center font-bold p-2 rounded-b-xl ${notificacao.tipo === 'success' ? 'bg-green-600' : 'bg-red-600'}`}>
                    {notificacao.mensagem}
                </div>
            )}
            <Card
                icone={<PiGraduationCap size={23} />}
                titulo="Alunos"
                conteudo={
                    <div className="flex flex-col justify-start items-center">
                    {alunos.map((aluno, index) => (
                        <div key={index} className="w-full flex justify-between items-center mb-3">
                            <div className='flex items-center justify-center'>
                                <PiStudent size={30} />
                                <h1 className="ml-1 font-semibold text-sm break-words"> {aluno.nome_aluno} </h1>
                            </div>
                            <input value={aluno.nota} type="number" className="border bg-gray-300 border-gray-500 rounded-lg w-12 h-9 text-center text-lg" onChange={(e) => handleNotaChange(index, e.target.value)} />
                        </div>
                    ))}
                </div>
                }
            />
            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-5 rounded-lg">
                        <p className="flex justify-center items-center"> <PiWarning size={40}/>{dadosAvaliacao.descricao_avaliacao}</p>
                        <p className='my-2'>Considerar campos vazios como 0,0 ?</p>
                        <div className="flex justify-center gap-3">
                            <button onClick={handleCancel} className="bg-red-500 text-white py-2 px-4 rounded-lg">
                                Cancelar
                            </button>
                            <button onClick={handleFillZeros} className="bg-green-700 text-white py-2 px-9 rounded-lg">
                                Sim
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LancarNotas;
