import Menu from '../components/ui/menu';
import PeticaoAluno from '../paginas/aluno/suaPeticao/Peticao_Aluno';
import MenuTopo from "../components/ui/menuTopo";

const Peticao = () => {
  return (
    <div className="flex flex-col ">
      <div className="top-0 left-0 w-full"><MenuTopo /></div>
      <main className="flex-grow container mx-auto w-full mb-20">
        <PeticaoAluno />
      </main>
      <div className="absolute bottom-0 w-full">
        <Menu />
      </div>
    </div>
  );
};

export default Peticao;