import React, { useEffect, useState } from 'react';
import { GrClose } from "react-icons/gr";
import { PiWarningCircle } from "react-icons/pi";
import InformacoesGerais from '../../components/ui/informacoesGerais';
import CorrecoesFeedback from '../../components/ui/correcoesFeedback';
import DadosPessoais from '../../components/ui/dadosPessoais';
import PrestacaoDeContas from '../../components/ui/prestacaoDeContas';
import AnalisePrestacaoDeContas from '../../components/ui/analisePrestacaoDeContas';

const WidgetPeticao = ({ onClose, peticaoId, idUser }) => {
  const [dados, setDados] = useState(null);
  const [correcoesFeedback, setCorrecoesFeedback] = useState("");
  const [idAluno, setIdAluno] = useState(null); // Novo estado para armazenar o ID do aluno
  const [idDadosBancarios, setIdDadosBancarios] = useState(null); // Novo estado para armazenar o ID dos dados bancários
  const [statusContaAluno, setStatusContaAluno] = useState(null); // Estado que recebe se alguma coisa na conta do aluno está negativada
  const [statusBancoAluno, setStatusBancoAluno] = useState(null); // Mesma coisa do de cima, mas para os dados bancários
  const [desativarAviso, setDesativarAviso] = useState(false); // Estado para desativar o aviso de dados negativados

  const token = localStorage.getItem('token');

  useEffect(() => {
    fetch(`https://suapuno.app:8080/api/peticao/${peticaoId}/`, {
      method: 'GET',
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Erro ao acessar a API');
      }
      return response.json();
    })
    .then(data => {
      setDados(data);
      console.log('Dados:', data.numero_processo);
    })
    .catch(error => {
      console.error('Erro ao buscar dados:', error);
    });
  }, [peticaoId, token]);

  const updateStatusContaAluno = (status) => {
    setStatusContaAluno(status);
  }

  const updateCorrecoesFeedback = (feedback) => {
    setCorrecoesFeedback(prevFeedback => `${prevFeedback}\n${feedback}`);
  };

  const handleCorrecoesFeedback = (selectedItems) => {
    setCorrecoesFeedback(selectedItems);
  };  

  const handleAlunoIdChange = (idAluno) => {
    setIdAluno(idAluno);
  };

  const handleIdDadosBancariosChange = (idDadosBancarios) => {
    setIdDadosBancarios(idDadosBancarios);
  }

  if (!dados) {
    return null;
  }

  const ultimoStatus = dados.status[dados.status.length - 1];
  const nomeStatus = ultimoStatus.nome_status;

  let bgColor = 'bg-green-600';
  if (nomeStatus === 'Aguardando correções' || nomeStatus === 'Não contemplado') {
    bgColor = 'bg-red-600';
  } else if (nomeStatus === 'Prestação de contas' || nomeStatus === 'Análise de pagamento de GRU') {
    bgColor = 'bg-yellow-500';
  }

  const renderCorrecoesFeedback = nomeStatus === 'Aguardando correções' || nomeStatus === 'Em análise';
  const renderPrestacaoDeContas = nomeStatus === 'Prestação de contas' || nomeStatus === 'Contemplado' || nomeStatus === 'Análise de prestação de contas';
  const renderAnaliseDeContas = nomeStatus === 'Aguardando pagamento da GRU' || nomeStatus === 'Análise de pagamento da GRU' || nomeStatus === 'Concluído';

  return (
    <div className="fixed flex flex-col bg-black bg-opacity-50 w-screen h-full z-10 overflow-y-auto items-center">
      <div className='flex flex-col w-fit'>
        <div className={`${bgColor} mt-3 rounded-t-lg pb-5`}>
          <div className="flex items-center justify-between text-white text-2xl">
            <div></div>
            <h1 className='mt-4'>{dados.evento.nome}</h1>
            <button onClick={onClose} className="text-white mr-4 mt-4">
              <GrClose />
            </button>
          </div>
        </div>
        <div className='bg-gray-200 rounded-b-lg px-7 py-2'>
          <h1 className='font-bold'>Status atual: <span className='font-normal'>{nomeStatus}</span></h1>
          <div className='flex flex-row justify-around gap-5 w-full'>
            <div className='w-auto'>
              <InformacoesGerais 
                correcoesFeedback={correcoesFeedback} 
                evento={dados.evento} 
                outrosGastos={dados.outros_gastos} 
                comprovanteInscricao={dados.comprovante_incricao} 
                comprovanteProgramacao={dados.comprovante_programacao} 
                custos={dados.custos} peticaoId={peticaoId} 
                updateCorrecoesFeedback={updateCorrecoesFeedback} 
                statusPeticao={nomeStatus} 
                verificado={dados.evento.verificado} 
                verificado_comprovante_incricao={dados.verificado_comprovante_incricao} 
                verificado_comprovante_programacao={dados.verificado_comprovante_programacao} 
              />
            </div>
            <div className='w-auto'>
              <DadosPessoais 
                idUser={idUser} 
                correcoesFeedback={correcoesFeedback} 
                updateCorrecoesFeedback={updateCorrecoesFeedback} 
                statusPeticao={nomeStatus} 
                onAlunoIdChange={handleAlunoIdChange} 
                onIdDadosBancariosChange={handleIdDadosBancariosChange} 
                updateStatusContaAluno={updateStatusContaAluno}
                setStatusBancoAluno={setStatusBancoAluno}
              />
            </div>
            {renderCorrecoesFeedback && (
              <div className='w-auto relative'>
                <> 
                  <CorrecoesFeedback 
                    correcoesFeedback={correcoesFeedback}
                    aoSelecionar={handleCorrecoesFeedback}
                    peticaoId={peticaoId}
                    idTipo={dados.tipo_auxilio.id}
                    idModaliade={dados.modalidade_auxilio.id}
                    onClose={onClose}
                    idUser={idUser}
                    idAluno={idAluno}
                    idBanco={idDadosBancarios} // Passa o ID do aluno para CorrecoesFeedback
                  />
                  {nomeStatus === 'Aguardando correções' && (
                    <div className="absolute inset-0 bg-white bg-opacity-50 backdrop-blur-sm flex items-center justify-center mt-3 h-grow rounded-lg">
                      <div className='flex flex-row items-center mx-5'>
                        <PiWarningCircle size={100} color='red'/>
                        <p className='text-center font-bold mt-4 text-md '>Aguarde o aluno realizar as correções para continuar com o processo!</p>
                      </div>
                    </div>
                  )}
                  {((statusContaAluno === false || statusBancoAluno === false) && desativarAviso === false) && nomeStatus !== 'Aguardando correções' && (
                    <div className='absolute inset-0 bg-white bg-opacity-50 backdrop-blur-sm flex flex-col items-center justify-center mt-3 h-grow rounded-lg'>
                      <div>
                        <div className='flex flex-row items-center mx-5'>
                          <PiWarningCircle size={100} color='red'/>
                          <p className='text-center font-bold mt-4 text-md '>Esse aluno possui dados aguardando correções,<br/>deseja prosseguir mesmo assim?</p>                
                        </div>
                      </div>
                     <button onClick={() => setDesativarAviso(true)} className='mt-10 py-2 px-3 rounded-lg text-white bg-gray-600'>Prosseguir</button>
                    </div>
                  )}
                </>
              </div>
            )}
            {renderPrestacaoDeContas && (
                <div className='w-auto relative'>
                    <PrestacaoDeContas 
                      comprovante_participacao={dados.comprovante_participacao}
                      peticaoId={peticaoId}
                      numeroProcesso={dados.numero_processo}
                    />
                    {nomeStatus === 'Contemplado' && (
                    <div className="absolute inset-0 bg-white bg-opacity-50 backdrop-blur-sm flex items-center justify-center mt-3 h-grow rounded-lg">
                      <div className='flex flex-row items-center mx-5'>
                        <PiWarningCircle size={100} color='red'/>
                        <p className='text-center font-bold mt-4 text-md '>Aguarde o evento concluir para prestar contas!</p>
                      </div>
                    </div>
                  )}
              </div>
            )}
            {renderAnaliseDeContas && (
                <div className='w-auto relative'>
                  <AnalisePrestacaoDeContas 
                    comprovante_participacao={dados.comprovante_participacao}
                    comprovante_pagamento_gru={dados.comprovante_pagamento_gru}
                    peticaoId={peticaoId}
                    nomeStatus={nomeStatus}
                  />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WidgetPeticao;
