import React, { useState } from 'react';
import { PiWarningCircle } from "react-icons/pi";
import { isBrowser } from 'react-device-detect';
import Menu from "../../../../components/ui/menuTopo";
import Contextos from "./contextos"
import Entidade from "./entidades"
import TesteDeAtendimento from "./testeDeAtendimento"
import Conversas from "./conversas"

const SuaPinho = () => {
  const [aba, setAba] = useState(1);

  if (!isBrowser) {
    return (
      <div className="flex flex-col items-center justify-center h-full text-center">
        <div className="top-0 left-0 w-full"><Menu /></div>
        <div className='flex flex-col justify-center items-center mt-[calc(50%)]'>
          Site não disponível para dispositivos móveis, por favor acesse de um computador.
          <PiWarningCircle size={100} color='red' />
        </div>
      </div>
    );
  }

  return (
    <div className='flex h-screen '>
      <div className="flex flex-col flex-grow bg-gray-200">
        <div className="flex flex-col h-full px-10 pt-5">
          <div className='flex'>
            <h1 className='text-gray-500 text-sm'>
              Auxílio estudantil &gt; Suapinho
            </h1>
          </div>
          <h1 className="text-2xl font-semibold mb-2 mt-3">Suapinho - Atendimento virtual</h1>
          <div className='flex gap-1'>
            <div onClick={() => setAba(1)} className={`${aba === 1 ? "bg-white font-semibold" : "bg-[#d1d1d1] font-semibold text-[#8c8c8e]" } rounded-t-lg p-3 text-xl`}>CONTEXTOS PADRÕES</div>
            <div onClick={() => setAba(2)} className={`${aba === 2 ? "bg-white font-semibold" : "bg-[#d1d1d1] font-semibold text-[#8c8c8e]" } rounded-t-lg p-3 text-xl`}>ENTIDADES</div>
            <div onClick={() => setAba(3)} className={`${aba === 3 ? "bg-white font-semibold" : "bg-[#d1d1d1] font-semibold text-[#8c8c8e]" } rounded-t-lg p-3 text-xl`}>CONVERSAS</div>
            <div onClick={() => setAba(4)} className={`${aba === 4 ? "bg-white font-semibold" : "bg-[#d1d1d1] font-semibold text-[#8c8c8e]" } rounded-t-lg p-3 text-xl`}>TESTE DE ATENDIMENTO</div>
          </div>
          { aba === 1 && 
            <Contextos />
          }
          { aba === 2 &&
            <Entidade />
          }
          { aba === 3 &&
            <Conversas />
          }
          { aba === 4 &&
            <TesteDeAtendimento/>
          }
        </div>
      </div>
    </div>
  );
};

export default SuaPinho;
