import React, { useState, useEffect, useRef } from 'react';
import { PiXBold, PiPaperPlane, PiDot } from 'react-icons/pi';
import notificationSound from './mensagem.mp3';

const Chat = ({ isOpen, onClose }) => {
  const [mensagens, setMensagens] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [msgBoasVindas, setMsgBoasVindas] = useState(false);
  const [digitando, setDigitando] = useState(false);
  const [token, setToken] = useState("")
  const chatEndRef = useRef(null);
  const audio = new Audio(notificationSound);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await fetch('https://suapuno.app:8080/api-token-auth/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            username: "suapinho",
            password: "senhaFRACA"
          })
        });
        
        if (response.ok) {
          const api = await response.json();
          setToken(api.token)
        } else {
          console.error('Api não retornou o ok:', response.status);
        }
      } catch (error) {
        console.error('Erro ao tentar obter token de chat generico:', error);
      }
    };

    fetchToken();
  }, []); 

  const mensagensBoasVindas = [
    "Olá, eu sou Suapinho! Estou aqui para ajudar e responder a qualquer dúvida que você possa ter sobre o sistema SUAPuno.",
    "Oi! Eu sou o Suapinho. Como posso te ajudar hoje?",
    "Bem-vindo! Eu sou Suapinho. Se precisar de qualquer ajuda com o SuapUno, estou aqui.",
    "Olá! Sou Suapinho, seu assistente virtual. Qualquer dúvida sobre o SuapUno, pergunte-me!",
    "Oi, sou Suapinho! Estou aqui para tirar suas dúvidas sobre o SUAPuno. Como posso ajudar?"
  ];

  useEffect(() => {
    if (isOpen && !msgBoasVindas) {
      const mensagemAleatoria = mensagensBoasVindas[Math.floor(Math.random() * mensagensBoasVindas.length)];
      setMensagens([...mensagens, { sender: 'Suapinho', text: mensagemAleatoria }]);
      setMsgBoasVindas(true);
    }
    // eslint-disable-next-line
  }, [isOpen, msgBoasVindas, mensagens]);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [mensagens]);

  const handleSendMensagen = async () => {
    if (inputValue.trim()) {
      const newMensagens = [...mensagens, { sender: 'Você', text: inputValue }];
      setMensagens(newMensagens);
      setInputValue('');
      setDigitando(true);

      try {
        const response = await fetch('https://suapuno.app:8080/api/assistente-virtual/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
          },
          body: JSON.stringify({ pergunta: inputValue }),
        });

        if (response.ok) {
          const data = await response.json();
          const resposta = data.resposta;

          if (resposta) {
            setMensagens([...newMensagens, { sender: 'Suapinho', text: resposta }]);
          } else {
            setMensagens([...newMensagens, { sender: 'Suapinho', text: 'Desculpe, não foi possível computar sua pergunta, tente novamente mais tarde.' }]);
          }
        } else {
          setMensagens([...newMensagens, { sender: 'Suapinho', text: 'Desculpe, ocorreu um erro ao processar sua mensagem, tente novamente mais tarde.' }]);
        }
      } catch (error) {
        setMensagens([...newMensagens, { sender: 'Suapinho', text: 'Desculpe, ocorreu um erro ao se comunicar com a nossa API, tente novamente mais tarde.' }]);
      } finally {
        audio.play();
        setDigitando(false);
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="relative bg-black p-2 rounded-md w-full h-[90%] mx-6 flex flex-col justify-center">
        <div className='flex justify-between items-center px-3 pt-2'>
          <div className="bg-white min-w-12 min-h-12 rounded-full flex items-center justify-center -mr-36 -ml-2">
            <img src='/imagens/SuaPinho.png' alt="Suapinho" style={{ width: '35px', height: '35px' }} />
          </div>
          <h1 className='text-white font-bold text-xl'>Suapinho</h1>
          <button className="text-white" onClick={onClose}>
            <PiXBold size={24} />
          </button>
          <div className='border-b-2 w-full absolute -ml-5 mt-16'></div>
        </div>
        <div className="flex-1 overflow-y-auto mt-8 scrollbar-none">
          {mensagens.map((mensagen, index) => (
            <div key={index} className={`flex mb-2 ${mensagen.sender === 'Você' ? 'justify-end' : 'justify-start'}`}>
              <div className="flex items-start">
                <div className={`p-2 rounded-md text-sm relative ${mensagen.sender === 'Suapinho' ? 'bg-white ml-2' : 'bg-[#7eda7d] mr-2'} ${mensagen.sender === 'Você' ? '' : 'text-left'}`}>
                  <div className="font-bold">{mensagen.sender}</div>
                  <div style={{ whiteSpace: 'pre-wrap' }}>{mensagen.text}</div>
                  <div className={` ${mensagen.sender === 'Suapinho' ? 'absolute w-0 h-0 border-t-[6px] border-t-transparent border-b-[6px] border-b-transparent border-r-[12px] border-r-white mt-2 top-0 -ml-4' : 'absolute w-0 h-0 border-t-[6px] border-t-transparent border-b-[6px] border-b-transparent border-l-[12px] border-l-[#7eda7d] mt-2 right-0 top-0 -mr-2' }`}/>
                </div>
              </div>
            </div>
          ))}
          {digitando && (
          <div className="flex mb-2 justify-start">
            <div className="flex items-start">
              <div className="p-2 rounded-md text-sm bg-white text-left">
                <div className="font-bold">Suapinho</div>
                <div className='flex -mb-6 -mt-4'>
                  <PiDot size={60} className='animate-bounce -mr-10 -ml-5' />
                  <PiDot size={60} className='animate-bounce -mr-10' style={{ animationDelay: '0.2s' }} />
                  <PiDot size={60} className='animate-bounce -mr-4' style={{ animationDelay: '0.4s' }} />
                </div>
              </div>
            </div>
          </div>
        )}
          <div ref={chatEndRef} />
        </div>
        <div className="mt-2 flex flex-col">
          <input
            type="text"
            placeholder="Digite uma mensagem..."
            className="p-2 pr-[45px] rounded-md bg-black text-white border-white border-2 mb-1"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') handleSendMensagen();
            }}
          />
          <PiPaperPlane size={23} color='white' className="absolute right-0 mr-5 mt-2 rotate-45" onClick={handleSendMensagen} />
          <h1 className='text-[10px] text-center italic text-gray-400'>Suapinho pode cometer erros. Certifique-se de revisar suas informações</h1>
        </div>
      </div>
    </div>
  );
};

export default Chat;
