import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { Stage, Layer, Image, Rect, Text } from 'react-konva';
import enviarImagemParaServidor from './enviarImagemParaServidor';

const MAX_RESOLUTION = 500;

const ComponenteCamera = ({ disc_id, aula_id, nomeAlunoClicado, receberDadosImagem, setNomeAlunoClicado, enviarDadosApi }) => {
  const stageRef = useRef(null);
  const inputRef = useRef(null);

  const [dadosImagem, setDadosImagem] = useState(null);
  const [imagem, setImagem] = useState(null);
  const [nivelZoom, setNivelZoom] = useState(1);
  const [ultimaDistanciaZoom, setUltimaDistanciaZoom] = useState(0);
  const [rostos, setRostos] = useState([]);
  const [dadosApi, setDadosApi] = useState(null);
  const [dadosApiAnterior, setDadosApiAnterior] = useState(null);
  const [carregandoApi, setCarregandoApi] = useState(true);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setDadosImagem({
          previsaoImagem: reader.result,
          arquivoImagem: file,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  useLayoutEffect(() => {
    if (dadosImagem?.previsaoImagem) {
      setCarregandoApi(true);
      enviarImagemParaServidor({
        dadosImagem: dadosImagem,
        disc_id: disc_id,
        aula_id: aula_id,
        setDadosApi: setDadosApi,
        setCarregando: () => setCarregandoApi(false),
      });

      const img = new window.Image();
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        if (img.width > MAX_RESOLUTION || img.height > MAX_RESOLUTION) {
          if (img.width > img.height) {
            img.width = MAX_RESOLUTION;
            img.height = MAX_RESOLUTION / aspectRatio;
          } else {
            img.height = MAX_RESOLUTION;
            img.width = MAX_RESOLUTION * aspectRatio;
          }
        }
        setImagem(img);
        if (stageRef.current) {
          const offsetX = (stageRef.current.width() - img.width) / 2;
          const offsetY = (stageRef.current.height() - img.height) / 2;
          stageRef.current.position({ x: offsetX, y: offsetY });
          stageRef.current.batchDraw();
        }
      };
      img.src = dadosImagem.previsaoImagem;
    }
  }, [dadosImagem, disc_id, aula_id]);

  const manipularZoom = (novaEscala) => {
    const stage = stageRef.current;
    if (!stage) return;

    const pontoMouse = {
      x: stage.getPointerPosition()?.x || 0,
      y: stage.getPointerPosition()?.y || 0,
    };

    const novaPosicao = {
      x: -(pontoMouse.x - stage.x()) / nivelZoom + pontoMouse.x / novaEscala,
      y: -(pontoMouse.y - stage.y()) / nivelZoom + pontoMouse.y / novaEscala,
    };

    setNivelZoom(novaEscala);
    stage.scale({ x: novaEscala, y: novaEscala });
    stage.position(novaPosicao);
    stage.batchDraw();
  };

  const manipularZoomPinca = (e) => {
    e.evt.preventDefault();
    if (e.evt.touches.length === 2) {
      const [toque1, toque2] = e.evt.touches;
      const distanciaAtualZoom = Math.hypot(toque1.clientX - toque2.clientX, toque1.clientY - toque2.clientY);
      const deltaZoom = distanciaAtualZoom - ultimaDistanciaZoom;
      const novaEscala = Math.max(0.7, Math.min(nivelZoom + deltaZoom * 0.01, 3));
      manipularZoom(novaEscala);
      setUltimaDistanciaZoom(distanciaAtualZoom);
    }
  };

  const manipularZoomScroll = (e) => {
    e.evt.preventDefault();
    const novaEscala = Math.max(0.7, nivelZoom * (e.evt.deltaY > 0 ? 1.02 : 0.98));
    manipularZoom(novaEscala);
  };

  const manipularInicioToque = (e) => {
    e.evt.preventDefault();
    if (e.evt.touches.length === 2) {
      const [toque1, toque2] = e.evt.touches;
      setUltimaDistanciaZoom(Math.hypot(toque1.clientX - toque2.clientX, toque1.clientY - toque2.clientY));
    }
  };

  const manipularCliqueRosto = (index) => {
    setNomeAlunoClicado('Desconhecido');

    const novosRostos = rostos.map((rosto, i) => ({
      ...rosto,
      estado: i === index ? (rosto.estado === 'ativo' ? 'desativado' : 'ativo') : 'desativado',
      nome: i === index ? (rosto.estado === 'conhecido' ? 'Desconhecido' : nomeAlunoClicado || 'Desconhecido') : rosto.nome,
    }));

    setRostos(novosRostos);

    const dadosApiAtualizados = {
      ...dadosApi,
      rostos: dadosApi.rostos.map((rosto, i) => ({
        ...rosto,
        nome: i === index ? novosRostos[i].nome : rosto.nome,
      })),
    };

    setDadosApi(dadosApiAtualizados);
    receberDadosImagem(dadosApiAtualizados);
  };

  // eslint-disable-next-line
  const gerarRostosDaApi = React.useCallback((dadosApi) => {
    if (!dadosApi || !dadosApi.rostos || !imagem) return;

    const novosRostos = dadosApi.rostos.map((item) => {
      const [x, y, largura, altura] = [
        item.coordenada[0] * imagem.width,
        item.coordenada[1] * imagem.height,
        item.coordenada[2] * imagem.width,
        item.coordenada[3] * imagem.height,
      ];

      const rostoAtivo = rostos.find(
        (rosto) => rosto.x === x && rosto.y === y && rosto.largura === largura && rosto.altura === altura && rosto.estado === 'ativo'
      );

      const estado = rostoAtivo ? 'ativo' : item.nome === 'Desconhecido' ? 'desativado' : 'conhecido';

      return { x, y, largura, altura, nome: item.nome, estado };
    });

    setRostos(novosRostos)
  });

  const calcularTamanhoFonte = (rosto) => {
    let tamanhoFonte = 40;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) return tamanhoFonte;

    context.font = `${tamanhoFonte}px Arial`;
    let larguraTexto = context.measureText(rosto.nome).width;

    while (larguraTexto > rosto.largura) {
      tamanhoFonte--;
      context.font = `${tamanhoFonte}px Arial`;
      larguraTexto = context.measureText(rosto.nome).width;
    }

    return tamanhoFonte;
  };

  useEffect(() => {
    if (dadosApi && JSON.stringify(dadosApi) !== JSON.stringify(dadosApiAnterior)) {
      enviarDadosApi(dadosApi);
      setDadosApiAnterior(dadosApi);
    }
  }, [dadosApi, dadosApiAnterior, enviarDadosApi]);

  useEffect(() => {
    if (dadosApi && JSON.stringify(dadosApi) !== JSON.stringify(dadosApiAnterior)) {
      gerarRostosDaApi(dadosApi);
      receberDadosImagem(dadosApi);
      setDadosApiAnterior(dadosApi);
    }
  }, [dadosApi, dadosApiAnterior, receberDadosImagem, gerarRostosDaApi]);

  useEffect(() => {
    if (nomeAlunoClicado && nomeAlunoClicado !== 'Desconhecido') {
      const indexRostoAtivo = rostos.findIndex((rosto) => rosto.estado === 'ativo');
      if (indexRostoAtivo !== -1) {
        const novosRostos = rostos.map((rosto, index) => ({
          ...rosto,
          estado: index === indexRostoAtivo ? 'conhecido' : rosto.estado,
          nome: index === indexRostoAtivo ? nomeAlunoClicado : rosto.nome,
        }));
        setRostos(novosRostos);

        const dadosApiAtualizados = {
          ...dadosApi,
          rostos: dadosApi.rostos.map((rosto, i) => ({
            ...rosto,
            nome: i === indexRostoAtivo ? nomeAlunoClicado : rosto.nome,
          })),
        };

        setDadosApi(dadosApiAtualizados);
        receberDadosImagem(dadosApiAtualizados);
        setNomeAlunoClicado('Desconhecido');
      }
    }
  }, [nomeAlunoClicado, rostos, dadosApi, receberDadosImagem, setNomeAlunoClicado]);

  return (
    <div style={{ position: 'relative' }}>
      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        ref={inputRef}
        style={{ display: 'none' }}
      />
      {imagem && (
        <Stage
          ref={stageRef}
          width={imagem.width}
          height={imagem.height}
          draggable
          onWheel={manipularZoomScroll}
          onTouchMove={manipularZoomPinca}
          onTouchStart={manipularInicioToque}
        >
          <Layer>
            <Image image={imagem} />
            {rostos.map((rosto, index) => (
              <Rect
                key={index}
                x={rosto.x}
                y={rosto.y}
                width={rosto.largura}
                height={rosto.altura}
                stroke={rosto.estado === 'ativo' ? 'yellow' : rosto.estado === 'conhecido' ? 'green' : 'red'}
                strokeWidth={3}
                onClick={() => manipularCliqueRosto(index)}
              />
            ))}
            {rostos.map((rosto, index) => (
              <Text
                key={index}
                text={rosto.nome}
                x={rosto.x}
                y={rosto.y - calcularTamanhoFonte(rosto)}
                fontSize={calcularTamanhoFonte(rosto)}
                fontFamily="Arial"
                fill={rosto.estado === 'ativo' ? 'yellow' : rosto.estado === 'conhecido' ? 'green' : 'red'}
                width={rosto.largura}
                align="center"
              />
            ))}
          </Layer>
        </Stage>
      )}
      {carregandoApi && (
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          fontSize: '20px'
        }}>
          <img src="/imagens/loading.gif" alt="Carregando..." />.
        </div>
      )}
    </div>
  );
};

export default ComponenteCamera;
