import React from 'react';

const BotoesStatus = ({ statusPossiveis, onClickStatus, statusSelecionado }) => {
  return (
    <div className="flex flex-wrap gap-2">
      {statusPossiveis.map((status, index) => (
        <button
          key={index}
          className={`py-1 px-3 rounded-md ${statusSelecionado === status ? 'bg-green-500' : 'bg-gray-400'}`}
          onClick={() => onClickStatus(status)}
        >
          {status}
        </button>
      ))}
    </div>
  );
};

export default BotoesStatus;
