import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './index.css';

import LoginForm from './root/paginas/forms/loginForm';
import MenuConta from './root/paginas/menuConta';
import Presenca from './root/paginas/presenca';
import Progressao from './root/paginas/progressao';
import Peticao from './root/paginas/peticao';
import PeticaoNova from './root/paginas/aluno/suaPeticao/nova_peticao';
import DadosAluno from './root/paginas/aluno/suaPeticao/dados_aluno';
import DadosBanco from './root/paginas/aluno/suaPeticao/banco_aluno';
import AssistenteEstudantil from './root/paginas/assistente/assistenteEstudantil/painel';
import AssistenteAcademico from './root/paginas/assistente/assistenteAcademico/painel';
import Chat from './root/components/ChatLogado'

function App() {
  return (
    <main>
      <Routes>
        {/* para usuarios não logados */}
        <Route >
          <Route path='/' element={<LoginForm />} />
        </Route>

        {/* somente para usuarios logados*/}
        <Route>
          <Route path='/menu' element={<MenuConta />} />

          <Route path='/chat' element={<Chat />} />
          
          <Route path='/sua-presenca' element={<Presenca />} />
          <Route path='/sua-progressao' element={<Progressao />} />
          <Route path='/sua-peticao' element={<Peticao />} />

          <Route path='/sua-peticao/nova' element={<PeticaoNova/>} />
          <Route path='/sua-peticao/dados-pessoais' element={<DadosAluno/>} />
          <Route path='/sua-peticao/dados-bancarios' element={<DadosBanco/>} />

          <Route path='/sua-peticao/painel' element={<AssistenteEstudantil/>} />
          <Route path='/sua-progressao/painel' element={<AssistenteAcademico/>} />
        </Route>

      </Routes>
    </main>
  );
}

export default App;