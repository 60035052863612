import React, { useState, useEffect } from 'react';

const CheckboxesModalidade = ({ modalidades, onChangeModalidade }) => {
  const [selectedModalidades, setSelectedModalidades] = useState([]);

  const handleCheckboxChange = (modalidade) => {
    const index = selectedModalidades.indexOf(modalidade);
    if (index === -1) {
      setSelectedModalidades([...selectedModalidades, modalidade]);
    } else {
      const updatedModalidades = [...selectedModalidades];
      updatedModalidades.splice(index, 1);
      setSelectedModalidades(updatedModalidades);
    }
  };

  useEffect(() => {
    onChangeModalidade(selectedModalidades);
  }, [selectedModalidades, onChangeModalidade]);

  return (
    <div>
      <h1 className="text-lg font-bold">Modalidade</h1>
      <ul className="flex flex-wrap mt-2">
        {modalidades.map((modalidade, index) => (
          <li key={index} className="mr-4 mb-2">
            <input
              type="checkbox"
              id={`modalidade-${index}`}
              checked={selectedModalidades.includes(modalidade)}
              onChange={() => handleCheckboxChange(modalidade)}
            />
            <label htmlFor={`modalidade-${index}`} className="font-bold ml-2">{modalidade}</label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CheckboxesModalidade;
