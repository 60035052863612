import React, { useState, useEffect, useCallback } from 'react';
import TabelaChamada from './tabelaChamada';
import ComponenteCamera from './componenteCamera';
import Notificacao from '../../../components/ui/notificacao';
import { BsPersonBoundingBox } from "react-icons/bs";

const Chamada = ({ id_disc, id_aula, setTabelaVisivel }) => {
  const token = localStorage.getItem('token');
  const [alunos, setAlunos] = useState([]);
  const [presencaAlunos, setPresencaAlunos] = useState({});
  const [showCamera, setShowCamera] = useState(false);
  const [buffer, setBuffer] = useState(null);
  const [nomeClickado, setNomeClickado] = useState('Desconhecido');
  const [showNotification, setShowNotification] = useState(false);
  const [errorNotification, setErrorNotification] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [dadosApi, setDadosApi] = useState(null);
  const [botaoClicado, setBotaoClicado] = useState(false);

  const encontrarAlunoIdPorNome = useCallback((nome) => {
    const alunoEncontrado = alunos.find(aluno => aluno.nome_aluno === nome);
    return alunoEncontrado ? alunoEncontrado.id_aluno : null;
  }, [alunos]);

  useEffect(() => {
    const carregarDadosChamada = () => {
      fetch(`https://suapuno.app:8080/api/aulas/${id_aula}/chamada/`, {
        method: 'GET',
        headers: {
          'Authorization': `Token ${token}`,
        }
      })
      .then(response => response.json())
      .then((data) => {
        const presencaAlunosAtualizada = {};
        data.forEach(item => {
          presencaAlunosAtualizada[item.id_aluno] = item.horas_faltas === 0;
        });
        setPresencaAlunos(presencaAlunosAtualizada);
        setAlunos(data); // Atualiza o estado alunos com os dados da API
      })
      .catch(error => {
        console.error('Erro ao carregar dados de chamada:', error);
        setErrorNotification(true);
        setErrorMessage('Erro ao carregar dados de chamada. Por favor, tente novamente.');
      });
    };

    carregarDadosChamada();
  }, [id_disc, id_aula, token]);

  const verificarTick = (id_aluno) => {
    setPresencaAlunos(prevState => {
      const novoEstado = { ...prevState };
      novoEstado[id_aluno] = !prevState[id_aluno];

      if (novoEstado[id_aluno]) {
        const alunoIndex = alunos.findIndex(aluno => aluno.id_aluno === id_aluno);
        if (alunoIndex !== -1) {
          alunos[alunoIndex].camera = false;
        }
      }
      setBotaoClicado(true);
      return novoEstado;
    });
  };

  const enviarChamadaPorImagem = useCallback(({ id_disc, id_aula, dadosApi }) => {
    if (dadosApi) {
      const body = {
        imagem_id: dadosApi.imagem_id,
        rostos: dadosApi.rostos.map(rosto => ({
          nome: rosto.nome,
          coordenada: rosto.coordenada
        }))
      };
      const dataArray = [body];

      fetch(`https://suapuno.app:8080/api/minhas-disciplinas/${id_disc}/fotochamada/${id_aula}/`, {
        method: 'PUT',
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataArray),
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Erro ao enviar chamada: ' + response.statusText);
        }
      })
      .then(data => {
        console.log(data);
      })
      .catch(error => console.error('Erro ao enviar dados de chamada:', error));
    }
  }, [token]);

  const handleRealizarChamada = useCallback(() => {
    const chamadaJSON = alunos.map(aluno => ({
      id_aluno: aluno.id_aluno,
      horas_faltas: presencaAlunos[aluno.id_aluno] ? 0 : 1
    }));

    fetch(`https://suapuno.app:8080/api/aulas/${id_aula}/chamada/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      },
      body: JSON.stringify(chamadaJSON)
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Erro na requisição: ' + response.statusText);
      } else {
        setShowNotification(true);
        setTimeout(() => {
          setShowNotification(false);
        }, 1000);
      }
    })
    .catch(error => {
      console.error('Erro:', error);
      setErrorNotification(true);
      setErrorMessage('Erro ao realizar chamada. Por favor, tente novamente.');
    });

    if (dadosApi) {
      enviarChamadaPorImagem({ id_disc, id_aula, dadosApi });
    }
    setBotaoClicado(false);
  }, [dadosApi, id_disc, id_aula, alunos, presencaAlunos, token, enviarChamadaPorImagem]);

  const toggleCamera = () => {
    setShowCamera(!showCamera);
  };

  const atualizarPresencaImagem = (dadosModificados) => {
    if (!buffer) {
      const novoEstado = { ...presencaAlunos };
      dadosModificados.forEach((item) => {
        const id_aluno = encontrarAlunoIdPorNome(item.nome);
        if (id_aluno !== null) {
          novoEstado[id_aluno] = true;
          const aluno = alunos.find((aluno) => aluno.id_aluno === id_aluno);
          if (aluno) {
            aluno.camera = true;
          }
        }
      });
      setPresencaAlunos(novoEstado);
      setBuffer(dadosModificados);
    } else {
      let alterado = false;
      const novoEstado = { ...presencaAlunos };

      for (let i = 0; i < buffer.length; i++) {
        const itemBuffer = buffer[i];
        const encontrado = dadosModificados.find((item) => item.nome === itemBuffer.nome);

        if (!encontrado) {
          const id_aluno = encontrarAlunoIdPorNome(itemBuffer.nome);
          if (id_aluno !== null) {
            novoEstado[id_aluno] = false;
            const aluno = alunos.find((aluno) => aluno.id_aluno === id_aluno);
            if (aluno) {
              aluno.camera = false;
            }
            alterado = true;
          }
        }
      }

      dadosModificados.forEach((item) => {
        const id_aluno = encontrarAlunoIdPorNome(item.nome);
        if (id_aluno !== null && !buffer.find((bufferItem) => bufferItem.nome === item.nome)) {
          novoEstado[id_aluno] = true;
          const aluno = alunos.find((aluno) => aluno.id_aluno === id_aluno);
          if (aluno) {
            aluno.camera = true;
          }
          alterado = true;
        }
      });

      if (alterado) {
        setPresencaAlunos(novoEstado);
      }
    }
    setBuffer(dadosModificados);
  };

  const receberDadosImagem = (dados) => {
    if (dados && dados.rostos && Array.isArray(dados.rostos)) {
      const dadosModificados = dados.rostos.map((rosto) => ({
        nome: rosto.nome,
        status: rosto.nome === 'Desconhecido' ? 'F' : 'V',
        camera: true
      }));
      atualizarPresencaImagem(dadosModificados);

      if (!botaoClicado) {
        setBotaoClicado(true);
      }
    } else {
      console.error("Erro ao receber dados da imagem, alguma coisa na formatação dos dados está errada");
      setErrorNotification(true);
      setErrorMessage('Erro ao receber dados da imagem. Por favor, tente novamente.');
    }
  };

  const handleNomeClickado = (nome) => {
    setNomeClickado(nome);
  };

  useEffect(() => {
    if (showNotification) {
      const timer = setTimeout(() => {
        setShowNotification(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [showNotification]);

  return (
    <div className='w-full'>
      <div className={'flex flex-row justify-between py-3'}>
        <button className='flex flex-row items-center px-5 py-3 ml-5 bg-white shadow-xl' onClick={toggleCamera}>
          <BsPersonBoundingBox size={35}/>
          <h1 className='ml-2 font-bold'>Foto</h1>
        </button>
        <div>
          <button className={`flex flex-row items-center px-5 py-3 ml-5 mt-2 shadow-xl rounded ${botaoClicado ? 'bg-green-400' : 'bg-gray-400'}`} onClick={handleRealizarChamada}>
            Realizar Chamada
          </button>
        </div>
      </div>
      {showCamera && (
        <ComponenteCamera
          id_disc={id_disc}
          id_aula={id_aula}
          alunos={alunos}
          receberDadosImagem={receberDadosImagem}
          setElementosVisiveis={toggleCamera}
          nomeAlunoClicado={nomeClickado}
          setNomeAlunoClicado={handleNomeClickado}
          enviarDadosApi={setDadosApi}
        />
      )}
      <>
        <TabelaChamada
          alunos={alunos}
          presencaAlunos={presencaAlunos}
          verificarTick={verificarTick}
          receberNome={handleNomeClickado}
        />
      </>
      {showNotification && (
        <Notificacao
          tipo='sucesso'
          mensagem='Chamada realizada com sucesso!'
          isVisible={showNotification}
          setIsVisible={setShowNotification}
        />
      )}
      {errorNotification && (
        <Notificacao
          tipo='erro'
          mensagem={errorMessage}
          isVisible={errorNotification}
          setIsVisible={setErrorNotification}
        />
      )}
    </div>
  );
};

export default Chamada;
