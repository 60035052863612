import { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

const CardCadeira = ({ aberto, oculto, toggleEstado, children }) => {
  const [isVisible, setIsVisible] = useState(!oculto);

  useEffect(() => {
    if (!oculto) {
      const timer = setTimeout(() => {
        setIsVisible(!oculto);
      }, 0);
      return () => clearTimeout(timer);
    } else {
      setIsVisible(false);
    }
  }, [oculto]);

  const handleButtonClick = (event) => {
    event.stopPropagation();
    toggleEstado();
  };

  return (
    <div
      className={`m-3 flex bg-gray-100 shadow-[0px_0px_10px_7px_rgba(0,0,0,0.2)]
      ${aberto ? 'top-0 left-0 inset-0 justify-center rounded-2xl w-full h-full z-10 ' : isVisible ? `${isMobile ? 'flex p-5 z-0 rounded-3xl w-full' : 'flex p-10 z-0 rounded-3xl'}` : 'hidden'} text-center`}
    >
      {aberto && (
        <button className="flex absolute top-0 right-0 mt-16 m-4 mr-8 font-bold" onClick={handleButtonClick}>
          X
        </button>
      )}
      <div className="w-full m-4">
        {children}
      </div>
    </div>
  );
};

export default CardCadeira;