import React, { useEffect, useState } from 'react';
import { PiFilePdf, PiX, PiCheck, PiWarningCircle, PiPlusLight, PiFiles, PiCheckCircle } from "react-icons/pi";

const PrestacaoDeContas = ({ comprovante_participacao, peticaoId, numeroProcesso }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [feedBack, setFeedBack] = useState(false);
  const [telaBoleto, setTelaBoleto] = useState(false);
  const [referencia, setReferencia] = useState('');
  const [valor, setValor] = useState('');
  const token = localStorage.getItem('token');
  const [comentario, setComentario] = useState('');

  useEffect(() => {
    if (numeroProcesso) {  
      setReferencia(numeroProcesso);
    }
  }, [numeroProcesso, setReferencia]);

  const handleInputChange = (e) => {
    setComentario(e.target.value);
  };

  const abreModal = (content) => {
    setModalContent(content);
    setShowModal(true);
  }

  const fechaModal = (feedbackType) => {
    setFeedBack(feedbackType);
    setModalContent(null);
    setShowModal(false);
  }

  const Concluir = () => {
    fetch(`https://suapuno.app:8080/api/peticao/${peticaoId}/`, {
      method: 'PUT',
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        comentario: comentario,
        concluir: true
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Erro ao processar a solicitação');
      }
      window.location.reload();
    })
    .catch(error => {
      console.error('Erro ao concluir petição:', error);
    });
  }

  const GerarGRU = async (valor, referencia) => {
    const valorConvertido = valor.replace(',', '.'); // Conversão de vírgula para ponto
    fetch(`https://suapuno.app:8080/api/peticao/${peticaoId}/gerarGRU/`, {
      method: 'POST',
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        numero_processo: referencia,
        valor_gru: valorConvertido
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Erro ao processar a solicitação');
      }
      return response.json();
    })
    .then( () => {
      window.location.reload();
    })
    .catch(error => {
      console.error('Erro ao gerar GRU:', error);
    });
  }

  const Corrigir = () => {
    fetch(`https://suapuno.app:8080/api/peticao/${peticaoId}/`, {
        method: 'PUT',
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          verificado_comprovante_participacao: false
        })
      })
      .then(response => {
          if (!response.ok) {
            throw new Error('Erro ao processar a solicitação');
          }
          window.location.reload();
        })
        .catch(error => {
          console.error('Erro ao solicitar correções:', error);
        });
  }

  return (
    <div className='flex flex-col bg-white h-auto my-3 pt-5 px-7 rounded-lg shadow-[0px_0px_10px_7px_rgba(0,0,0,0.2)] relative'>
      <div>
        <h1 className='bg-yellow-500 py-1 px-20 rounded-lg text-white text-center'>Prestacao de contas</h1>
        <div className='flex flex-row my-4 justify-center items-center'>
          {!comprovante_participacao ? (
            <>
              <PiFiles size={60} color='gray' />
              <h1 className='text-center text-gray-500'>O aluno não enviou <br /> nenhuma comprovação de <br />participação!</h1>
            </>
          ) :
            <h1 className='flex flex-row font-bold mt-3 items-center'>Comprovante de<br /> participação
              <span className='font-normal'>
                <button className='ml-7 flex flex-row items-center justify-center border border-black rounded-lg border-spacing-13 p-2' onClick={() => abreModal(comprovante_participacao)}>
                  <PiFilePdf color='red' size={35} className='ml-1' />
                  <h1 className='mr-2 text-gray-500 text-sm'>Acessar</h1>
                  {feedBack === "inválido" && <PiWarningCircle color='red' size={20} className='absolute ml-40' />}
                  {feedBack === "válido" && <PiCheckCircle color='green' size={20} className='absolute ml-40' />}
                </button>
              </span>
            </h1>
          }
        </div>
      </div>
      <div className='border-b border-gray-300 -mx-2'></div>
      {!telaBoleto ? (
        <>
          <button className='my-3 flex flex-row justify-between' onClick={() => setTelaBoleto(true)}>
            <h1 className='font-bold'>Gerar devolução via GRU</h1>
            <PiPlusLight size={20} color='black' />
          </button>
          <div className='border-b border-gray-300 -mx-2'></div>
          { (!feedBack || feedBack === "válido") && <h1 className='bg-[#0DB30B] py-1 px-20 rounded-lg mt-2 text-white text-center'>Prestacao de contas</h1>}
          { feedBack === "inválido" && <h1 className='bg-red-600 py-1 px-20 rounded-lg mt-2 text-white text-center'>Feedback ao aluno</h1>}
          <textarea className="mt-2 p-2 w-[100%] bg-[#DDDDDD] rounded-lg border-gray-300 border-2" style={{ resize: 'none' }} value={comentario} onChange={handleInputChange}></textarea>
          <div className='flex flex-row justify-end'>
            { (!feedBack || feedBack === "válido") && <button className='text-white bg-green-800 px-3 py-[6px] rounded-lg my-4 mr-2' onClick={Concluir}>Concluir petição</button>}
            { feedBack === "inválido" && <button className='text-white bg-green-800 px-3 py-[6px] rounded-lg my-4 mr-2' onClick={Corrigir}>Solicitar correções</button>}
          </div>
        </>
      ) : (
        <>
          <h1 className='font-bold mt-[2px]'>Numero de referência</h1>
          <h1 className='italic -mt-2'>exemplo: número processo SEI de pagamento</h1>
          <input
            className="mt-2 p-2 w-[100%] h-8 bg-[#DDDDDD] rounded-lg border-gray-300 border-2"
            style={{ resize: 'none' }}
            value={referencia}
            onChange={(e) => setReferencia(e.target.value)}
          />
          <h1 className='font-bold mt-2'>Valor para devolução</h1>
          <input
            className="mt-2 p-2 w-[100%] h-8 bg-[#DDDDDD] rounded-lg border-gray-300 border-2"
            style={{ resize: 'none' }}
            value={valor}
            onChange={(e) => setValor(e.target.value)}
          />
          <div className='border-b border-gray-300 -mx-2 mt-3'></div>
          <div className='flex flex-row justify-end'>
            <button className='text-white bg-red-600 px-3 py-[6px] rounded-lg my-4 mr-2' onClick={() => setTelaBoleto(false)}>Cancelar</button>
            <button
              className={`text-white bg-green-800 px-3 py-[6px] rounded-lg my-4 mr-2 ${!referencia || !valor ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={() => GerarGRU(valor, referencia)}
              disabled={!referencia || !valor}
            >
              Gerar GRU
            </button>
          </div>
        </>
      )}
      {showModal && (
        <div className='fixed top-0 left-0 bg-black bg-opacity-50 w-full h-full flex items-center justify-center z-50'>
          <div className='bg-[#333333] w-1/2 h-[70%] rounded-lg -mt-36'>
            <div className='flex justify-center w-full h-full'>
              <iframe title="PDF Viewer" src={modalContent} className="w-full h-full"></iframe>
            </div>
            <div className='flex justify-end bg-black rounded-b-lg'>
              <h1 className='flex items-center text-white text-lg mr-10'>Esse documento é válido?</h1>
              <button onClick={() => fechaModal("inválido")} className='mr-5 my-4 py-3 px-5 rounded-lg text-white bg-red-600'><PiX size={25} /></button>
              <button onClick={() => fechaModal("válido")} className='mr-5 my-4 py-3 px-5 rounded-lg text-white bg-green-600'><PiCheck size={25} /></button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PrestacaoDeContas;