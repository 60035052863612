import React, { useEffect, useState } from 'react';
import { PiCaretDown, PiGraduationCap } from "react-icons/pi";
import Card from "../../../components/ui/cardGenerico";

const CalcularEtapa = ({ dados, discId, etapaId }) => {
    const token = localStorage.getItem('token');
    const [menuDropdownAberto, setMenuDropdownAberto] = useState(false);
    const [metodoSelecionado, setMetodoSelecionado] = useState(dados.calculo_etapa || 'Média ponderada');
    const [desempenho, setDesempenho] = useState([]);
    const [infoEtapas, setInfoDisciplina] = useState([]);
    const [status, setStatus] = useState(dados.status);
    const isEntregue = dados.status === "Entregue";

    useEffect(() => {
        const fetchData = async () => {
            try {
                const etapasResponse = await fetch('https://suapuno.app:8080/api/informacoes/etapas-disciplina/', {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Token ${token}`
                    }
                });

                if (!etapasResponse.ok) {
                    throw new Error('Falha ao acessar a API');
                }

                const etapasData = await etapasResponse.json();
                setInfoDisciplina(etapasData);

                const desempenhoResponse = await fetch(`https://suapuno.app:8080/api/diarios/${discId}/desempenho/${etapaId}/`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Token ${token}`
                    }
                });

                if (!desempenhoResponse.ok) {
                    throw new Error('Falha ao acessar a API');
                }

                const desempenhoData = await desempenhoResponse.json();
                setDesempenho(desempenhoData);
            } catch (erro) {
                console.error(erro);
            }
        };

        fetchData();

        let etapa = dados.etapa;

        fetch(`https://suapuno.app:8080/api/diarios/${discId}/avaliacoes/`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        .then((resposta) => {
            if (!resposta.ok) {
                throw new Error('Falha ao acessar a API');
            }
            return resposta.json();
        })
        .then((dados) => {
            for(let i=0; i<dados.length; i++){
                if(dados[i].etapa === etapa){
                    setMetodoSelecionado(dados[i].calculo_etapa);
                }
            }
        })
        .catch((erro) => console.error(erro));
    }, [token, discId, etapaId, dados.etapa]);

    const handleMetodoSelecionado = async (metodo) => {
        setMetodoSelecionado(metodo);

        const infoEtapa = infoEtapas.find(info => info.etapa === dados.etapa);
        if (!infoEtapa) {
            console.error('Etapa não encontrada');
            return;
        }

        try {
            const response = await fetch(`https://suapuno.app:8080/api/diarios/${discId}/`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
                body: JSON.stringify({
                    media_etapa: {
                        id_etapa: infoEtapa.id_etapa,
                        descricao_calculo_media: metodo
                    }
                })
            });

            if (!response.ok) {
                throw new Error('Falha ao acessar a API');
            }

            await response.json();
        } catch (erro) {
            console.error(erro);
        }

        setMenuDropdownAberto(false);
    };

    const calcularMedia = (avaliacoes) => {
        const calcularMediaPonderada = (avaliacoes) => {
            const totalPeso = avaliacoes.reduce((total, avaliacao) => total + avaliacao.peso_avaliacao, 0);
            const totalNota = avaliacoes.reduce((total, avaliacao) => total + (avaliacao.nota * avaliacao.peso_avaliacao), 0);
            return totalNota / totalPeso;
        };

        const calcularMediaAritmetica = (avaliacoes) => {
            const totalNotas = avaliacoes.reduce((total, avaliacao) => total + avaliacao.nota, 0);
            return totalNotas / avaliacoes.length;
        };

        const calcularSoma = (avaliacoes) => {
            return avaliacoes.reduce((total, avaliacao) => total + avaliacao.nota, 0);
        };

        const calcularMaiorNota = (avaliacoes) => {
            return Math.max(...avaliacoes.map(avaliacao => avaliacao.nota), 0);
        };

        const calcularSomaComPesos = (avaliacoes) => {
            return avaliacoes.reduce((total, avaliacao) => total + (avaliacao.nota * avaliacao.peso_avaliacao), 0);
        };

        let media;
        switch (metodoSelecionado) {
            case 'Média ponderada':
                media = calcularMediaPonderada(avaliacoes);
                break;
            case 'Média aritmética':
                media = calcularMediaAritmetica(avaliacoes);
                break;
            case 'Soma':
                media = calcularSoma(avaliacoes);
                break;
            case 'Maior nota':
                media = calcularMaiorNota(avaliacoes);
                break;
            case 'Soma com pesos':
                media = calcularSomaComPesos(avaliacoes);
                break;
            default:
                media = calcularMediaPonderada(avaliacoes);
        }

        return media;
    };

    const corMedia = (media) => {
        if (media < 5) {
            return 'bg-red-700 text-white rounded-lg w-10 h-7 items-center text-center';
        } else if (media < 7.5) {
            return 'bg-yellow-500 text-white rounded-lg w-10 h-7 items-center text-center';
        } else {
            return 'bg-green-700 text-white rounded-lg w-10 h-7 items-center text-center';
        }
    };

    const apropriar = async (opcao) => {
        let url;

        if (opcao === 1) {
            url = `https://suapuno.app:8080/api/diarios/${discId}/apropriar-etapa/${etapaId}/`;
        } else if (opcao === 2) {
            url = `https://suapuno.app:8080/api/diarios/${discId}/entregar-etapa/${etapaId}/`;
        }

        if (url) {
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Token ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Falha ao acessar a API');
                }

                await response.json();
                if (opcao === 1) {
                    setStatus('Apropriado');
                } else if (opcao === 2) {
                    setStatus('Entregue');
                }
            } catch (erro) {
                console.error(erro);
            }
        }
    };

    return (
        <div className="">
            <div className="flex justify-start gap-4 mb-3">
                <div className='flex items-center'>
                    <div className='mr-2'>
                        {status === 'Não apropriado' && (<div className='h-2 w-2 rounded-full bg-yellow-400'></div>)}
                        {status === 'Apropriado' && (<div className='h-2 w-2 rounded-full bg-yellow-400'></div>)}
                        {status === 'Entregue' && (<div className='h-2 w-2 rounded-full bg-green-600'></div>)}
                    </div>
                    <h1>
                        {status === 'Não apropriado' && (<h1 className='text-yellow-400 font-semibold'>Em andamento</h1>)}
                        {status === 'Apropriado' && (<h1 className='text-yellow-400 font-semibold'>Etapa apropriada</h1>)}
                        {status === 'Entregue' && (<h1 className='text-green-600 font-semibold'>Etapa entregue</h1>)}
                    </h1>
                </div>
                <button className="text-white flex-grow items-center ">
                    {status === 'Não apropriado' && (<h1 onClick={() => apropriar(1)} className='bg-green-700 rounded-lg h-9 flex items-center justify-center'>Apropriar</h1>)}
                    {status === 'Apropriado' && (<h1 onClick={() => apropriar(2)} className='bg-green-700 rounded-lg h-9 flex items-center justify-center'>Entregar</h1>)}
                    {status === 'Entregue' && (<h1 className='bg-red-700 rounded-lg h-9 flex items-center justify-center'>Solicitar acesso à etapa</h1>)}
                </button>
            </div>
            <Card
                icone={<PiGraduationCap size={23} />}
                titulo={
                    <div className='w-full flex justify-between items-center'>
                        <h1 className='font-bold'>Resultado dos alunos</h1>
                        <button className={`${isEntregue ? 'bg-gray-400 ' : 'bg-green-600'} px-3 py-[2px] ml-1 rounded-xl text-white text-xs`} disabled={isEntregue} onClick={() => { setMenuDropdownAberto(!menuDropdownAberto) }} aria-expanded={menuDropdownAberto}>
                            <div className='flex flex-row'>
                                {metodoSelecionado}
                                {!isEntregue && <PiCaretDown className='mt-[2px] ml-1' />}
                            </div>
                        </button>
                    </div>
                }
                conteudo={
                    <>
                        {menuDropdownAberto && (
                            <div
                                className={`absolute right-2 bg-white border shadow-[0px_0px_10px_3px_rgba(0,0,0,0.2)]  -mt-10 p-3 rounded-md z-10`}>
                                {['Média ponderada', 'Média aritmética', 'Soma', 'Soma com pesos', 'Maior nota'].map((metodo) => (
                                    <p
                                        key={metodo}
                                        onClick={() => handleMetodoSelecionado(metodo)}
                                        className="cursor-pointer hover:bg-gray-200"
                                    >
                                        {metodo}
                                    </p>
                                ))}
                            </div>
                        )}
                        <div className="flex mt-10">
                            {/* Coluna fixa de Alunos */}
                            <div className="flex flex-col">
                                <div className="text-sm font-normal text-center min-w-20">Aluno</div>
                                {desempenho.map((aluno, index) => (
                                    <div className="flex w-full mt-2" key={index}>
                                        <div className="text-xs text-center font-bold min-w-20">
                                            {aluno.nome_aluno || 'Nome não disponível'}
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {/* Colunas de notas roláveis */}
                            <div className="flex overflow-x-auto">
                                {dados.avaliacoes.map((avaliacao, index) => (
                                    <div className="flex flex-col justify-start" key={index}>
                                        <div className="text-sm font-normal text-center min-w-20">{avaliacao.descricao_avaliacao}</div>
                                        {desempenho.map((aluno, index) => (
                                            <div className="text-xs text-center min-w-20 h-[32px] mt-1 flex items-center justify-center" key={index}>
                                                {(aluno.avaliacoes?.find(a => a.descricao_avaliacao === avaliacao.descricao_avaliacao)?.nota || '--').toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>

                            {/* Coluna fixa de Faltas */}
                            <div className="flex flex-col">
                                <div className="text-sm font-normal text-center">Faltas</div>
                                {desempenho.map((aluno, index) => (
                                    <div className="text-xs text-center h-[32px] mt-1 flex items-center justify-center" key={index}>
                                        0
                                    </div>
                                ))}
                            </div>

                            {/* Coluna fixa de Médias */}
                            <div className="flex flex-col">
                                <div className="text-sm font-normal text-center min-w-16">Média</div>
                                {desempenho.map((aluno, index) => (
                                    <div className="text-xs text-center flex justify-center mt-[6px] h-[30px]" key={index}>
                                        <div className={corMedia(calcularMedia(aluno.avaliacoes))}>
                                            <h1 className="mt-[5px]">
                                                {calcularMedia(aluno.avaliacoes).toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}
                                            </h1>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>                              
                    </>
                }
            />
        </div>
    );
};

export default CalcularEtapa;
