import CardCadeira from "../../../components/ui/cardCadeira";
import { useEffect, useState } from 'react';
import Aulas from './Aulas';
import { isBrowser, isMobile } from 'react-device-detect';

const Turma = ({setMenu}) => {
  const [indexAberto, setIndexAberto] = useState(null);
  const [turmaInfo, setTurmaInfo] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      console.error('Token não encontrado no localStorage.');
      return;
    }

    fetch('https://suapuno.app:8080/api/diarios/', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Token ${token}`
      }
    })
    .then((resposta) => {
      if (!resposta.ok) {
        throw new Error('Falha ao acessar a API');
      }
      return resposta.json();
    })
    .then((dados) => {
      setTurmaInfo(dados)
    })
    .catch((erro) => console.error(erro));
  }, []);

  const toggleEstado = (index) => () => {
    setIndexAberto(indexAberto === index ? null : index);
    isMobile && setMenu(indexAberto === index);
  };

  return (
    <div className={`${isBrowser ? 'flex' : 'flex flex-wrap'}`}>
      {turmaInfo.map((item, index) => (
        <CardCadeira
          key={item.id_diario}
          aberto={indexAberto === index}
          oculto={indexAberto !== null && indexAberto !== index}
          toggleEstado={toggleEstado(index)}
        >
          {indexAberto === index ? 
          (
            <Aulas titulo={item.turma} id={item.id_diario}/>
          ) 
          : 
          (
            <div onClick={toggleEstado(index)}>
              <button className="text-md font-semibold">{item.turma}</button>
            </div>
          )}
        </CardCadeira>
      ))}
    </div>
  );
}

export default Turma;