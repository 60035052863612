import { FaChalkboardTeacher, FaGraduationCap } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const MenuConta = () => {
    const navigate = useNavigate();

    localStorage.setItem('multiPerfil',true)

    const handleClickProfessor = () => {
        localStorage.setItem('perfis', JSON.stringify(["professor"]));
        navigate('/sua-presença');
    };

    const handleClickAluno = () => {
        localStorage.setItem('perfis', JSON.stringify(["aluno"]));
        navigate('/sua-petição');
    };

    return (
        <div className="flex items-center justify-center h-screen">
            <ul className="flex flex-col items-center justify-center">
                <li className="flex flex-col gap-10">
                    <button className="flex flex-row bg-white py-6 px-5 items-center justify-center rounded-md shadow-[0px_1px_10px_3px_rgba(0,0,0,0.2)]" onClick={handleClickProfessor}>
                        <FaChalkboardTeacher size={50}/>
                        <h2 className="pl-2 text-xl">Professor</h2>
                    </button>
                    <button className="flex flex-row bg-white py-6 px-5 items-center justify-center rounded-md shadow-[0px_1px_10px_3px_rgba(0,0,0,0.2)]" onClick={handleClickAluno}>
                        <FaGraduationCap size={50}/>
                        <h2 className="pl-2 text-xl">Aluno</h2>
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default MenuConta;
