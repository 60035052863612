import React, { useState, useEffect } from 'react';

const Avaliacao = ({ removerAvaliacao, dadosAvaliacao, handleLancarNotasClick, handleAvaliacaoChange, disabled }) => {
    const [avaliacaoData, setAvaliacaoData] = useState(dadosAvaliacao);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAvaliacaoData(prevState => ({
            ...prevState,
            [name]: value
        }));
        handleAvaliacaoChange({ ...avaliacaoData, [name]: value });
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.target.blur();
        }
    };

    useEffect(() => {
        setAvaliacaoData(dadosAvaliacao);
    }, [dadosAvaliacao]);

    return (
        <div className="relative text-gray-500">
            <div>
                <div className='flex flex-col w-full'>
                    <h1 className="font-semibold my-1 text-xs">Avaliação:</h1>
                    <div className="flex">
                        <input
                            type="text"
                            name="descricao_avaliacao"
                            value={avaliacaoData.descricao_avaliacao}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            className="border-gray-500 border px-3 py-[8px] font-semibold text-sm rounded-lg w-full bg-gray-200"
                            disabled={disabled}
                        />
                    </div>
                </div>
                <div className='flex gap-3 w-full'>
                    <div className='w-full'>
                        <h1 className="font-semibold my-1 text-xs">Nota máxima:</h1>
                        <div className="flex">
                            <input
                                type="number"
                                name="nota_max_avaliacao"
                                value={avaliacaoData.nota_max_avaliacao}
                                onChange={handleChange}
                                onKeyDown={handleKeyDown}
                                className="border-gray-500 border px-3 py-[8px] font-semibold text-sm rounded-lg w-full bg-gray-200"
                                disabled={disabled}
                            />
                        </div>
                    </div>
                    <div className='w-full'>
                        <h1 className="font-semibold my-1 text-xs">Peso da avaliação:</h1>
                        <div className="flex">
                            <input
                                type="number"
                                name="peso_avaliacao"
                                value={avaliacaoData.peso_avaliacao}
                                onChange={handleChange}
                                onKeyDown={handleKeyDown}
                                className="border-gray-500 border px-3 py-[8px] font-semibold text-sm rounded-lg w-full bg-gray-200"
                                disabled={disabled}
                            />
                        </div>
                    </div>
                </div>
                <div className='flex flex-col w-full'>
                    <h1 className="font-semibold my-1 text-xs">Data de avaliação:</h1>
                    <div className="flex">
                        <input
                            type="date"
                            name="data_avaliacao"
                            value={avaliacaoData.data_avaliacao}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            className="border-gray-500 border px-3 py-[8px] font-semibold text-sm rounded-lg w-full bg-gray-200"
                            disabled={disabled}
                        />
                    </div>
                </div>
                <div className='flex w-full text-sm font-semibold'>
                    <button onClick={() => removerAvaliacao(dadosAvaliacao.id_avaliacao)} disabled={disabled} className={`border-2 inline-block py-2 w-full rounded-xl ${disabled ? 'bg-gray-400' : 'bg-red-600'} text-white mt-3`}>
                        Remover avaliação
                    </button>
                    <button onClick={() => handleLancarNotasClick(dadosAvaliacao)} disabled={disabled} className={`border-2 inline-block py-2 w-full rounded-xl ${disabled ? 'bg-gray-400' : 'bg-green-700' } text-white mt-3`}>
                        Lançar notas
                    </button>                    
                </div>
            </div>
        </div>
    );
};

export default Avaliacao;
