import React, { useEffect, useState } from "react";
import { Bar, Line, Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend as ChartLegend } from 'chart.js';
import { PiNotePencil, PiChartLineUp, PiChartLine, PiCalendar, PiAlarm, PiStudent, PiGraduationCap, PiPerson, PiSmiley ,PiSmileySad, PiSmileyMeh, PiChartDonut, PiScroll, PiArrowRight, PiArrowLeft } from "react-icons/pi";
import MenuTopo from "../../../components/ui/menuTopo";
import Card from "../../../components/ui/cardGenerico";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  ChartLegend
);

const Disciplina = () => {
    const token = localStorage.getItem('token');
    const [rendimento, setRendimento] = useState({});
    const [dadosDiarios, setDadosDiarios] = useState([]);
    const [anoSemestre, setAnoSemestre] = useState([]);
    const [listaAnosSemestre, setListaAnosSemestre] = useState([]);
    const [mediaDesempenho, setMediaDesempenho] = useState([]);
    const [carregando, setCarregando] = useState(true);
    const [agenda, setAgenda] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [celulaSelecionada, setCelulaSelecionada] = useState(null); 
    const [disciplinaSelecionada, setDisciplinaSelecionada] = useState(null);

    useEffect(() => {
        aoLogin();
    // eslint-disable-next-line
    }, [token]);

    const aoLogin = async () => {
        try {
            const respostaUsuario = await fetch(`https://suapuno.app:8080/api/usuarios/${localStorage.getItem('id_user')}/`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${token}`
                }
            });

            if (!respostaUsuario.ok) {
                throw new Error('Falha ao acessar a API');
            }

            const dadosUsuario = await respostaUsuario.json();

            const periodos_letivos = await fetch(`https://suapuno.app:8080/api/periodos-letivos/`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${token}`
                }
            });

            if (!periodos_letivos.ok) {
                throw new Error('Falha ao acessar a API');
            }

            const periodos = await periodos_letivos.json();
            setListaAnosSemestre(periodos);
            setAnoSemestre(periodos[periodos.length - 1])

            const respostaRendimento = await fetch('https://suapuno.app:8080/api/alunos/coeficiente-rendimento/', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${token}`
                }
            });

            if (!respostaRendimento.ok) {
                throw new Error('Falha ao acessar a API');
            }

            const dadosRendimento = await respostaRendimento.json();
            setRendimento(dadosRendimento);

            const respostaDiarios = await fetch(`https://suapuno.app:8080/api/diarios/`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${token}`
                }
            });

            if (!respostaDiarios.ok) {
                throw new Error('Falha ao acessar a API');
            }

            const dadosDiarios = await respostaDiarios.json();  
            await setDadosDiarios(dadosDiarios);

            const ids = dadosDiarios.map(item => ({ ano_semestre: item.ano_semestre, id: item.id_diario }));

            try {
                const resultados = await Promise.all(ids.map(id =>
                    fetch(`https://suapuno.app:8080/api/diarios/${id.id}/alunos/${dadosUsuario.aluno.id_aluno}/desempenho/`, {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': `Token ${token}`
                        }
                    }).then(res => res.json())
                ));

                const novaMediaDesempenho = resultados.map((dados, index) => ({
                    id: ids[index],
                    etapas: dados.etapas,
                    media: dados.media_parcial,
                    mediaFinal: dados.mediaFinal,
                    aulas_futuras: dados.aulas_futuras,
                    aulas_pendentes: dados.aulas_pendentes,
                    presencas: dados.presencas,
                    faltas: dados.faltas,
                    mnemonico: dadosDiarios[index].mnemonico_disc
                }));

                setMediaDesempenho(novaMediaDesempenho);
                
            } catch (erro) {
                console.error('Erro ao obter desempenho:', erro);
            }

            setCarregando(false);
        } catch (erro) {
            console.error(erro);
        }
    };
     
    useEffect(() => {
        const fetchData = async () => {
            try {
                const agendaAluno = await fetch(`https://suapuno.app:8080/api/alunos/agenda/${anoSemestre.id_ano_semestre}/`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Token ${token}`
                    }
                });

                if (!agendaAluno.ok) {
                    throw new Error('Falha ao acessar a API');
                }

                const dadosAgenda = await agendaAluno.json();
                setAgenda(dadosAgenda);

            } catch (erro) {
                console.error(erro);
            }
        };

        fetchData();
    }, [anoSemestre, token]);

    const dataBar = {
        labels: ['Meu coeficiente', 'Coeficiente da turma', 'Melhor da turma'],
        datasets: [{
            barThickness: 23,
            data: [rendimento.meu_coeficiente || 0, rendimento.coeficiente_turma || 0, rendimento.melhor_turma || 0],
            backgroundColor: ['rgb(19,141,18)', 'rgb(44,220,42)', 'rgb(218,218,218)'],
        }]
    };

    const optionsBar = {
        indexAxis: 'y',
        scales: {
            x: {
                beginAtZero: true,
                max: 10,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                displayColors: false,
            }
        },
        categoryPercentage: 0.8,
    };

    const createGradient = (ctx, chartArea) => {
        const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        gradient.addColorStop(0, 'rgba(232,47,50,0.8)');
        gradient.addColorStop(0.4, 'rgba(216,198,43,0.8)');
        gradient.addColorStop(1, 'rgba(44,220,42,0.8)');
        return gradient;
    };

    const dataLine = {
        labels: ['', ...mediaDesempenho.filter(item => item.id.ano_semestre === anoSemestre.ano_semestre).map(id => id.mnemonico), ''],
        datasets: [{
            data: [0, ...mediaDesempenho.filter(item => item.id.ano_semestre === anoSemestre.ano_semestre).map(id => id.media || 0), 0],
            borderColor: 'rgb(218,218,218)',
            pointRadius: 0,
            backgroundColor: (context) => {
                const { ctx, chartArea } = context.chart;
                if (!chartArea) {
                    return null;
                }
                return createGradient(ctx, chartArea);
            },
            fill: true,
            offset: 5,
        }]
    };

    const optionsLine = {
        scales: {
            x: {
                beginAtZero: true,
            },
            y: {
                beginAtZero: true,
                max: 10,
            },
        },

        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                displayColors: false,
            }
        },
    };

    const formatarData = (data) => {
        let diasDaSemana = ['Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado', 'Domingo'];
        const dataObj = new Date(data);
        let diaDaSemana = dataObj.getDay({ timeZone: 'UTC' });
        const dataAtual = dataObj.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
        return `${diasDaSemana[diaDaSemana]} ${dataAtual}`;
    };

    const getPeriod = (turno) => {
        if (turno === 'matutino') return 'manha';
        if (turno === 'vespertino') return 'tarde';
        if (turno === 'noturno') return 'noite';
        return '';
    };

    const dadosCalendario = dadosDiarios.reduce((acc, curr) => {
        curr.horarios.forEach(horario => {
            const day = horario.dia_semana.toLowerCase();
            const periodo = getPeriod(curr.turno); // Faz conversão dos periodos
            if (!acc[day]) acc[day] = [];
            acc[day].push({
                id_diario: curr.id_diario,
                disciplina: curr.disciplina,
                professor: curr.professor,
                hora_inicio: horario.hora_inicio,
                hora_fim: horario.hora_fim,
                local: horario.local,
                ano_semestre: horario.ano_semestre,
                periodo: periodo
            });
        });
        return acc;
    }, {});

    const diasSemana = ["segunda", "terça", "quarta", "quinta", "sexta"];

    const CelulaCalendario = ({ disciplina, professor, onClick }) => (
        <div className="mt-1">
            <div className="border-[1px] border-gray-500 p-2 rounded-[10px] max-w-[70px] min-w-[70px]" onClick={onClick}>
                <h1 className="text-[17px] overflow-hidden line-clamp-1 -mr-1">{disciplina}</h1>
                <div className="flex text-[12px]">
                    <h1 className="line-clamp-1 max-w-7 mr-2">{professor}</h1>
                    <PiPerson size={20}/>
                </div>
            </div>
        </div>
    );

    const Modal = ({ data, onClose }) => {
        const resultadosFiltrados = mediaDesempenho
            .filter(item => item.id.id === celulaSelecionada.id_diario)
            .map(item => ({
                aulas_futuras: item.aulas_futuras,
                aulas_pendentes: item.aulas_pendentes
            }));
         
        const [diarioEquivalente] = dadosDiarios.filter(item => item.id_diario === data.id_diario);


            console.log(data)
            console.log(diarioEquivalente)
    
        const total = resultadosFiltrados.length > 0 
            ? resultadosFiltrados.map(result => result.aulas_futuras + result.aulas_pendentes).join(' + ')
            : '0'; // valor padrão caso não haja resultados
        
        return (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center" onClick={onClose}>
                <div className="bg-white p-5 w-80 rounded-lg" onClick={e => e.stopPropagation()}>
                    <div className="bg-white p-4 border-2 rounded-lg border-gray-400">
                        <h1 className="text-lg font-semibold">{data.disciplina}</h1>
                        <div className="flex justify-between">
                            <p className="text-sm text-gray-500 italic">{data.professor || '-'}</p>
                            <PiPerson />
                        </div>
                        <div className="flex mt-1 -mb-1">
                            <div style={{ width: `calc(${mediaDesempenho.filter(item => item.id.id === celulaSelecionada.id_diario).map(item => item.faltas)} / ${total} * 100%)` }} className="h-1 bg-red-500">{data.presencas}</div>
                            <div style={{ width: `calc(${mediaDesempenho.filter(item => item.id.id === celulaSelecionada.id_diario).map(item => item.presencas)} / ${total} * 100%)` }} className="h-1 bg-green-500">{data.presencas}</div>
                            <div style={{ width: `calc(${total} / ${total} * 100%)` }} className="h-1 bg-gray-500">{data.presencas}</div>
                        </div>
                        <div className="flex items-center gap-1 text-sm mt-2">
                            <div className="h-[5px] w-[5px] bg-black rounded-full"></div>
                            <h1 className="flex">
                                <p className="font-semibold">Horário: </p>
                                {data.hora_inicio || '-'} - {data.hora_fim || '-'}
                            </h1>
                        </div>
                        <div className="flex items-center gap-1 text-sm">
                            <div className="h-[5px] w-[5px] bg-black rounded-full"></div>
                            <h1 className="flex">
                                <p className="font-semibold">Localização: </p>
                                {data.local || '-'}
                            </h1>
                        </div>
                        <div className="flex items-center gap-1 text-sm">
                            <div className="h-[5px] w-[5px] bg-black rounded-full"></div>
                            <h1 className="flex">
                                <p className="font-semibold">Período: </p>
                                {data.periodo ? (data.periodo.charAt(0).toUpperCase() + data.periodo.slice(1)) : '-'}
                            </h1>
                        </div>
                        {data.hora_inicio && (<button onClick={() => selecionarDiario(diarioEquivalente)} className="mt-4 bg-green-700 text-white px-4 py-2 w-full rounded-md">
                            Acessar disciplina
                        </button>)}
                    </div>
                </div>
            </div>
        );
    };
      
    const openModal = (data) => {
        setCelulaSelecionada(data);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setCelulaSelecionada(null);
    };

    const ElementoCalendario = ({ calendariododiario }) => (
        <div className="flex flex-col">
            {calendariododiario.map((schedule, index) => (
                <div key={index} className="flex justify-center font-semibold">
                    <CelulaCalendario disciplina={schedule.disciplina} professor={schedule.professor} onClick={() => openModal(schedule)} />
                </div>
            ))}
        </div>
    );

    const secaoCalendario = (periodo, dadosCalendario) => {
        const calendarioDoDiario = diasSemana.reduce((acc, day) => {
            acc[day] = (dadosCalendario[day] || []).filter(item => item.ano_semestre === anoSemestre.ano_semestre).filter(schedule => schedule.periodo === periodo);

            return acc;
        }, {});

        const temCalendario = Object.values(calendarioDoDiario).some(calendariododiario => calendariododiario.length > 0);

        if (!temCalendario) {
            return null;
        }

        diasSemana.forEach(day => {
            if (calendarioDoDiario[day].length === 0) {
                calendarioDoDiario[day] = [{ disciplina: '---', professor: '---' }, { disciplina: '---', professor: '---' }];
            } else if (calendarioDoDiario[day].length === 1) {
                calendarioDoDiario[day].push({ disciplina: '---', professor: '---' });
            }
        });

        return (
            <div>
                <div className="relative flex w-full justify-center text-sm mt-1 text-gray-400 border-[1px] border-b-gray-400">{periodo.charAt(0).toUpperCase() + periodo.slice(1)}</div>
                <div className="mt-2 -ml-2 flex gap-1">
                    {diasSemana.map((day, index) => (
                        <div key={index} className="day-column">
                            <ElementoCalendario calendariododiario={calendarioDoDiario[day]} />
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const mediaFiltrada = mediaDesempenho
        .filter(item => agenda.some(agenda => agenda.id_diario === item.id.id))
        .map(item => item.media);

        let corClasse;
        let textoExibido;

        if (mediaFiltrada < 5) {
        corClasse = 'text-red-600';
        textoExibido = 'Insatisfatório';
        } else if (mediaFiltrada >= 5 && mediaFiltrada < 8) {
        corClasse = 'text-yellow-600';
        textoExibido = 'Regular';
        } else {
        corClasse = 'text-green-600';
        textoExibido = 'Excelente';
    }

    const selecionarDiario = (dadosDiarios) =>{
        setModalOpen(false);

        const dadosFiltrados = mediaDesempenho.filter(item => item.id.id === dadosDiarios.id_diario);
        const dadosFinais = [dadosDiarios, ...dadosFiltrados];
        
        setDisciplinaSelecionada(dadosFinais)
    }

    let graficoRosquinha
    let configRosquinha
    if(disciplinaSelecionada){
        graficoRosquinha = {
            labels: ['Faltas', 'Pendentes', 'Presencas'],
            datasets: [
                {
                    data: [disciplinaSelecionada[1].faltas || 0, disciplinaSelecionada[1].aulas_pendentes || 0, disciplinaSelecionada[1].presencas || 0],
                    backgroundColor: ['rgb(232,47,50)', 'rgb(198,198,198)', 'rgb(44,220,42)', ]
                }
            ]
        };
      
        configRosquinha = {
            responsive: true,
            cutout: '70%',
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    displayColors: false,
                }
            }
        };
    }

    return (
        <div className="flex flex-col justify-center items-center">
            {!disciplinaSelecionada ? 
            (
                <div className="top-0 left-0 w-full"><MenuTopo /></div>
            ):(
                <div className="top-0 left-0 w-full" onClick={() => setDisciplinaSelecionada(null)}>
                    <div className="w-full top-0 left-0 bg-white h-[48px] flex items-center">
                        <div className="flex justify-between w-full h-full items-center">
                            <button className="ml-2">
                                <PiArrowLeft size={30}/>
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {!disciplinaSelecionada && (
                <div className="flex flex-col justify-center items-center w-[100%]">
                    <div className="w-[93%] mt-3">
                        <Card
                            icone={<PiGraduationCap size={23} />}
                            titulo={dadosDiarios[0]?.curso || <Skeleton width={100} />}
                            blockMovimento={false}
                            conteudo={
                                carregando ? (
                                    <Skeleton count={5} />
                                ) : (
                                    <div style={{direction: 'ltr'}} className="flex flex-col">
                                        <h1>Seus Diários</h1>
                                        <div style={{direction: 'rtl'}} className="flex fex-row overflow-auto gap-3">
                                            {listaAnosSemestre.slice().reverse().map((item, index) => (
                                                <div className="flex justify-end" key={index}>
                                                    <div className="flex flex-col">
                                                        <button className={`${item.ano_semestre === anoSemestre.ano_semestre ? 'bg-[#138d12] p-3 text-white rounded-xl font-semibold' : 'p-3'}`} key={index} onClick={() => setAnoSemestre(listaAnosSemestre.slice().reverse()[index])}>
                                                            {item.ano_semestre}
                                                        </button>
                                                        <div className={`${item.ano_semestre === anoSemestre.ano_semestre ? 'bg-[#138d12] p-[3px] mt-1 rounded-xl' : ''}`}></div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )
                            }
                        />
                        <Card
                            icone={<PiChartLineUp size={23} />}
                            titulo={'Coeficiente de Rendimento'}
                            blockMovimento={false}
                            conteudo={
                                carregando ? (
                                    <Skeleton height={150} />
                                ) : (
                                    <div className="flex">
                                        <div className="w-full">
                                            <Bar data={dataBar} options={optionsBar} />
                                        </div>
                                    </div>
                                )
                            }
                        />
                        <Card
                            icone={<PiChartLine size={23} />}
                            titulo={'Desempenho'}
                            blockMovimento={false}
                            conteudo={
                                carregando ? (
                                    <Skeleton height={150} />
                                ) : (
                                    <div className="flex">
                                        <div className="w-full">
                                            <Line data={dataLine} options={optionsLine} />
                                        </div>
                                    </div>
                                )
                            }
                        />
                        <Card
                            icone={<PiCalendar size={23} />}
                            titulo={'Agenda'}
                            blockMovimento={false}
                            conteudo={
                                carregando ? (
                                    <Skeleton height={150} />
                                ) : (
                                    <div className="flex flex-row overflow-x-auto">
                                        {agenda.map((item, index) => (
                                            <div key={index} className="flex-shrink-0 mx-2">
                                                <div className={`flex flex-col h-42 w-52 rounded-xl ${mediaDesempenho.filter(item => agenda.some(agenda => agenda.id_diario === item.id.id)).map(item => item.media) < 5 ? 'bg-[#f19496]' : mediaDesempenho.filter(item => agenda.some(agenda => agenda.id_diario === item.id.id)).map(item => item.media) > 5 && mediaDesempenho.filter(item => agenda.some(agenda => agenda.id_diario === item.id.id)).map(item => item.media) < 8 ? 'bg-[#e9e093]' : 'bg-[#7ee77d]'}`}>
                                                    <div className="mx-5 my-3">
                                                        <h1 className="text-sm">{formatarData(item.data_avaliacao)}</h1>
                                                        <div className="flex flex-row mt-1">
                                                            <div className={`flex h-[6px] w-[6px] rounded-full mt-[6px] mr-1 ${mediaDesempenho.filter(item => agenda.some(agenda => agenda.id_diario === item.id.id)).map(item => item.media) < 5 ? 'bg-red-600' : mediaDesempenho.filter(item => agenda.some(agenda => agenda.id_diario === item.id.id)).map(item => item.media) > 5 && mediaDesempenho.filter(item => agenda.some(agenda => agenda.id_diario === item.id.id)).map(item => item.media) < 8 ? 'bg-yellow-600' : 'bg-green-600'}`}></div>
                                                            <h1 className={`text-xs font-semibold ${corClasse}`}>Situação: {textoExibido}</h1>
                                                        </div>
                                                        <h1 className="mt-2 font-semibold text-2xl">{item.descricao_avaliacao}</h1>
                                                        <h1 className="mt-2 font-semibold">{dadosDiarios.filter(item => agenda.some(agenda => agenda.id_diario === item.id_diario)).map(item => item.disciplina)}</h1>
                                                        <div className="flex flex-row mt-2">
                                                            <h1 className="text-sm text-gray-500 italic mr-2 line-clamp-1">{dadosDiarios.filter(item => agenda.some(agenda => agenda.id_diario === item.id_diario)).map(item => item.professor)}</h1>
                                                            <PiStudent size={20} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )
                            }
                        />
                        <Card
                            icone={<PiAlarm size={23} />}
                            titulo={'Horários'}
                            conteudo={
                                carregando ? (
                                    <Skeleton height={150} />
                                ) : (
                                    <div className="flex flex-col">
                                        <div className="flex ml-1 text-center gap-6 font-semibold -mb-1">
                                            {diasSemana.map(day => (
                                                <h1 key={day}>{day.charAt(0).toUpperCase() + day.slice(1)}</h1>
                                            ))}
                                        </div>
                                        {['manha', 'tarde', 'noite'].map(periodo => secaoCalendario(periodo, dadosCalendario))}
                                    </div>
                                )
                            }
                        />
                        <Card
                            icone={<PiNotePencil size={23} />}
                            titulo={'Minhas disciplinas'}
                            conteudo={
                                carregando ? (
                                    <Skeleton height={150} />
                                ) : (
                                    <div>
                                        {dadosDiarios.filter(item => item.ano_semestre === anoSemestre.ano_semestre).map((dados, index) => {
                                            // Encontra o desempenho correspondente para o dado atual
                                            const desempenho = mediaDesempenho.filter(item => item.id.id === dados.id_diario);
                                    
                                            // Extrai as aulas futuras e pendentes, garantindo que são números
                                            const aulasFuturas = desempenho.map(item => item.aulas_futuras).reduce((a, b) => a + b, 0);
                                            const aulasPendentes = desempenho.map(item => item.aulas_pendentes).reduce((a, b) => a + b, 0);
                                            const totalAulas = aulasFuturas + aulasPendentes;
                                    
                                            return (
                                                <div key={index} onClick={() => selecionarDiario(dados)} className="border-gray-500 border-2 rounded-lg py-2 px-3 mb-3">
                                                    <h1>{dados.disciplina}</h1>
                                                    <div className="flex items-center justify-between">
                                                        <h1 className="text-gray-500 italic">{dados.professor}</h1>
                                                        <PiPerson className="mr-2"/>
                                                    </div>
                                                    <div className="flex">
                                                        <div style={{ width: `calc(${desempenho.map(item => item.faltas).reduce((a, b) => a + b, 0)} / ${totalAulas} * 100%)` }} className="h-1 bg-red-500"></div>
                                                        <div style={{ width: `calc(${desempenho.map(item => item.presencas).reduce((a, b) => a + b, 0)} / ${totalAulas} * 100%)` }} className="h-1 bg-green-500"></div>
                                                        <div style={{ width: `calc(${totalAulas} / ${totalAulas} * 100%)` }} className="h-1 bg-gray-500"></div>
                                                    </div>
                                                    {desempenho[0].media < 5 && (
                                                        <h1 className="text-sm mt-1 text-red-500">Situação: Insatisfatório</h1>
                                                    )}
                                                    {(desempenho[0].media > 5 && desempenho[0].media < 7) && (
                                                        <h1 className="text-sm mt-1 text-yellow-500">Situação: Regular</h1>
                                                    )}
                                                    {desempenho[0].media > 7 && (
                                                        <h1 className="text-sm mt-1 text-green-500">Situação: Excelente</h1>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                )
                            }
                        />
                    </div>
                </div>
            )}
            {disciplinaSelecionada && (
                <div className="flex flex-col justify-center items-center w-[100%]">
                    <div className="w-[93%] mt-3">
                        <Card
                            icone={<PiGraduationCap size={23}/>}
                            titulo={disciplinaSelecionada[0].disciplina}
                            conteudo={
                                <div>
                                    <div className="flex items-center justify-between">
                                        <h1>{disciplinaSelecionada[0].professor}</h1>
                                        <PiPerson className="mr-5"/>
                                    </div>
                                    <div className="flex items-center gap-2 mt-2">
                                        <div className="h-1 w-1 bg-black rounded-full"></div>
                                        <h1 className="flex text-start font-semibold gap-1">Semestre: <p className="font-normal">{disciplinaSelecionada[0].ano_semestre}</p></h1>
                                    </div>
                                    <div className="flex gap-2">
                                        <div className="flex justify-center">
                                            <div className="h-1 w-1 mt-[10px] bg-black rounded-full"/>
                                            <h1 className="font-semibold ml-2">
                                                Horários:
                                            </h1>
                                        </div>
                                        <h1 className="flex items-center text-start font-bold gap-1">
                                            <div className="font-normal">
                                                {disciplinaSelecionada[0].horarios.map((horario, index) => (
                                                    <h1 className="text-sm" key={index}>
                                                        {horario.local} -  {horario.hora_inicio} - {horario.hora_fim} ({horario.dia_semana}) <br/>
                                                    </h1>
                                                ))}
                                            </div>
                                        </h1>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <div className="h-1 w-1 bg-black rounded-full"></div>
                                        <h1 className="flex text-start font-semibold gap-1">Turno: <p className="font-normal">{disciplinaSelecionada[0].turno}</p></h1>
                                    </div>
                                    <div>
                                        {disciplinaSelecionada[1].media < 5 && (
                                            <div className="flex flex-col justify-center text-center items-center mt-2 text-sm">
                                                <PiSmileySad color="red" size={20}/>
                                                <h1 className="text-red-500">Seu desempenho nessa matéria está insatisfatório.</h1>
                                            </div>
                                        )}
                                        {(disciplinaSelecionada[1].media > 5 && disciplinaSelecionada[1].media < 7) && (
                                            <div className="flex flex-col justify-center text-center items-center mt-2 text-sm">
                                                <PiSmileyMeh color="yellow" size={20}/>
                                                <h1 className="text-yellow-400">Seu desempenho nessa matéria está regular.</h1>
                                            </div>
                                        )}
                                        {disciplinaSelecionada[1].media > 7 && (
                                            <div className="flex flex-col justify-center text-center items-center mt-2 text-sm">
                                                <PiSmiley color="green" size={20}/>
                                                <h1 className="text-green-500">Parabéns! Seu desempenho nessa matéria está excelente. Continue assim!</h1>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            }
                        />
                        <Card
                            icone={<PiChartDonut size={23}/>}
                            titulo={'Resumo das Aulas'}
                            conteudo={
                                <div className="flex">  
                                    <div className="h-[40%] w-[40%] relative">
                                        <Doughnut data={graficoRosquinha} options={configRosquinha} />
                                        <div className="absolute inset-0 flex flex-col justify-center items-center text-center text-[10px]">
                                                <p className="text-2xl font-bold ">{disciplinaSelecionada[1].aulas_futuras}</p>
                                                aulas até a conclusão<br/> da matéria
                                        </div>
                                    </div>
                                    <div className="justify-center items-center font-bold text-[13px] mt-7 ml-3">
                                        <div className="flex items-center text center w-full mb-2">
                                            <div className="h-[15px] w-[15px] rounded bg-[#e82f32]"></div>
                                            <h1 className="ml-1">{disciplinaSelecionada[1].faltas} Faltas</h1>
                                        </div>
                                        <div className="flex items-center text center w-full  mb-2">
                                            <div className="h-[15px] w-[15px] rounded bg-[#c6c6c6]"></div>
                                            <h1 className="ml-1">{disciplinaSelecionada[1].aulas_pendentes} Pendências de lançamento</h1>
                                        </div>
                                        <div className="flex items-center text center w-full">
                                            <div className="h-[15px] w-[15px] rounded bg-[#2cdc2a]"></div>
                                            <h1 className="ml-1">{disciplinaSelecionada[1].presencas} Presenças</h1>
                                        </div>
                                    </div>
                                </div>
                            }
                        />
                        <Card
                            icone={<PiScroll size={23}/>}
                            titulo={'Avaliações'}
                            conteudo={
                                <div className="flex flex-col">  
                                {Array.isArray(disciplinaSelecionada) && disciplinaSelecionada[1]?.etapas?.length > 0 && (
                                    disciplinaSelecionada[1].etapas
                                        .filter(etapa => etapa.avaliacoes.length > 0)
                                        .sort((a, b) => (a.etapa === "Final" ? 1 : b.etapa === "Final" ? -1 : 0))
                                        .map((etapa, index) => (
                                            <div key={index}>
                                                <div className="flex flex-col">
                                                    <h1 className="font-bold">{etapa.etapa}</h1>
                                                </div>
                                                <div className="flex justify-around text-[15px] font-semibold">
                                                    <h1>Avaliação</h1>
                                                    <h1>Nota máx</h1>
                                                    <h1>Peso</h1>
                                                    <h1>Nota</h1>
                                                </div>
                                                {etapa.avaliacoes.map((avaliacao, index) => {
                                                    const date = new Date(avaliacao.data_avaliacao);
                                                    const dataProva = date.toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo' });
                            
                                                    const notaFormatada = avaliacao.nota;
                                                    const notaBgCor = avaliacao.nota < 6 ? 'bg-red-500' : avaliacao.nota < 8 ? 'bg-yellow-500' : 'bg-green-500';
                            
                                                    return (
                                                        <div key={index} className="flex text-sm items-center mt-2 mx-2 justify-around pb-1 border-b border-gray-400">
                                                            <div className="flex flex-col -ml-1">
                                                                <h1 className="font-semibold text-center">{avaliacao.descricao_avaliacao}</h1>
                                                                <h1 className="text-[12px] font-semibold -mt-1">{dataProva}</h1>
                                                            </div>
                                                            <h1 className="ml-4">{avaliacao.nota_max_avaliacao.toFixed(1).replace('.', ',')}</h1>
                                                            <h1 className="ml-5">{avaliacao.peso_avaliacao.toFixed(1).replace('.', ',')}</h1>
                                                            <h1 className={`px-3 py-1 rounded-xl ${notaBgCor} text-white -mr-2`}>{notaFormatada  || "--"}</h1>
                                                        </div>
                                                    );
                                                })}
                                                <div className="flex justify-around items-center bg-[rgb(198,198,198)] my-6 rounded-lg p-4">
                                                    <h1 className="text-[13px] font-semibold text-gray-800">MÉDIA DA {etapa.etapa}</h1>
                                                    <PiArrowRight size={25} />
                                                    {etapa.media_etapa ?
                                                        (<h1 className={`${etapa.media_etapa < 6 ? 'bg-red-500' : etapa.media_etapa < 8 ? 'bg-yellow-500' : 'bg-green-500'} px-3 py-1 text-sm text-white rounded-lg`}>{etapa.media_etapa.toFixed(1).replace('.', ',')}</h1>):
                                                        (<h1 className={`bg-gray-700 px-3 py-1 text-sm text-white rounded-lg`}>Não definido</h1>)
                                                    }
                                                </div>
                                            </div>
                                        ))
                                )}
                                <h1 className="font-bold mb-7">Resumo das Notas</h1>
                                <div className="flex gap-5">
                                    {Array.isArray(disciplinaSelecionada) && disciplinaSelecionada[1]?.etapas?.length > 0 && (
                                        disciplinaSelecionada[1].etapas.filter(etapa => etapa.etapa !== 'Final').map((etapa, index) => (
                                            <div key={index + 1} className="w-full">
                                                <div className="flex justify-around items-center bg-[rgb(198,198,198)] rounded-lg p-4 border-black border-2">
                                                    <h1 className="text-[13px] font-semibold text-gray-800">{etapa.etapa}</h1>
                                                    <PiArrowRight size={25} />
                                                    {etapa.media_etapa ?
                                                        (<h1 className={`${etapa.media_etapa < 6 ? 'bg-red-500' : etapa.media_etapa < 8 ? 'bg-yellow-500' : 'bg-green-500'} px-3 py-1 text-sm text-white rounded-lg`}>{etapa.media_etapa.toFixed(1).replace('.',',')}</h1>):
                                                        (<h1 className={`bg-gray-700 px-3 py-1 text-sm text-white rounded-lg`}>--</h1>)
                                                        }
                                                </div>
                                            </div>
                                        ))
                                    )}
                                </div>
                                {disciplinaSelecionada[1].media && <div className="flex justify-around items-center bg-[rgb(198,198,198)] rounded-lg p-4 border-black border-2 mt-5">
                                    <h1 className="text-[13px] font-semibold text-gray-800">MÉDIA PARCIAL</h1>
                                    <PiArrowRight size={25} />
                                    <h1 className={`${disciplinaSelecionada[1].media < 6 ? 'bg-red-500' : disciplinaSelecionada[1].media < 8 ? 'bg-yellow-500' : 'bg-green-500'} px-3 py-1 text-sm text-white rounded-lg`}>{disciplinaSelecionada[1].media.toFixed(1).replace('.',',') || 0}</h1>
                                </div>}
                                <div className="flex justify-around items-center bg-[rgb(198,198,198)] rounded-lg p-4 border-black border-2 mt-5">
                                    <h1 className="text-[13px] font-semibold text-gray-800">PROVA FINAL</h1>
                                    <PiArrowRight size={25} />
                                    <h1 className={`${disciplinaSelecionada[1].etapas[0].media_etapa !== null && disciplinaSelecionada[1].etapas[0].media_etapa !== undefined ? (disciplinaSelecionada[1].etapas[0].media_etapa < 6 ? 'bg-red-500' : disciplinaSelecionada[1].etapas[0].media_etapa < 8 ? 'bg-yellow-500' : 'bg-green-500') : 'bg-gray-700'} px-3 py-1 text-sm text-white rounded-lg`}>
                                        {disciplinaSelecionada[1].etapas[0].media_etapa !== null && disciplinaSelecionada[1].etapas[0].media_etapa !== undefined ? disciplinaSelecionada[1].etapas[0].media_etapa.toFixed(1).replace('.', ',') : 'NÃO REALIZADO'}
                                    </h1>
                                </div>
                                <div className="flex justify-around items-center bg-[rgb(198,198,198)] rounded-lg p-4 border-black border-2 mt-5">
                                    <h1 className="text-[13px] font-semibold text-gray-800">MÉDIA FINAL</h1>
                                    <PiArrowRight size={25} />
                                    <h1 className={`${disciplinaSelecionada[1].mediaFinal !== null && disciplinaSelecionada[1].mediaFinal !== undefined ? (disciplinaSelecionada[1].mediaFinal < 6 ? 'bg-red-500' : disciplinaSelecionada[1].mediaFinal < 8 ? 'bg-yellow-500' : 'bg-green-500') : 'bg-gray-700'} px-3 py-1 text-sm text-white rounded-lg`}>
                                        {disciplinaSelecionada[1].mediaFinal !== null && disciplinaSelecionada[1].mediaFinal !== undefined ? disciplinaSelecionada[1].mediaFinal.toFixed(1).replace('.', ',') : 'NÃO DEFINIDA'}
                                    </h1>
                                </div>
                            </div>
                            }
                        />
                    </div>
                </div>
            )}
            {modalOpen && <Modal data={celulaSelecionada} onClose={closeModal} />}
        </div>
    );
}

export default Disciplina;