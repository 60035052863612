import { useState } from 'react';
import Menu from '../components/ui/menu';
import MenuTopo from '../components/ui/menuTopo';
import DisciplinaProfessor from './professor/suaPresenca/Disciplina_Professor';


const Presenca = () => {
  const [menuVisivel, setMenuVisivel] = useState(true);

  return (    
    <div className="flex flex-col min-h-screen">
      <div className="top-0 left-0 w-full"><MenuTopo /></div>
      <main className="flex-grow container mx-auto py-8"> 
        <DisciplinaProfessor setMenu={setMenuVisivel}/> 
      </main>
      {menuVisivel && <div className='bg-black'> <Menu /> </div>}
    </div>
  );
};

export default Presenca;