import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import LoginMobile from "../../components/loginMobile";
import LoginPc from "../../components/loginPc";
import ChatModal from "../../components/chat";

const LoginForm = () => {
  const navigate = useNavigate();
  const [login, setLogin] = useState("");
  const [senha, setSenha] = useState("");
  const [mostrarSenha, setMostrarSenha] = useState(false);
  const [lembrar, setLembrar] = useState(false);
  const [erro, setErro] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const perfisString = localStorage.getItem('perfis');
      if (perfisString) {
        const perfis = JSON.parse(perfisString);
        if (Object.keys(perfis).length > 1) {
          navigate('/menu');
        } else if (perfis.includes('Professor')) {
          navigate('/sua-progressao');
        } else if (perfis.includes('Aluno')) {
          navigate('/sua-progressao');
        } else {
          navigate('/sua-peticao/painel');
        }
      }
    }
  }, [navigate]);

  async function onSubmit(event) {
    event.preventDefault();
    try {
      const resposta = await fetch('https://suapuno.app:8080/api-token-auth/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: login,
          password: senha
        })
      });
      if (resposta.ok) {
        const api = await resposta.json();

        localStorage.setItem('token', api.token);
        localStorage.setItem('id_user', api.id_user);
        localStorage.setItem('perfis', JSON.stringify(api.perfis));

        const perfis = JSON.parse(localStorage.getItem('perfis'));
        if (Object.keys(perfis).length > 1) {
          navigate('/menu');
        } else if (perfis.includes('Professor')) {
          navigate('/sua-progressao');
        } else if (perfis.includes('Aluno')) {
          navigate('/sua-progressao');
        } else if (perfis.includes('Assistente estudantil')){
          navigate('/sua-peticao/painel');
        } else if (perfis.includes('Assistente acadêmico')) {
          navigate('/sua-progressao/painel');
        }
      } else {
        setErro(true);
      }
    } catch (error) {
      console.error('Não foi possível obter dados de login - ', error);
    }
  }

  return (
    <div className={isMobile ? 'h-screen w-screen flex justify-center bg-gradient-to-t from-[#5d9b5de3]  via-[#4e924e] to-[#348133]' : 'bg-white'}>
      {isMobile ? (
        <LoginMobile
          login={login}
          setLogin={setLogin}
          senha={senha}
          setSenha={setSenha}
          mostrarSenha={mostrarSenha}
          setMostrarSenha={setMostrarSenha}
          lembrar={lembrar}
          setLembrar={setLembrar}
          erro={erro}
          onSubmit={onSubmit}
        />
      ) : (
        <LoginPc
          login={login}
          setLogin={setLogin}
          senha={senha}
          setSenha={setSenha}
          mostrarSenha={mostrarSenha}
          setMostrarSenha={setMostrarSenha}
          lembrar={lembrar}
          setLembrar={setLembrar}
          erro={erro}
          onSubmit={onSubmit}
        />
      )}

      <div className="bg-white rounded-full h-14 w-14 flex items-center justify-center mr-1 fixed bottom-4 right-2" onClick={() => setIsChatOpen(true)}>
          <img src='/imagens/SuaPinho.png' alt="Suapinho" style={{ width: '40px', height: '40px' }} />
      </div>

      <ChatModal isOpen={isChatOpen} onClose={() => setIsChatOpen(false)} />
    </div>
  );
};

export default LoginForm;