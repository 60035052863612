import React, { useEffect, useState } from 'react';
import Card from "../../../components/ui/cardGenerico";
import { PiChartDonut, PiWarningCircle, PiGraduationCap, PiStudent, PiClipboardText, PiScroll } from "react-icons/pi";
import { Doughnut } from 'react-chartjs-2';

const Desempenho = (etapas) => {
    const token = localStorage.getItem('token');
    const [ativo, setAtivo] = useState(0); // 0 = avaliações, 1 = alunos
    const [etapaSelecionada, setEtapaSelecionada] = useState(1); // 1 = 1º etapa, 2 = 2º etapa, 3 = 3º etapa, 4 = 4º etapa, 5 = PF, 6 = MF
    const [dadosProcessados, setDadosProcessados] = useState({});
    const [alunosAprovados, setAlunosAprovados] = useState(0);
    const [totalAlunos, setTotalAlunos] = useState(0);
    const [dadosAlunos, setDadosAlunos] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [alunoSelecionado, setAlunoSelecionado] = useState(null); 
    const [todosOsDados, setTodosOsDados] = useState([])
    const [dadosEspecificosAluno, setDadosEspecificosAluno] = useState([])

    const processarDados = (dados) => {
        const estatisticasPorAvaliacao = {};
        let totalAlunosAprovados = 0;
        let totalDeAlunos = dados.length;

        dados.forEach(aluno => {
            let somaNotasAluno = 0;
            let totalAvaliacoesAluno = 0;

            aluno.avaliacoes.forEach(avaliacao => {
                const descricao = avaliacao.descricao_avaliacao;
                somaNotasAluno += avaliacao.nota;
                totalAvaliacoesAluno += 1;

                if (!estatisticasPorAvaliacao[descricao]) {
                    estatisticasPorAvaliacao[descricao] = {
                        insatisfatorio: 0,
                        regular: 0,
                        excelente: 0,
                        porcentagemMaior8: 0,
                        maiorNota: 0,
                        nomeMaiorNota: [],
                        totalNotas: 0,
                        somaNotas: 0,
                        mediaNota: 0,
                        nota_maxima: avaliacao.nota_maxima
                    };
                }

                estatisticasPorAvaliacao[descricao].totalNotas += 1;
                estatisticasPorAvaliacao[descricao].somaNotas += avaliacao.nota;

                if (avaliacao.nota < (avaliacao.nota_maxima / 2)) {
                    estatisticasPorAvaliacao[descricao].insatisfatorio += 1;
                } else if (avaliacao.nota >= (avaliacao.nota_maxima / 2) && avaliacao.nota < (avaliacao.nota_maxima * 0.7)) {
                    estatisticasPorAvaliacao[descricao].regular += 1;
                } else if (avaliacao.nota >= (avaliacao.nota_maxima * 0.7)) {
                    estatisticasPorAvaliacao[descricao].excelente += 1;
                }                

                if (avaliacao.nota > estatisticasPorAvaliacao[descricao].maiorNota) {
                    estatisticasPorAvaliacao[descricao].maiorNota = avaliacao.nota;
                    estatisticasPorAvaliacao[descricao].nomeMaiorNota = [aluno.nome_aluno];
                } else if (avaliacao.nota === estatisticasPorAvaliacao[descricao].maiorNota) {
                    estatisticasPorAvaliacao[descricao].nomeMaiorNota.push(aluno.nome_aluno);
                }
            });

            const mediaAluno = totalAvaliacoesAluno ? somaNotasAluno / totalAvaliacoesAluno : 0;
            if (mediaAluno >= 7) {
                totalAlunosAprovados += 1;
            }
        });

        Object.keys(estatisticasPorAvaliacao).forEach(descricao => {
            const estatisticas = estatisticasPorAvaliacao[descricao];
            estatisticas.porcentagemMaior8 = estatisticas.totalNotas 
                ? (estatisticas.excelente / estatisticas.totalNotas) * 100 
                : 0;
            estatisticas.mediaNota = estatisticas.totalNotas 
                ? estatisticas.somaNotas / estatisticas.totalNotas 
                : 0;
            delete estatisticas.totalNotas;
            delete estatisticas.somaNotas;
        });

        setAlunosAprovados(totalAlunosAprovados);
        setTotalAlunos(totalDeAlunos);
        return estatisticasPorAvaliacao;
    };

    const calcularEtapa = (etapaId) => {
        setEtapaSelecionada(etapaId);

        fetch(`https://suapuno.app:8080/api/diarios/${etapas.disciplinaSelecionada.id_diario}/desempenho/${etapaId}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        .then((resposta) => {
            if (!resposta.ok) {
                throw new Error('Falha ao acessar a API');
            }
            return resposta.json();
        })
        .then((dados) => {
            const stats = processarDados(dados);
            console.log(dados)
            setDadosProcessados(stats);
        })
        .catch((erro) => console.error(erro));
    };

    console.log(dadosProcessados)

    const getDadosAluno = (id_aluno) => {
        fetch(`https://suapuno.app:8080/api/diarios/${etapas.disciplinaSelecionada.id_diario}/alunos/${id_aluno}/desempenho/` ,{
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        .then((resposta) => {
            return resposta.json();
        })
        .then((dados) => {
            setDadosEspecificosAluno(dados);
        })
        .catch((erro) => console.error(erro));
    }

    const porcentagemAprovados = totalAlunos ? ((alunosAprovados / totalAlunos) * 100) : 0;

    let estilo;
    let desempenhoPorcentagem;
    let texto;
    if (porcentagemAprovados >= 70) {
        estilo = 'bg-[#6fb46f]';
        texto = 'text-green-700 text-sm';
        desempenhoPorcentagem = 'Excelente';
    } else if (porcentagemAprovados >= 50 && porcentagemAprovados < 70) {
        estilo = 'bg-yellow-400';
        texto = 'text-yellow-700 text-sm';
        desempenhoPorcentagem = 'Regular';
    } else {
        estilo = 'bg-red-400';
        texto = 'text-red-700 text-sm';
        desempenhoPorcentagem = 'Insatisfatório';
    }

    const getTodosDados = () => {
        let allData = {};
    
        const fetchDataForI = (i) => {
            return fetch(`https://suapuno.app:8080/api/diarios/${etapas.disciplinaSelecionada.id_diario}/desempenho/${i}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${token}`
                }
            })
            .then((resposta) => {
                if (!resposta.ok) {
                    throw new Error('Falha ao acessar a API');
                }
                return resposta.json();
            })
            .then((dados) => {
                let nomeEtapa;
                switch (i) {
                    case 1:
                        nomeEtapa = 'N1';
                        break;
                    case 2:
                        nomeEtapa = 'N2';
                        break;
                    case 3:
                        nomeEtapa = 'N3';
                        break;
                    case 4:
                        nomeEtapa = 'N4';
                        break;
                    case 5:
                        nomeEtapa = 'Final';
                        break;
                    default:
                        nomeEtapa = 'N';
                        break;
                }
                allData[i] = { ...dados, nomeEtapa };
            })
            .catch((erro) => console.error(erro));
        };
    
        const promises = [];
        for (let i = 1; i <= 5; i++) {
            promises.push(fetchDataForI(i));
        }
    
        Promise.all(promises)
            .then(() => {
                setTodosOsDados(allData);
            })
            .catch((erro) => console.error('Erro ao obter todos os dados', erro));
    };
    
    const abrirModal = (aluno) => {
        setAlunoSelecionado(aluno);
        getDadosAluno(aluno.id_aluno)
        setModalVisible(true);
    };

    const fecharModal = () => {
        setModalVisible(false);
        setAlunoSelecionado(null);
    };

    const getCorBarra = (valor) => {
        if (valor >= 70) return 'bg-green-500';
        if (valor >= 50) return 'bg-yellow-500';
        return 'bg-red-500';
    };

    useEffect(() => {
        calcularEtapa(1);
        getTodosDados();

        fetch(`https://suapuno.app:8080/api/diarios/${etapas.disciplinaSelecionada.id_diario}/desempenho/`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
            .then((resposta) => {
                if (!resposta.ok) {
                    throw new Error('Falha ao acessar a API');
                }
                return resposta.json();
            })
            .then((dados) => {
                setDadosAlunos(dados);
            })
            .catch((erro) => console.error(erro));
    // eslint-disable-next-line 
    },[token]);
    
    return (
        <div className="w-screen relative">
            <div className='bg-white flex justify-between w-full py-2'>
                <div className="w-full text-center" onClick={() => setAtivo(0)}>
                    <h1>Avaliações</h1>
                    {ativo === 0 && (
                        <div className='relative border-b-[5px] border-green-700 h-2 -mb-3'></div>
                    )}
                </div>
                <div className="w-full text-center" onClick={() => setAtivo(1)}>
                    <h1>Alunos</h1>
                    {ativo === 1 && (
                        <div className='relative border-b-[5px] border-green-700 h-2 -mb-3'></div>
                    )}
                </div>
            </div>

            {ativo === 0 && (
                <>
                    <div className='mx-2'>
                        <div className='flex gap-2 mt-4 justify-center text-xs'>
                            {etapas.etapas.map((etapa, index) => (
                                <div
                                    key={index}
                                    className={`${etapaSelecionada === index + 1 ? 'bg-green-600' : 'bg-white'} px-7 py-2 rounded-lg`}
                                    onClick={() => calcularEtapa(index + 1)}
                                >
                                    <h1>{etapa === 'Final' ? 'PF' : etapa}</h1>
                                </div>
                            ))}
                            <div
                                className={`${etapaSelecionada === etapas.etapas.length + 1 ? 'bg-green-600' : 'bg-white'} px-7 py-2 rounded-lg`}
                                onClick={() => calcularEtapa(etapas.etapas.length + 1)}
                            >
                                <h1>MF</h1>
                            </div>
                        </div>
                    </div>

                    {Object.keys(dadosProcessados).length > 0 ? (
                        <div>
                            <div className='mx-4 mb-28'>
                                {Object.keys(dadosProcessados).map(descricao => {
                                    const graficoRosquinha = {
                                        labels: ['Insatisfatório', 'Regular', 'Excelente'],
                                        datasets: [
                                            {
                                                data: [
                                                    dadosProcessados[descricao].insatisfatorio,
                                                    dadosProcessados[descricao].regular,
                                                    dadosProcessados[descricao].excelente
                                                ],
                                                backgroundColor: ['rgb(232,47,50)', 'rgb(216,198,43)', 'rgb(44,220,42)']
                                            }
                                        ]
                                    };
                                    
                                    const configRosquinha = {
                                        responsive: true,
                                        cutout: '70%',
                                        plugins: {
                                            legend: {
                                                display: false,
                                            },
                                            tooltip: {
                                                displayColors: false,
                                            }
                                        }
                                    };
                                    
                                    return (
                                        <div key={descricao} className='mt-2'>
                                            <Card
                                                icone={<PiChartDonut />}
                                                titulo={`Resumo da avaliação ${descricao}`}
                                                blockMovimento = {true}
                                                conteudo={
                                                    <div className="flex flex-col relative">
                                                        <div className='flex'>    
                                                            <div className="h-[38%] w-[38%] relative">
                                                                <Doughnut data={graficoRosquinha} options={configRosquinha} />
                                                                <div className="absolute inset-0 flex flex-col justify-center items-center text-center text-[10px]">
                                                                    {isNaN(dadosProcessados[descricao].porcentagemMaior8) || dadosProcessados[descricao].porcentagemMaior8 == null ? (
                                                                        <p className="-mb-8 mt-2">Sem média disponível</p>
                                                                    ) : (
                                                                        <p className="text-2xl font-bold -mb-5">{dadosProcessados[descricao].porcentagemMaior8}%</p>
                                                                    )}
                                                                    <br />das notas são<br /> excelentes
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-col justify-center items-start font-bold text-sm mt-7 ml-2 space-y-2">
                                                                <div className="flex items-center">
                                                                    <div className="h-[15px] w-[15px] rounded bg-[#e82f32]"></div>
                                                                    <h1 className="ml-1">{dadosProcessados[descricao].insatisfatorio} em situação Insatisfatório</h1>
                                                                </div>
                                                                <div className="flex items-center">
                                                                    <div className="h-[15px] w-[15px] rounded bg-[#d8c62b]"></div>
                                                                    <h1 className="ml-1">{dadosProcessados[descricao].regular} em situação Regular</h1>
                                                                </div>
                                                                <div className="flex items-center">
                                                                    <div className="h-[15px] w-[15px] rounded bg-[#2cdc2a]"></div>
                                                                    <h1 className="ml-1">{dadosProcessados[descricao].excelente} em situação Excelente</h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='flex flex-col mt-3'>
                                                            <div className='flex items-center gap-2'>
                                                                <div className='h-1 w-1 bg-black rounded-full'></div>
                                                                <p>A média da turma nessa avaliação foi <span className='font-bold'> {dadosProcessados[descricao].mediaNota !== null ? dadosProcessados[descricao].mediaNota.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '--'} </span></p>
                                                            </div>
                                                            <div className='flex items-center gap-2 mt-2'>
                                                                <div className='h-1 w-1 bg-black rounded-full'></div>
                                                                <p>As maiores notas da avaliação pertencem a <span className='font-bold'>{dadosProcessados[descricao].nomeMaiorNota.join(', ')}</span></p>
                                                            </div>
                                                            <div className='flex items-center gap-2 mt-2'>
                                                                <div className='h-1 w-1 bg-black rounded-full'></div>
                                                                <p>A avaliação teve <span className='font-bold'>{dadosProcessados[descricao].excelente}</span> {dadosProcessados[descricao].excelente === 1 ? 'nota com uma situação' : 'notas com uma situação'} <span className='font-bold'>excelente</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                            <div className={`fixed bottom-0 w-full ${estilo} py-3 mb-12 text-center`}>
                                <p className='my-1'><span className='font-bold'>{porcentagemAprovados}%</span> dos alunos foram aprovados nessa etapa!</p>
                                <p className={texto}>O desempenho da sua turma nessa etapa foi <span className='font-bold'>{desempenhoPorcentagem}</span></p>
                            </div>
                        </div>
                    ) : (
                        <div className='flex justify-center items-center h-[50vh] mx-10 text-sm text-center font-semibold'>
                            <PiWarningCircle size={100} color='red' />
                            <h1> Até o momento não é possível visualizar o desempenho da turma. <br></br>Venha novamente em breve quando esse acesso for possível!</h1>
                        </div>
                    )}
                </>
            )}
            {ativo === 1 && (
                <>
                    <div className='mx-2 mt-4'>
                        <Card
                            icone={<PiGraduationCap />}
                            titulo={'Alunos'}
                            blockMovimento = {true}
                            conteudo={
                                <div>
                                    {dadosAlunos.map((aluno, index) => (
                                        <div key={index} className='flex justify-between mb-2'>
                                            <div className='flex items-center'>
                                                <PiStudent size={30} />
                                                <h1>{aluno.nome_aluno}</h1>
                                            </div>
                                            <button className='bg-green-700 px-3 py-1 text-white rounded-lg' onClick={() => abrirModal(aluno)}>Analisar</button>
                                        </div>
                                    ))}
                                </div>
                            }
                        />
                    </div>
                </>
            )}

            {modalVisible && alunoSelecionado && (
                <div className='fixed inset-0 flex items-start justify-center bg-black bg-opacity-50'>
                    <div className='bg-white p-4 rounded-lg w-[95%] mt-10'>
                        <div className='flex w-full justify-between'>
                            <PiGraduationCap size={80}/>
                            <h1 className='w-[70%]'>
                                <p className='font-bold text-xl'>{alunoSelecionado.nome_aluno}</p>
                                <p className='text-gray-600 text-xs'>matricula: {dadosEspecificosAluno.matricula_aluno}</p>
                                <p className='text-gray-600 text-xs mt-[3px]'>email: {dadosEspecificosAluno.email_aluno}</p>
                                <p className='text-gray-600 text-xs mt-[3px]'>whatsapp: {dadosEspecificosAluno.contato_aluno}</p>
                            </h1>
                            <button className='flex' onClick={fecharModal}>X</button>
                        </div>
                        {etapas.etapas.map((etapa, etapaIndex) => {
                            const dadosEtapa = Object.values(todosOsDados).find(dado => dado.nomeEtapa === etapa);

                            if (dadosEtapa && Object.keys(dadosEtapa).length > 2) {
                                return (
                                    <div key={etapaIndex}>
                                        <h1 className='font-bold'>{etapa}</h1>
                                        {Object.keys(dadosEtapa).map((alunoIndex, innerIndex) => {
                                            const alunoData = dadosEtapa[alunoIndex];
                                            console.log(alunoData)
                                            if (alunoData && alunoData.nome_aluno && alunoSelecionado.nome_aluno === alunoData.nome_aluno) {
                                                return (
                                                    <div key={innerIndex}>
                                                        {alunoData.avaliacoes.map((avaliacao, avaliacaoIndex) => (
                                                            <div key={avaliacaoIndex} className='flex items-center'>
                                                                <PiClipboardText size={25}/>
                                                                <div className='flex items-center min-w-14 max-w-14 h-[38px] border-r-2 border-black'>
                                                                    <p className="mr-1 text-xs break-all">{avaliacao.descricao_avaliacao}</p>
                                                                </div>
                                                                <div className='flex-1'>
                                                                    <div className="w-full bg-gray-300 h-4 relative">
                                                                        <p className='text-[11px] right-0 absolute -mt-[14px] text-gray-400'>nota max: {avaliacao.nota_maxima}</p>
                                                                        <div className={`absolute top-0 left-0 h-full ${getCorBarra((avaliacao.nota / avaliacao.nota_maxima) * 100)}`} style={{ width: `${(avaliacao.nota / avaliacao.nota_maxima) * 100}%` }}>
                                                                            <p className='text-white text-[11px] text-end mr-1'>{avaliacao.nota?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) ?? ''}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <div className='flex items-center'>
                                                            <PiScroll size={25}/>
                                                            <div className='flex items-center min-w-14 max-w-14 h-[38px] border-r-2 border-black'>
                                                                <p className="mr-1 text-xs text-center">Média<br/> {etapa}</p>
                                                            </div>
                                                            <div className='flex-1'>
                                                                <div className="w-full bg-gray-300 h-4 relative ">
                                                                    <p className='text-[11px] right-0 absolute -mt-[14px] text-gray-400'>nota max: 10</p>
                                                                    {alunoSelecionado.etapas.map((aluno, index) => {
                                                                        if (aluno.etapa === etapa) {
                                                                            return (
                                                                                <div key={index} className={`absolute top-0 left-0 h-full ${getCorBarra((aluno.media_etapa / 10) * 100)}`} style={{ width: `${(aluno.media_etapa / 10) * 100}%` }}>
                                                                                    <p className='text-white text-[11px] text-end mr-1'>{aluno.media_etapa !== null ? aluno.media_etapa.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '---'}</p>
                                                                                </div>
                                                                            );
                                                                        } else {
                                                                            return null;
                                                                        }
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Desempenho;
