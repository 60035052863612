import React, { useEffect } from 'react';
import './notificacao.css';

function Notificacao({ tipo, mensagem, isVisible, setIsVisible }) {
  useEffect(() => {
    const temporizador = setTimeout(() => {
      setIsVisible(false);
    }, 1000); // Definindo um tempo de exibição de 2 segundos para a notificação

    return () => clearTimeout(temporizador);
  }, [isVisible, setIsVisible]);

  const classeNotificacao = tipo === 'sucesso' ? 'bg-green-500' : 'bg-red-500';

  return (
    <>
      {isVisible && (
        <div className="deslizar-notificacao items-center justify-center w-full flex">
          <div className={`${classeNotificacao} text-white rounded-lg border-gray-300 border p-3 shadow-lg mt-10 items-center justify-center transition-opacity duration-500 ease-out opacity-100 hover:opacity-0`}>
            <div className="flex flex-row items-center justify-center">
              <div className="">
                <span className="text-gray-100">{mensagem}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Notificacao;
