import React, { useState, useEffect, useRef } from 'react';
import { PiPaperPlane, PiDot } from 'react-icons/pi';

const TesteDeAtendimento = () => {
  const [mensagens, setMensagens] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [digitando, setDigitando] = useState(false);
  const chatEnd = useRef(null);
  const chatContainerRef = useRef(null);
  const token = localStorage.getItem('token');

  const handleSendMensagem = async () => {
    if (inputValue.trim()) {
      const newMensagens = [...mensagens, { remetente: 'Você', texto: inputValue }];
      setMensagens(newMensagens);
      setInputValue('');
      setDigitando(true);

      try {
        const response = await fetch('https://suapuno.app:8080/api/assistente-virtual/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
          },
          body: JSON.stringify({ pergunta: inputValue }),
        });

        if (response.ok) {
          const data = await response.json();
          const resposta = data.resposta;

          if (resposta) {
            setMensagens([...newMensagens, { remetente: 'Suapinho', texto: resposta }]);
          } else {
            setMensagens([...newMensagens, { remetente: 'Suapinho', texto: 'Desculpe, não foi possível computar sua pergunta, tente novamente mais tarde.' }]);
          }
        } else {
          setMensagens([...newMensagens, { remetente: 'Suapinho', texto: 'Desculpe, ocorreu um erro ao processar sua mensagem, tente novamente mais tarde.' }]);
        }
      } catch (error) {
        setMensagens([...newMensagens, { remetente: 'Suapinho', texto: 'Desculpe, ocorreu um erro ao se comunicar com a nossa API, tente novamente mais tarde.' }]);
      } finally {
        setDigitando(false);
      }
    }
  };

  useEffect(() => {
    chatEnd.current?.scrollIntoView({ behavior: 'smooth' });
  }, [mensagens]);

  return (
    <div className="flex flex-col justify-between bg-[#898989] h-[calc(100vh-250px)] relative">
      <div className="top-0 left-0 w-full z-20">
        <div className="flex flex-row bg-[#1e731d] px-3 py-3 justify-between">
            <div className="flex items-center">
                <div className='relative'>
                    <div className=' w-12 h-12 bg-gray-300 rounded-full'></div>
                    <img src='/imagens/SuaPinho.png' className='h-8 w-8 -mt-10 ml-2 absolute'/>
                </div>
                <div className='flex-col'>
                    <h1 className="ml-2 font-bold text-gray-100">Suapinho - Atendimento virtual</h1>
                    <h1 className='ml-2 font-semibold text-sm text-gray-200'>PREVIEW</h1>
                </div>
            </div>
        </div>
      </div>
      <div 
        ref={chatContainerRef} 
        className="flex-1 overflow-y-auto p-4" 
        style={{ maxHeight: 'calc(100% - 120px)' }} 
      >
        {mensagens.map((mensagem, index) => (
          <div key={index} className={`flex mb-2 ${mensagem.remetente === 'Você' ? 'justify-end' : 'justify-start'}`}>
            <div className="flex items-start">
              <div className={`p-2 rounded-md text-sm relative ${mensagem.remetente === 'Suapinho' ? 'bg-white ml-2' : 'bg-[#7eda7d] mr-2'} ${mensagem.remetente === 'Você' ? '' : 'text-left'}`}>
                <div style={{ whiteSpace: 'pre-wrap' }}>{mensagem.texto}</div>
                <div className={`${mensagem.remetente === 'Suapinho' ? 'absolute w-0 h-0 border-t-[6px] border-t-transparent border-b-[6px] border-b-transparent border-r-[12px] border-r-white mt-2 top-0 -ml-4' : 'absolute w-0 h-0 border-t-[6px] border-t-transparent border-b-[6px] border-b-transparent border-l-[12px] border-l-[#7eda7d] mt-2 right-0 top-0 -mr-2'}`} />
              </div>
            </div>
          </div>
        ))}
        {digitando && (
          <div className="flex mb-2 justify-start">
            <div className="flex items-start">
              <div className="p-2 rounded-md text-sm bg-white text-left">
                <div className="font-bold">Suapinho</div>
                <div className='flex -mb-6 -mt-4'>
                  <PiDot size={60} className='animate-bounce -mr-10 -ml-5' />
                  <PiDot size={60} className='animate-bounce -mr-10' style={{ animationDelay: '0.2s' }} />
                  <PiDot size={60} className='animate-bounce -mr-4' style={{ animationDelay: '0.4s' }} />
                </div>
              </div>
            </div>
          </div>
        )}
        <div ref={chatEnd} />
      </div>
      <div className="p-4 flex flex-col w-full bg-black rounded-t-xl">
        <input
          type="text"
          placeholder="Digite uma mensagem..."
          className="w-full p-2 pr-[34px] rounded-md bg-black text-white border-[#138d12] border-2 mb-1"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') handleSendMensagem();
          }}
        />
        <PiPaperPlane size={23} color='#138d12' className="absolute right-0 mr-5 mt-2 rotate-45" onClick={handleSendMensagem} />
        <h1 className='text-[10px] text-center italic text-gray-400'>Suapinho pode cometer erros. Certifique-se de revisar suas informações</h1>
      </div>
    </div>
  );
};

export default TesteDeAtendimento;
