import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { PiFilePdf } from "react-icons/pi";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import bancos from './bancos.json';

const DadosBanco = () => {
    const navigate = useNavigate();
    const [arquivoSelecionado, setArquivoSelecionado] = useState(null);
    const [listaBancos] = useState(bancos);
    const token = localStorage.getItem('token');
    const modo_edicao = localStorage.getItem('modo_edicao');
    const id_user = localStorage.getItem('id_user');
    const id_peticao = localStorage.getItem('id_peticao')
    const [noDados, setNoDados] = useState(false);
    const [formulario, setFormulario] = useState({
        tipo: '',
        banco: { id: '' },
        agencia: '',
        conta: '',
        variacao: ''
    });

    useEffect(() => {
        const dadosBancariosArmazenados = localStorage.getItem('dados_bancarios');
        if (dadosBancariosArmazenados) {
            try {
                const dadosFormatados = JSON.parse(dadosBancariosArmazenados);
                setFormulario({
                    tipo: dadosFormatados.tipo || '',
                    banco: { id: dadosFormatados.banco?.id || '' },
                    agencia: dadosFormatados.agencia || '',
                    conta: dadosFormatados.conta || '',
                    variacao: dadosFormatados.variacao || ''
                });
                if (localStorage.getItem('arquivo3')) {
                    setArquivoSelecionado(base64ParaFile(localStorage.getItem('arquivo3'), 'Comprovante de cartão bancário ou fatura'));
                }
                if (dadosFormatados.id) {
                    localStorage.setItem('idDados', dadosFormatados.id);
                }
            } catch (e) {
                console.error("Erro ao parsear dados bancários:", e);
            }
        }else{
            setNoDados(true);
            console.log(noDados)
        }
    }, [noDados]);

    const btnCancelar = () => {
        limparLocalStorage();
        navigate('/sua-petição', { replace: true });
        
        window.location.reload();
    };

    const btnVoltar = async () => {
        await salvaParaLocastorage();
        navigate('/sua-petição/dados-pessoais');
    };

    const btnSalvar = async () => {
        if (!formCompleto()) {
            alert('Por favor, preencha todos os campos obrigatórios.');
            return;
        }
        if(noDados){
            await handlePostDadosBanco();
        }
        handleRealizarPeticao();
                
        limparLocalStorage();
        navigate('/sua-petição', { replace: true });
        
        window.location.reload();
    };

    const btnAtualizarDados = async () => {
        if(noDados){
            await handlePostDadosBanco();
        }

        const localStorageKeys = {
            dadosAlunoVerificado: 'dados_aluno_verificado',
            dadosBancarioVerificado: 'dados_bancario_verificado',
            dadosPeticaoVerificado: 'dados_peticao_verificado',
            comprovanteIncricaoVerificado: 'comprovante_incricao_verificado',
            comprovanteProgramacaoVerificado: 'comprovante_programacao_verificado',
        };
    
        const checkAndUpdate = (key, falseCallback, trueCallback = null) => {
            const value = localStorage.getItem(key);
            if (value === 'false') {
                console.log('Dados não verificados',{key});
                falseCallback();
            } else if (trueCallback && value === 'true') {
                trueCallback();
            }
        };
    
        Promise.all([
            checkAndUpdate(localStorageKeys.dadosAlunoVerificado, handlePutDadosPessoais),
            checkAndUpdate(localStorageKeys.dadosBancarioVerificado, handlePutDadosBanco),
            checkAndUpdate(localStorageKeys.dadosPeticaoVerificado, handlePutDadosEvento),
            checkAndUpdate(localStorageKeys.comprovanteIncricaoVerificado, handlePutComprovanteInscricaoEvento),
            checkAndUpdate(localStorageKeys.comprovanteProgramacaoVerificado, handlePutComprovanteProgramacaoEvento)
        ]).then(() => {
            setTimeout(() => {
                limparLocalStorage();
                navigate('/sua-petição', { replace: true });
                window.location.reload();
            }, 500); // 500 ms
        }).catch((error) => {
            console.error('Erro:', error);
        });
    };

    const salvaParaLocastorage = async () => {
        try {
            const arquivoBase64 = arquivoSelecionado ? await convertToBase64(arquivoSelecionado) : null;
            const dadosBancarios = {
                banco: { id: formulario.banco.id },
                agencia: formulario.agencia,
                conta: formulario.conta,
                tipo: formulario.tipo,
                variacao: formulario.variacao,
                comprovante: arquivoBase64
            };
            localStorage.setItem('dados_bancarios', JSON.stringify(dadosBancarios));
            localStorage.setItem('arquivo3', arquivoBase64);
        } catch (e) {
            console.error("Erro ao salvar dados bancários no localStorage:", e);
        }
    };

    const handleAlterarArquivo = (event) => {
        const file = event.target.files[0];
        setArquivoSelecionado(file);
    };

    const removerArquivoSelecionado = () => {
        setArquivoSelecionado(null);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormulario(prevState => {
            if (name === 'id_banco') {
                return {
                    ...prevState,
                    banco: { id: value }
                };
            }
            return {
                ...prevState,
                [name]: value
            };
        });
    };

    const handlePutDadosBanco = async () => {
        const idDados = localStorage.getItem('idDados');

        console.log('idDados')

        const arquivoBase64 = arquivoSelecionado ? await convertToBase64(arquivoSelecionado) : null;
        const dadosBancarios = {
            id_banco: formulario.banco.id ,
            agencia: formulario.agencia,
            conta: formulario.conta,
            tipo: formulario.tipo,
            variacao: formulario.variacao,
            comprovante: arquivoBase64
        };
        console.log(JSON.stringify(dadosBancarios))

        fetch(`https://suapuno.app:8080/api/dados-bancarios/${idDados}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
            body: JSON.stringify(dadosBancarios)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Erro na requisição: ' + response.statusText);
            }else{
                fetch(`https://suapuno.app:8080/api/peticao/${id_peticao}/`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${token}`,
                    },
                    body: JSON.stringify({ corrigido_dados_bancarios: true })
                })
            }
        })
        .catch(error => {
            console.error('Erro:', error);
        });


    };

    const handlePostDadosBanco = async () => {
        const arquivoBase64 = arquivoSelecionado ? await convertToBase64(arquivoSelecionado) : null;
        const dadosBancarios = {
            id_banco: formulario.banco.id ,
            agencia: formulario.agencia,
            conta: formulario.conta,
            tipo: formulario.tipo,
            variacao: formulario.variacao,
            comprovante: arquivoBase64
        };
        console.log(JSON.stringify(dadosBancarios))

        fetch(`https://suapuno.app:8080/api/dados-bancarios/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
            body: JSON.stringify(dadosBancarios)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Erro na requisição: ' + response.statusText);
            }
        })
        .catch(error => {
            console.error('Erro:', error);
        });
    };

    const handlePutDadosPessoais = useCallback(() => {
        const dados_pessoais = JSON.parse(localStorage.getItem('dados_pessoais'));

        const dadosPessoais = {
            aluno: {
                nome_aluno: dados_pessoais.nome_aluno,
                data_nascimento: dados_pessoais.data_nascimento,
                contato_aluno: dados_pessoais.contato_aluno,
                e_mail_aluno: dados_pessoais.e_mail_aluno,
                matricula_aluno: dados_pessoais.matricula_aluno,
            }
        };

        console.log(JSON.stringify(dadosPessoais))

        fetch(`https://suapuno.app:8080/api/usuarios/${id_user}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
            body: JSON.stringify(dadosPessoais),

        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Erro na requisição: ' + response.statusText);
            }else{
                fetch(`https://suapuno.app:8080/api/peticao/${id_peticao}/`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${token}`,
                    },
                    body: JSON.stringify({ corrigido_dados_pessoais: true })
                })
            }
        })
        .catch(error => {
            console.error('Erro:', error);
        });
    }, [token, id_peticao, id_user]);

    const handlePutDadosEvento = (() => {
        const peticao = JSON.parse(localStorage.getItem('peticao'));

        const peticao_dados = ({
            evento: {
                    nome: peticao.evento.nome,
                    data_inicial: peticao.evento.data_inicial,
                    data_final: peticao.evento.data_final,
                    custo_deslocamento: peticao.evento.custo_deslocamento,
                    custo_inscricao: peticao.evento.custo_inscricao,
                    custo_alimentacao: peticao.evento.custo_alimentacao,
                    custo_hospedagem: peticao.evento.custo_hospedagem   
            },
            outros_gastos: {
              valor: peticao.outros_gastos.valor,
              descricao: peticao.outros_gastos.descricao
            }
        });

        console.log(JSON.stringify(peticao_dados))

        fetch(`https://suapuno.app:8080/api/peticao/${id_peticao}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
            body: JSON.stringify(peticao_dados)
        })
        .then(response => {
            console.log(response)
            if (!response.ok) {
                throw new Error('Erro na requisição: ' + response.statusText);
            }
        })
        .catch(error => {
            console.error('Erro:', error);
        });

    })

    const handlePutComprovanteInscricaoEvento = (() => {
        const peticao = JSON.parse(localStorage.getItem('peticao'));

        const peticao_dados = ({
            comprovante_inscricao: peticao.comprovante_inscricao,
        });

        console.log(JSON.stringify(peticao_dados))

        fetch(`https://suapuno.app:8080/api/peticao/${id_peticao}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
            body: JSON.stringify(peticao_dados)
        })
        .then(response => {
            console.log(response)
            if (!response.ok) {
                throw new Error('Erro na requisição: ' + response.statusText);
            }
        })
        .catch(error => {
            console.error('Erro:', error);
        });

    })

    const handlePutComprovanteProgramacaoEvento = (() => {
        const peticao = JSON.parse(localStorage.getItem('peticao'));

        const peticao_dados = ({
            comprovante_programacao: peticao.comprovante_programacao,
        });

        console.log(JSON.stringify(peticao_dados))

        fetch(`https://suapuno.app:8080/api/peticao/${id_peticao}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
            body: JSON.stringify(peticao_dados)
        })
        .then(response => {
            console.log(response)
            if (!response.ok) {
                throw new Error('Erro na requisição: ' + response.statusText);
            }
        })
        .catch(error => {
            console.error('Erro:', error);
        });

    })

    const handleRealizarPeticao = useCallback(() => {
        const peticao = localStorage.getItem('peticao');

        fetch(`https://suapuno.app:8080/api/peticao/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
            body: peticao
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Erro na requisição: ' + response.statusText);
            }
        })
        .catch(error => {
            console.error('Erro:', error);
        });
    }, [token]);

    const limparLocalStorage = (() => {
        localStorage.removeItem('dados_pessoais');
        localStorage.removeItem('dados_bancarios');
        localStorage.removeItem('peticao');
        localStorage.removeItem('arquivo1');
        localStorage.removeItem('arquivo2');
        localStorage.removeItem('arquivo3');
        localStorage.removeItem('modo_edicao');
        localStorage.removeItem('idDados');
        localStorage.removeItem('dados_aluno_verificado');
        localStorage.removeItem('dados_bancario_verificado');
        localStorage.removeItem('dados_peticao_verificado');
        localStorage.removeItem('comprovante_incricao_verificado');
        localStorage.removeItem('comprovante_programacao_verificado');
        localStorage.removeItem('id_peticao');
    })

    const formCompleto = () => {
        const camposObrigatorios = ['tipo', 'banco.id', 'agencia', 'conta'];
        if (formulario.tipo === 'Poupança') {
            camposObrigatorios.push('variacao');
        }
        return camposObrigatorios.every(field => {
            const [obj, key] = field.split('.');
            return key ? formulario[obj][key] !== "" && formulario[obj][key] !== undefined && formulario[obj][key] !== null : formulario[obj] !== "" && formulario[obj] !== undefined && formulario[obj] !== null;
        });
    };

    const liberarBotao = () => {
        return formCompleto() && arquivoSelecionado;
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            if (!file) {
                reject("Nenhum arquivo fornecido");
            }

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const base64String = reader.result.split(';base64,')[1];
                resolve(base64String);
            };
            reader.onerror = (error) => {
                reject(error);
            };
        });
    };

    function base64ParaFile(base64String, filename) {
        const base64Data = base64String.split(';base64,').pop();
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/octet-stream' });
        const file = new File([blob], filename, { type: 'application/octet-stream' });

        return file;
    }
    return (
        <div className="flex flex-col h-full w-screen">
            <div className="flex flex-col items-center justify-center">
                <h1 className="font-bold mt-2">Tipo de Processo</h1>
                <h1 className="mx-16 text-center">Auxilio Acadêmico (evento de ensino, pesquisa e extensão)</h1>
            </div>
            <div className="flex px-3 items-center justify-center gap-3 mt-5 mx-3">
                <div>
                    <h1 className="text-white bg-green-500 w-12 h-12 flex items-center justify-center rounded-full">1</h1>
                    <h1 className="w-14 text-center">Sobre o evento</h1>
                </div>
                <div className="border-b-2 border-gray-500 flex-grow"></div>
                <div>
                    <h1 className="text-white bg-green-500 w-12 h-12 flex items-center justify-center rounded-full">2</h1>
                    <h1 className="w-12 text-center">Dados <span className="-ml-1">Pessoais</span></h1>
                </div>
                <div className="border-b-2 border-gray-500 flex-grow"></div>
                <div>
                    <h1 className="text-white bg-black w-12 h-12 flex items-center justify-center rounded-full">3</h1>
                    <h1 className="w-12 text-center">Dados <span className="-ml-2">Bancários</span></h1>
                </div>
            </div>
            <div className="grid m-5 gap-5">
                <div className="justify-center items-center bg-green-500 text-center p-2 rounded-lg w-80 mx-auto text-white z-10">
                    Dados Bancarios
                </div>
                <div className="bg-white p-4 -mt-10 rounded-lg shadow-[0px_0px_10px_7px_rgba(0,0,0,0.2)]">
                    <div className="font-bold text-sm mt-3">
                        <h1>Tipo de Conta:</h1>
                        <div className="flex flex-row justify-between mx-5">
                            <label className="flex gap-2">
                                <input type="radio" name="tipo" value="Conta corrente" checked={formulario.tipo === 'Conta corrente'} onChange={handleInputChange}/>
                                Corrente
                            </label>
                            <label className="flex gap-2">
                                <input type="radio" name="tipo" value="Poupança" checked={formulario.tipo === 'Poupança'} onChange={handleInputChange}/>
                                Poupança
                            </label>
                        </div>
                    </div>
                    <div className="font-bold text-sm mt-3">
                        <h1>Banco:</h1>
                        <select name='id_banco' className="mt-1 bg-gray-300 border-gray-400 border rounded-md p-2 w-full" value={formulario.banco.id} onChange={handleInputChange}>
                            <option value=''>Selecione</option>
                            {listaBancos.map(banco => (
                                banco && <option key={banco.id} value={banco.id}>{banco.nome}</option>
                            ))}
                        </select>
                    </div>
                    <div className="font-bold text-sm mt-3">
                        <h1>Agencia:</h1>
                        <input className="mt-1 bg-gray-300 border-gray-400 border rounded-md p-2 w-full" type="text" name="agencia" value={formulario.agencia} onChange={handleInputChange} />
                    </div>
                    <div className="font-bold text-sm mt-3">
                        <h1>Conta:</h1>
                        <input className="mt-1 bg-gray-300 border-gray-400 border rounded-md p-2 w-full" type="text" name="conta" value={formulario.conta} onChange={handleInputChange}/>
                    </div>
                    {formulario.tipo === 'Poupança' && (
                        <div className="font-bold text-sm mt-3">
                            <h1>Variação:</h1>   
                            <input className="mt-1 bg-gray-300 border-gray-400 border rounded-md p-2 w-full" type="text" name="variacao" value={formulario.variacao} onChange={handleInputChange}/>
                        </div>
                    )}
                    <div className="justify-center items-center bg-green-500 text-center p-2 rounded-lg mx-auto w-full text-white z-10 mt-5">
                        Documentos complementares
                    </div>
                    <div className="flex flex-row mt-4 items-center">
                        {arquivoSelecionado ? (
                            <div className="flex items-center text-sm gap-7" onClick={removerArquivoSelecionado}>
                                <span className="font-bold">Comprovante de cartão bancário ou fatura</span>
                                <AiFillMinusCircle size={30} color="red"/>
                                
                            </div>
                        ) : (
                            <>
                                <input type="file" onChange={handleAlterarArquivo} className="hidden" id="fileInput" />
                                <label htmlFor="fileInput" className="flex flex-row items-center text-sm gap-7">
                                    <span className="font-bold">Comprovante de cartão bancário ou fatura</span>
                                    <AiFillPlusCircle htmlFor="fileInput" size={30} color="green"/>
                                </label>
                            </>
                        )}
                    </div>
                    {arquivoSelecionado && 
                    <div className="flex flex-row items-center mt-2">
                        <PiFilePdf size={50} color="red"/>
                        <span className="ml-2">{(arquivoSelecionado && arquivoSelecionado.name) || 'Comprovante de cartão bancário ou fatura'}</span>
                    </div>}
                </div>
                <div className="flex justify-end gap-5">
                    <button className='px-3 py-1 w-30 bg-red-600 rounded-lg text-white' onClick={btnCancelar}>Cancelar</button>
                    <button className='px-3 py-1 w-30 bg-green-600 rounded-lg text-white' onClick={btnVoltar}>Anterior</button>
                    {modo_edicao ? (
                       <button className={`px-3 py-1 w-30 bg-green-600 rounded-lg text-white ${!liberarBotao() ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={btnAtualizarDados} disabled={!liberarBotao()}>Enviar correções</button>)
                    : (
                        <button className={`px-3 py-1 w-30 bg-green-600 rounded-lg text-white ${!liberarBotao() ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={btnSalvar} disabled={!liberarBotao()}>Peticionar</button>)
                    }
                </div>
            </div>
        </div>
    );
};

export default DadosBanco;