import React, { useEffect, useState } from 'react';
import { FaSearch, FaRegFileAlt } from 'react-icons/fa';
import { PiWarningCircle } from "react-icons/pi";
import { isBrowser } from 'react-device-detect';
import Menu from "../../../components/ui/menuTopo";
import WidgetPeticao from '../widget_peticao';
import BotoesStatus from '../../../components/ui/botoesStatus';
import CheckboxesModalidade from '../../../components/ui/checkboxesModalidade';

const GerenciarPeticoes = () => {
  const [peticoes, setPeticoes] = useState([]);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [termoBusca, setTermoBusca] = useState('');
  const [statusSelecionado, setStatusSelecionado] = useState('');
  const [selectedModalidades, setSelectedModalidades] = useState([]);
  const [showWidget, setShowWidget] = useState(false);
  const [selectedPeticao, setSelectedPeticao] = useState(null);
  const [idUser, setIdUser] = useState(null);
  const itensPorPagina = Math.floor(window.innerHeight / 200);
  const itensPorBloco = 5;
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetch('https://suapuno.app:8080/api/peticao/', {
      method: 'GET',
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then(data => {
      if (data && data.length > 0) {
        setPeticoes(data);
      } else {
        if(data.status === 404){
          return  null
        }else{
          console.error('Resposta da API inválida');
        }
      }
    })
  }, [token]);

  const statusPossiveis = [
    'Em análise', 'Não contemplado', 'Contemplado', 'Aguardando correções', 
    'Prestação de contas', 'Aguardando pagamento da GRU', 'Concluído', 'Análise de prestação de contas', 'Análise de pagamento da GRU'
  ];

  const modalidades = ['Evento de ensino, pesquisa e extensão', 'Desporto e lazer', 'Sócio estudantil'];

  const indexUltimo = paginaAtual * itensPorPagina;
  const indexOfPrimeiroItem = indexUltimo - itensPorPagina;

  const itensFiltrados = peticoes.filter(item =>
    item.nome_evento.toLowerCase().includes(termoBusca.toLowerCase()) &&
    (statusSelecionado === '' || (item.status.length > 0 && item.status[item.status.length - 1].nome_status.toLowerCase() === statusSelecionado.toLowerCase())) &&
    (selectedModalidades.length === 0 || selectedModalidades.includes(item.modalidade_auxilio.nome))
  ).reverse();

  const currentItems = itensFiltrados.slice(indexOfPrimeiroItem, indexUltimo);

  const paginasTotais = Math.ceil(itensFiltrados.length / itensPorPagina);

  const paginasVisiveis = () => {
    const paginas = [];
    let inicio = paginaAtual - Math.floor(itensPorBloco / 2);
    inicio = Math.max(inicio, 1);
    const fim = Math.min(inicio + itensPorBloco - 1, paginasTotais);

    for (let i = inicio; i <= fim; i++) {
      paginas.push(i);
    }

    return paginas;
  };

  const handleClickStatus = (status) => {
    setStatusSelecionado(prevStatus => prevStatus === status ? '' : status);
    setPaginaAtual(1);
  };

  const handleChangeModalidade = (modalidades) => {
    setSelectedModalidades(modalidades);
    setPaginaAtual(1);
  };

  const handleAnalisarClick = (peticao) => {

    if(peticao.visualizado_assistente === false){
      fetch(`https://suapuno.app:8080/api/peticao/${peticao.id}/`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        },
        body: JSON.stringify({visualizado_assistente: true}),
  
      })
      .then(response => {
          if (!response.ok) {
              throw new Error('Erro na requisição: ' + response.statusText);
          }
      })
      .catch(error => {
          console.error('Erro:', error);
      });
    }

    setSelectedPeticao(peticao.id);
    setIdUser(peticao.id_user);
    setShowWidget(true);
    peticao.visualizado_assistente = true;
  };

  const handleCloseWidget = () => {
    setShowWidget(false);
  };

  if (!isBrowser) {
    return (
      <div className="flex flex-col items-center justify-center h-full text-center">
        <div className="top-0 left-0 w-full"><Menu /></div>
        <div className='flex flex-col justify-center items-center mt-[calc(50%)]'>
          Site não disponível para dispositivos móveis, por favor acesse de um computador.
          <PiWarningCircle size={100} color='red' />
        </div>
      </div>
    );
  }

  return (
    <div className='flex h-screen'>
      {showWidget && <WidgetPeticao onClose={handleCloseWidget} peticaoId={selectedPeticao} idUser={idUser} />}
      <div className="flex flex-col flex-grow bg-gray-200">
          <div className="flex flex-col h-auto px-10 pt-5">
            <div className='flex'>
              <h1 className='text-gray-500 text-sm'>
                Auxílio acadêmico &gt; Gerenciar petições
              </h1>
            </div>
            <h1 className="text-[40px] font-semibold mb-1">Filtrar Petições</h1>
            <h1 className="text-lg font-bold mt-3">Status</h1>
            <BotoesStatus statusPossiveis={statusPossiveis} onClickStatus={handleClickStatus} statusSelecionado={statusSelecionado} />
            <div className="flex flex-row gap-3 mt-2">
              <div className="flex flex-col">
                <h1 className="text-lg font-bold mb-1">Evento</h1>
                <input
                  className="border border-gray-400 bg-gray-300 rounded-md w-80 pl-10 py-1"
                  placeholder="Buscar petição..."
                  value={termoBusca}
                  onChange={(e) => {
                    setTermoBusca(e.target.value);
                    setPaginaAtual(1);
                  }}
                />
                <span className="absolute mt-[40px] ml-2 text-gray-400"><FaSearch size={20} /></span>
              </div>
              <div>
                <CheckboxesModalidade modalidades={modalidades} onChangeModalidade={handleChangeModalidade} />
              </div>
            </div>
            <div className="mt-2">
              <table className="w-full rounded-lg overflow-hidden">
                <thead className="bg-[#1E731D] text-white">
                  <tr>
                    <th className="w-1/5 text-center py-5">Evento</th>
                    <th className="w-1/5 text-center">Aluno</th>
                    <th className="w-1/5 text-center">Modalidade</th>
                    <th className="w-1/5 text-center">Status</th>
                    <th className="w-1/5 text-center">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((peticao, index) => (
                    <tr key={index} className={`${index % 2 === 0 ? 'bg-[#54905359]' : 'bg-[#b5b5b565]'} font-semibold`}>
                      <td className='flex ml-16 py-6 text-start items-center'>{peticao.nome_evento}</td>
                      <td className=''>
                        <span className='flex flex-row'>
                          {peticao.nome_aluno}
                          <br />
                          matrícula: {peticao.matricula_aluno}
                        </span>
                      </td>
                      <td className='text-center'>{peticao.modalidade_auxilio.nome}</td>
                      <td className='text-center'>
                        {peticao.status.length > 0 ? peticao.status[peticao.status.length - 1].nome_status : 'Sem status'}
                      </td>
                      <td className='text-center '>
                        <button className='py-2 px-5 rounded-md border-2 border-black' onClick={() => handleAnalisarClick(peticao)}>
                          <span className='flex flex-row justify-center gap-1'>
                            <FaRegFileAlt size={30} color='black'/>
                            Analisar
                          </span>
                        </button>   
                      </td>
                      {peticao.visualizado_assistente === false ? <div className="absolute mt-[25px] -ml-20 bg-red-500 text-white rounded-full h-6 w-6 flex justify-center items-center">!</div> : <></>}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex justify-center mt-4 gap-2">
              {paginasVisiveis().map((pagina, index) => (
                <button key={index} onClick={() => setPaginaAtual(pagina)} className={`px-3 py-1 rounded-full ${pagina === paginaAtual ? 'bg-green-600 text-white' : 'bg-gray-400'}`}>{pagina}</button>
              ))}
            </div>
          </div>
      </div>
    </div>
  );
};

export default GerenciarPeticoes;