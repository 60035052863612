import React, { useState, useEffect } from 'react';
import { PiFilePdf, PiX, PiCheck, PiWarningCircle } from "react-icons/pi";

const DadosPessoais = ({ idUser, correcoesFeedback, updateCorrecoesFeedback, statusPeticao, onAlunoIdChange, onIdDadosBancariosChange, updateStatusContaAluno, setStatusBancoAluno }) => {
  const [dadosPessoais, setDadosPessoais] = useState(null);
  const [dadosBancarios, setDadosBancarios] = useState(null);
  const [cursos, setCursos] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const token = localStorage.getItem('token');
  
  useEffect(() => {
    fetch(`https://suapuno.app:8080/api/usuarios/${idUser}/`, {
      method: 'GET',
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Erro ao acessar a API');
      }
      return response.json();
    })
    .then(data => {
      setDadosPessoais(data);
      if (data && data.aluno && data.aluno.id_aluno) {
        onAlunoIdChange(data.aluno.id_aluno); // Envia o ID do aluno para o componente pai
        updateStatusContaAluno(data.aluno.verificado) // Envia o status da conta do aluno para o componente pai
      }
    })
    .catch(error => {
      console.error('Erro ao buscar dados:', error);
    });
  }, [idUser, token, onAlunoIdChange, updateStatusContaAluno, setStatusBancoAluno]);

  useEffect(() => {
    fetch('https://suapuno.app:8080/api/informacoes/cursos/', {
      method: 'GET',
      headers: {
        'accept': 'application/json',
        'X-CSRFToken': 'on4XPqckHCd2h9gKxuYmBSRC0cHWFGB0bMWOfUVqyxhgvP9PnYILPHja78mRiXnk',
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Erro ao acessar a API de cursos');
      }
      return response.json();
    })
    .then(data => {
      setCursos(data);
    })
    .catch(error => {
      console.error('Erro ao buscar dados dos cursos:', error);
    });
  }, []);

  useEffect(() => {
    if (dadosPessoais) {
      fetch(`https://suapuno.app:8080/api/dados-bancarios/${dadosPessoais.aluno.id_aluno}/`, {
        method: 'GET',
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Erro ao acessar a API');
        }
        return response.json();
      })
      .then(data => {
        setDadosBancarios(data);
        onIdDadosBancariosChange(data.id);
        setStatusBancoAluno(data.verificado); // Envia o status dos dados bancários para o componente pai
      })
      .catch(error => {
        console.error('Erro ao buscar dados:', error);
      });
    }
  }, [dadosPessoais, idUser, token, onIdDadosBancariosChange, setStatusBancoAluno]);

  const getCursoNome = (idCurso) => {
    const curso = cursos.find(curso => curso.id === idCurso);
    return curso ? curso.nome_curso : 'Curso não encontrado';
  }

  const getAnoSemestre = (idAnoSemestre) => {
    let anoSemestre = null;
    cursos.forEach(curso => {
      curso.anoSemestres.forEach(anoSem => {
        if (anoSem.id === idAnoSemestre) {
          anoSemestre = anoSem.ano_semestre;
        }
      });
    });
    return anoSemestre ? anoSemestre : 'Ano/Semestre não encontrado';
  }

  const formatarData = (data) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(data).toLocaleDateString('pt-BR', options);
  };

  const abreModal = (content) => {
    setModalContent(content);
    setShowModal(true);
  }

  const fechaModal = (feedbackType) => {
    if (feedbackType === 'inválido') {
      updateCorrecoesFeedback(`Dados bancários`);
    }
    setModalContent(null);
    setShowModal(false);
  }

  return (
    <div className='flex flex-col bg-white h-auto my-3 py-5 px-12 rounded-lg shadow-[0px_0px_10px_7px_rgba(0,0,0,0.2)]'>
      <div>
        <h1 className='bg-[#0DB30B] py-1 px-20 rounded-lg text-white text-center'>Dados pessoais </h1>
        {dadosPessoais && (
          <>
            {(correcoesFeedback.includes('Dados pessoais') || dadosPessoais?.aluno.verificado === false) && <PiWarningCircle color='red' size={20} className='absolute ml-[345px] -mt-[26px]'/>}
            <h1 className='font-bold -ml-7 mt-3'>Nome do aluno:<span className='font-normal'> {dadosPessoais.aluno.nome_aluno}</span></h1>
            <h1 className='font-bold -ml-7 mt-3'>Data de nascimento:<span className='font-normal'> {formatarData(dadosPessoais.aluno.data_nascimento)}</span></h1>
            <h1 className='font-bold -ml-7 mt-3'>Matrícula:<span className='font-normal'> {dadosPessoais.aluno.matricula_aluno}</span></h1>
            <h1 className='font-bold -ml-7 mt-3'>Curso:<span className='font-normal'> {getCursoNome(dadosPessoais.aluno.id_curso)}</span></h1>
            <h1 className='font-bold -ml-7 mt-3'>Ano de ingresso:<span className='font-normal'> {getAnoSemestre(dadosPessoais.aluno.id_ano_semestre_ingresso)}</span></h1>
            <h1 className='font-bold -ml-7 mt-3'>Telefone:<span className='font-normal'> {dadosPessoais.aluno.contato_aluno}</span></h1>
            <h1 className='font-bold -ml-7 mt-3 mb-2'>E-mail:<span className='font-normal'> {dadosPessoais.aluno.e_mail_aluno}</span></h1>
          </>
        )}
      </div>
      <div className='border-b border-gray-300 -ml-10 -mr-10'></div>
      <div>
        <h1 className='bg-[#0DB30B] py-1 px-20 rounded-lg text-white mt-3 text-center'>Dados bancários </h1>
        {dadosBancarios && (
          <>
            {(correcoesFeedback.includes('Dados bancários') || dadosBancarios?.verificado === false) && <PiWarningCircle color='red' size={20} className='absolute ml-[345px] -mt-[26px]'/>}
            <h1 className='font-bold -ml-7 mt-3'>Tipo de conta:<span className='font-normal'> {dadosBancarios.tipo}</span></h1>
            <h1 className='font-bold -ml-7 mt-3'>Banco:<span className='font-normal'> {dadosBancarios.banco.nome}</span></h1>
            <h1 className='font-bold -ml-7 mt-3'>Agência:<span className='font-normal'> {dadosBancarios.agencia}</span></h1>
            <h1 className='font-bold -ml-7 mt-3'>Conta:<span className='font-normal'> {dadosBancarios.conta}</span></h1>
            <h1 className='font-bold -ml-7 mt-3'>Variação:<span className='font-normal'> {dadosBancarios.variacao}</span></h1>
            <h1 className='flex flex-row font-bold -ml-7 mt-3'>Comprovante de cartão<br /> bancário ou fatura
              <span className='font-normal'> 
                <button className='ml-7 flex flex-row items-center justify-center border border-black rounded-lg border-spacing-13 p-2' onClick={() => abreModal(dadosBancarios.comprovante)}>
                  <PiFilePdf color='red' size={35} className='ml-1'/>
                  <h1 className='mr-2 text-gray-500 text-sm'>Acessar</h1>
                  {(correcoesFeedback.includes('Dados bancários') || dadosBancarios?.verificado === false) && <PiWarningCircle color='red' size={20} className='absolute ml-40'/>}
                </button>
              </span>
            </h1>
          </>
        )}
      </div>
      {showModal && (
        <div className='fixed top-0 left-0 bg-black bg-opacity-50 w-full h-full flex items-center justify-center z-50'>
          <div className='bg-[#333333] w-1/2 h-[70%] rounded-lg -mt-36'>
            <div className='flex justify-end'>
              {(statusPeticao !== 'Em análise' || (correcoesFeedback.includes('Dados bancários') || dadosBancarios?.verificado === false)) && (
                  <>
                    <button onClick={() => fechaModal(null)} className='mr-5 my-5 text-white'>X</button>
                  </>
                )}
            </div>
            <div className='flex justify-center w-full h-full'>
              <iframe title="PDF Viewer" src={modalContent} className="w-full h-full"></iframe>
            </div>
            <div className='flex justify-end bg-black rounded-b-lg'>
              {(statusPeticao === 'Em análise' && (!correcoesFeedback.includes('Dados bancários') && dadosBancarios?.verificado !== false )) && (
                <>
                  <h1 className='flex items-center text-white text-lg mr-10'>Esse documento é válido?</h1>
                  <button onClick={() => fechaModal("inválido")} className='mr-5 my-4 py-3 px-5 rounded-lg text-white bg-red-600'><PiX size={25} /></button>
                  <button onClick={() => fechaModal("válido")} className='mr-5 my-4 py-3 px-5 rounded-lg text-white bg-green-600'><PiCheck size={25} /></button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DadosPessoais;
