import { FiAlertTriangle } from "react-icons/fi";
import { PiEye, PiEyeClosed } from "react-icons/pi";

const LoginPc = ({ login, setLogin, senha, setSenha, mostrarSenha, setMostrarSenha, erro, onSubmit }) => {
    return (
        <div className="bg-white">
            <div className='border-b-2 border-gray-400'>
                <img src='/imagens/suapUNO.png' alt='logo' width={100} />
            </div>

            <div className="flex justify-center items-center h-screen">
                <div>
                    <div className='bg-white h-full'>
                        <form onSubmit={onSubmit}>
                            <div className="flex flex-col items-center p-5 rounded-xl bg-white">
                                <div>
                                    <p className="text-md ml-1 text-bold">Usuário:</p>
                                    <input type="text" value={login} onChange={(e) => setLogin(e.target.value)} className="border-2 border-black rounded-md  p-2 pr-12" />
                                </div>

                                <div>
                                    <p className="text-md ml-1 text-bold text">Senha:</p>
                                    <div className="flex flex-row">
                                        <input type={mostrarSenha ? "text" : "password"} value={senha} onChange={(e) => setSenha(e.target.value)} className="border-2 border-r-0 border-black rounded-l-md  p-2" />
                                        <button type="button" onClick={() => setMostrarSenha(!mostrarSenha)} className="border-2 border-black rounded-r-md  p-3">
                                            {mostrarSenha ? <PiEye /> : <PiEyeClosed />}
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-col justify-start w-full ml-3">
                                    <div>
                                        {erro && (
                                            <div className="flex flex-row items-center mt-2">
                                                <FiAlertTriangle className="text-red-500" />
                                                <p className="text-red-500 text-sm ml-1">Usuário ou senha inválidos</p>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className='flex items-center justify-center mx-20 mt-2'>
                                    <button type="submit" className="bg-[#359830] rounded-lg w-full p-3 text-white">
                                        Entrar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="bg-black h-20 -mt-10">.</div>
        </div>
    );
};

export default LoginPc;