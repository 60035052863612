import React, { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { PiPlus, PiTrash } from "react-icons/pi";

const ModalSimilares = ({ id_pergunta, fecharModal, itensPorPagina, remover, handleInputChangeOutro }) => {
    const [perguntas, setPerguntas] = useState([]);
    const [linkNext, setLinkNext] = useState('')
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [termoBusca, setTermoBusca] = useState('');
    const [novaPergunta, setNovaPergunta] = useState('');
    const token = localStorage.getItem('token');
    const handleFecharModal = (event) => {
        if (event.target === event.currentTarget) {
        fecharModal();
        }
    };

    useEffect(() => {
        const fetchSimilares = async () => {
            try {
                const perguntas = await fetch(`https://suapuno.app:8080/api/assistente-virtual/perguntas-similares/${id_pergunta}/`, {
                method: 'GET',
                headers: {
                    'Authorization': `Token ${token}`
                }
                });
                if (perguntas.ok) {
                const resposta = await perguntas.json();
                setPerguntas(resposta.results.reverse());
                setLinkNext(resposta.next);
                } else {
                }
            } catch (error) {
                console.log('Erro ao tentar capturar historico de chat.', error);
            }
        };
        fetchSimilares()
    }, [token, id_pergunta]);

    const itensFiltrados = perguntas.filter(item =>
        item.pergunta.toLowerCase().includes(termoBusca.toLowerCase())
      ).reverse();

    const adicionarItem = async () => {
        if (novaPergunta.trim()) {
        const novoItem = {
            pergunta: novaPergunta,
        };
        try {
            const response = await fetch(`https://suapuno.app:8080/api/assistente-virtual/perguntas-similares/${id_pergunta}/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
                body: JSON.stringify(novoItem)
            });
            if (!response.ok) {
                throw new Error('Erro na requisição: ' + response.statusText);
            } else {
                const perguntasReload = await fetch(`https://suapuno.app:8080/api/assistente-virtual/perguntas-similares/${id_pergunta}/`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });
                if (perguntasReload.ok) {
                    const resposta = await perguntasReload.json();
                    setPerguntas(resposta.results.reverse());
                    setLinkNext(resposta.next);
                    setNovaPergunta('');
                } else {
                    console.log('Api não retornou o ok:', perguntasReload.status);
                }
            }
        } catch (error) {
            console.error('Erro ao adicionar pergunta:', error);
        }
    }}

    const removerItem = (id_pergunta) => {
        remover(id_pergunta);
        setPerguntas(perguntas.filter(item => item.id_pergunta !== id_pergunta));
    }

    const handleInputChange = (id, field, value) => {
        setPerguntas(perguntas.map(item => item.id_pergunta === id ? {...item, [field]: value}: item))
        handleInputChangeOutro(id, field, value)
    };

    const paginasVisiveis = () => {
      const maisPerguntas = async () => {
        try {
          const perguntas = await fetch(linkNext, {
            method: 'GET',
            headers: {
              'Authorization': `Token ${token}`
            }
          });
          if (perguntas.ok) {
            const resposta = await perguntas.json();
            setPerguntas(prevPeticoes => [...resposta.results.reverse(), ...prevPeticoes])
            setLinkNext(resposta.next);
          } else {
            console.log('Api não retornou o ok:', perguntas.status);
          }
        } catch (error) {
          console.log('Erro ao tentar capturar historico de chat.', error);
        }
      };
  
      const paginas = [];
  
      if(paginaAtual - 1 > 0){
        paginas.push(paginaAtual - 1)
      }
      paginas.push(paginaAtual)
      if(itensUnicos.slice((paginaAtual - 1) * itensPorPagina, paginaAtual * itensPorPagina).length === itensPorPagina){
        paginas.push(paginaAtual + 1)
      } else {
        maisPerguntas()
      }
  
      return paginas;
    };
    
    const handleKeypress = (e, id_pergunta, resposta) => {
    if(e.key === 'Enter'){
        const itemAtualizado = perguntas.find(item => item.id_pergunta === id_pergunta)
        if(itemAtualizado){
        atualizaPergunta(itemAtualizado, id_pergunta, resposta);
        }
    }
    }

    const handleBlur = (id_pergunta, resposta) => {
    const itemAtualizado = perguntas.find(item => item.id_pergunta === id_pergunta)
    if(itemAtualizado){
        atualizaPergunta(itemAtualizado, id_pergunta, resposta);
    }
    }

    const atualizaPergunta = (itemAtualizado, id_pergunta, resposta) => {
    const novoItem = {
        pergunta: itemAtualizado.pergunta,
        resposta: itemAtualizado.resposta,
    };
    fetch(`https://suapuno.app:8080/api/assistente-virtual/pergunta-resposta/${id_pergunta}/`, {
        method: 'PUT',
        headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
        },
        body: JSON.stringify(novoItem)
    })
    .then(response => {
        if (!response.ok){
        throw new Error('Erro na requisição: '+ response.status);
        }
    })
    .catch(error => {
        console.log('Erro ao salvar alterações na pergunta.', error);
    })
    };

    // Função para filtrar itens duplicados
    const filtrarDuplicados = (itens) => {
        const idsVistos = new Set();
        return itens.filter(item => {
            if (idsVistos.has(item.id_pergunta)) {
                return false;
            } else {
                idsVistos.add(item.id_pergunta);
                return true;
            }
        });
    };

    // Aplica o filtro antes de mapear
    const itensUnicos = filtrarDuplicados(itensFiltrados);

  return (
    <div onClick={handleFecharModal} className='flex justify-center h-screen w-screen bg-[#00000093]'>
      <div className='flex-col bg-white p-5 w-full m-10 mx-[22%] rounded-lg'>
        <h1 className='text-4xl font-semibold text-center'>Perguntas/Temas similares</h1>
        <div className="flex flex-col w-full mt-5 relative">
            <input
                className="border border-gray-400 bg-gray-300 rounded-md w-full pl-10 py-1"
                placeholder="Busque por perguntas/temas similares..."
                value={termoBusca}
                onChange={(e) => {
                    setTermoBusca(e.target.value);
                    setPaginaAtual(1);
                }}
            />
          <span className="absolute top-2 left-2 text-gray-400">
            <FaSearch size={20} />
          </span>
        </div>
        <div className="mt-8">
          <div className="flex flex-row gap-2">
            <div className='relative border border-[#1e731d] bg-white rounded-md p-2 w-full'>
              <input
                type="text"
                className="w-full outline-none"
                value={novaPergunta}
                onChange={(e) => setNovaPergunta(e.target.value)}
              />
              <h1 className='absolute -top-3 left-2 bg-white p-1 text-xs text-[#1e731d]'>
                NOVA PERGUNTA/TEMA SIMILAR
              </h1>
            </div>
            <button
              className="bg-[#1e731d] text-white py-1 px-7 rounded-xl flex items-center gap-2 justify-center"
              onClick={adicionarItem}
            >
              <PiPlus size={20} />
            </button>
          </div>
        </div>
        <div className="mt-4 rounded-lg overflow-hidden border-[1px] border-[#1E731D] mx-10">
          <table className="w-full">
            <thead className="bg-[#1E731D] text-white">
              <tr>
                <th className="w-3/10 text-center py-5">Pergunta/Tema</th>
                <th className="w-1/10 text-center">Ações</th>
              </tr>
            </thead>
            <tbody>
            {itensUnicos.slice((paginaAtual - 1) * itensPorPagina, paginaAtual * itensPorPagina).map(item => (
              <tr key={item.id_pergunta} className='bg-gray-200'>
                <td>
                    <input
                        type='text'
                        value={item.pergunta}
                        onChange={(e) => handleInputChange(item.id_pergunta, 'pergunta', e.target.value)}
                        onKeyDown={(e) => handleKeypress(e, item.id_pergunta, item.resposta)}
                        onBlur={() => handleBlur(item.id_pergunta, item.resposta)}
                        className='text-center py-2 truncate w-full'
                    />
                </td>
                <td className="text-center py-2">
                  <button className="text-red-500 mx-1">
                    <PiTrash onClick={() => {removerItem(item.id_pergunta)}} color='white' className='bg-[#a30002] rounded-full p-1' size={25} />
                  </button>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-center mt-4 gap-2 -ml-12">
            {paginaAtual > 2 && (
                <div className=' flex'>
                    <button onClick={() => setPaginaAtual(1)} className={`px-3 py-1 rounded-full bg-gray-400`}>{1}</button>
                    <h1 className='ml-2 mt-2 font-semibold'>. . .</h1>
                </div>
            )}
            {paginasVisiveis().map((pagina, index) => (
                <button key={index} onClick={() => setPaginaAtual(pagina)} className={`px-3 py-1 rounded-full ${pagina === paginaAtual ? 'bg-green-600 text-white' : 'bg-gray-400'}`}>{pagina}</button>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ModalSimilares;