import { PiTableLight, PiFiles, PiSparkle } from "react-icons/pi";
import { FaRegHandPaper } from "react-icons/fa";
import { ImExit } from "react-icons/im";
import { useNavigate, useLocation } from "react-router-dom";

const menuItem = "flex items-center gap-3 mb-2 cursor-pointer";
const menuItemTexto = "text-white text-sm";
const menuItemAtivo = "text-green-700 text-sm";

const Menu = () => {
  let perfis = JSON.parse(localStorage.getItem('perfis') || '[]');
  perfis = perfis.map(perfil => perfil.toLowerCase());
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = (path) => location.pathname.includes(path);

  return (
    <div className="fixed bottom-0 left-0 w-full bg-[#424242] py-3 z-50">
      <div className="flex items-center justify-around">
        {perfis.includes('professor') && (
          <div 
            className={`${menuItem} ${isActive('/sua-presenca') ? menuItemAtivo : menuItemTexto}`} 
            onClick={() => navigate('/sua-presenca')}
            aria-label="Presenca"
          >
            <FaRegHandPaper size={20} color={isActive('/sua-presenca') ? "green" : "white"} />
            <h2 className={isActive('/sua-presenca') ? menuItemAtivo : menuItemTexto}>Presenca</h2>
          </div>
        )}
        {(perfis.includes('aluno') || perfis.includes('professor')) && (
          <div 
            className={`${menuItem} ${isActive('/sua-progressao') ? menuItemAtivo : menuItemTexto}`} 
            onClick={() => navigate('/sua-progressao')}
            aria-label="Progressao"
          >
            <PiTableLight size={20} color={isActive('/sua-progressao') ? "green" : "white"} />
            <h2 className={isActive('/sua-progressao') ? menuItemAtivo : menuItemTexto}>Progressao</h2>
          </div>
        )}
        {(perfis.includes('aluno') || perfis.includes('assistente')) && (
          <div 
            className={`${menuItem} ${isActive('/sua-peticao') ? menuItemAtivo : menuItemTexto}`} 
            onClick={() => navigate('/sua-peticao')}
            aria-label="Peticao"
          >
            <PiFiles size={20} color={isActive('/sua-peticao') ? "green" : "white"} />
            <h2 className={isActive('/sua-peticao') ? menuItemAtivo : menuItemTexto}>Peticao</h2>
          </div>
        )}
        {(perfis.includes('aluno') || perfis.includes('professor')) && (
          <div 
            className={`${menuItem} ${isActive('/chat') ? menuItemAtivo : menuItemTexto}`} 
            onClick={() => navigate('/chat')}
            aria-label="Suapinho"
          >
            <PiSparkle size={20} color={isActive('/chat') ? "green" : "white"} />
            <h2 className={isActive('/chat') ? menuItemAtivo : menuItemTexto}>Suapinho</h2>
          </div>
        )}
        {localStorage.getItem('multiPerfil') && (
          <div 
            className={`${menuItem}`} 
            onClick={() => navigate('/menu')}
            aria-label="Trocar perfil"
          >
            <ImExit color="green"/>
            <h2 className={menuItemTexto}>Trocar perfil</h2>
          </div>  
        )}      
      </div>
    </div>
  );
}

export default Menu;
