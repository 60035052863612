import React, { useState, useEffect } from 'react';
import { PiPlusLight, PiTrash, PiCheck } from "react-icons/pi";

const MenuModal = ({ aberto, selecionarItem }) => (
  aberto && (
    <ul className="p-2 -m-4 mt-1 mb-5 rounded-lg w-30 shadow-[0px_0px_10px_7px_rgba(0,0,0,0.2)] font-bold">
      {["Informações gerais e/ou custos do evento", "Dados pessoais", "Dados bancários"].map(item => (
        <li key={item} className='mb-1' onClick={() => selecionarItem(item)} style={{ cursor: 'pointer' }}>
          {item}
        </li>
      ))}
    </ul>
  )
);

const ItensSelecionados = ({ itensSelecionados, removeItem }) => (
  <ul>
    {itensSelecionados.map((item, index) => (
      <li className='flex justify-between item mb-2 w-full' key={index}>
        {item}
        <button onClick={() => removeItem(index)}> <PiTrash size={20} color='red' className='-mt-1'/> </button>
      </li>
    ))}
  </ul>
);

//esse aqui é roda quando tem itens selecionados para correção
const SecaoSelecionados = ({ itensSelecionados, aoSelecionar, peticaoId, token, onClose, idUser, idBanco }) => {
  const [comentario, setComentario] = useState('');

  const enviarVerificacao = (url, body) => {
    return fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Erro ao processar a solicitação');
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  const atualizarVerificacoes = () => {
    const initialState = {
      verificado_evento: true,
      verificado_comprovante_inscricao: true,
      verificado_comprovante_programacao: true,
      verificado_dados_pessoais: true,
      verificado_dados_bancarios: true,
    };

    const updatedState = { ...initialState };

    const promises = itensSelecionados.map(item => {
      switch (item) {
        case "Informações gerais e/ou custos do evento":
          updatedState.verificado_evento = false;
          break;
        case "Comprovante de inscrição":
          updatedState.verificado_comprovante_inscricao = false;
          break;
        case "Programação do evento":
          updatedState.verificado_comprovante_programacao = false;
          break;
        case "Dados bancários":
          updatedState.verificado_dados_bancarios = false;
          return enviarVerificacao(`https://suapuno.app:8080/api/dados-bancarios/${idBanco}/`, { verificado: false });
        case "Dados pessoais":
          updatedState.verificado_dados_pessoais = false;
          return enviarVerificacao(`https://suapuno.app:8080/api/usuarios/${idUser}/`, { aluno: { verificado: false } });
        default:
          break;
      }
      return Promise.resolve();
    });

    return Promise.all(promises).then(() => updatedState);
  };

  const handleEnviarParaCorrecao = () => {
    atualizarVerificacoes()
      .then(updatedState => {

        return fetch(`https://suapuno.app:8080/api/peticao/${peticaoId}/`, {
          method: 'PUT',
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...updatedState,
            comentario: comentario,
          }),
        });
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Erro ao processar a solicitação');
        }
        aoSelecionar(itensSelecionados.join(", "));
        onClose();
        window.location.reload();
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  return (
    <div>
      <h1 className='bg-[#E82F32] py-1 px-20 rounded-lg text-white mt-3 text-center'>Feedback do aluno</h1>
      <textarea
        className="mt-2 mb-1 p-2 w-full bg-[#DDDDDD] rounded-lg"
        style={{ resize: 'none' }}
        value={comentario}
        onChange={(e) => setComentario(e.target.value)}
      ></textarea>
      <div className='flex flex-row items-end justify-end'>
        <button onClick={handleEnviarParaCorrecao} className='text-white bg-green-800 px-3 py-2 rounded-lg mb-4'>Solicitar correções</button>
      </div>
    </div>
  );
};

//esse aqui roda quando não tem itens selecionados para correção
const ProcessarSelecao = ({ aoSelecionar, peticaoId, token, idTipo, idModaliade, onClose, idBanco, idUser, idAluno }) => { 
  const [numeroProcesso, setNumeroProcesso] = useState('');

  const handleInputChange = (e) => {
    setNumeroProcesso(e.target.value);
  };

  const handleNaoContemplar = () => { // especifico para o botão de não contemplar
    fetch(`https://suapuno.app:8080/api/peticao/${peticaoId}/`, {
      method: 'PUT',
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id_tipo_auxilio: idTipo,
        id_modalidade_auxilio: idModaliade,
        nao_contemplado: true,
        numero_processo: numeroProcesso
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Erro ao processar a solicitação');
      }
      aoSelecionar("");
      onClose();
      window.location.reload();
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  const handleContemplar = () => { // especifico para o botão de contemplar
    
    fetch(`https://suapuno.app:8080/api/dados-bancarios/${idBanco}/`, {
      method: 'PUT',
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        verificado: true 
      })
    });
    
    fetch(`https://suapuno.app:8080/api/usuarios/${idUser}/`, {
      method: 'PUT',
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        aluno: { verificado: true } 
      })
    });

    fetch(`https://suapuno.app:8080/api/peticao/${peticaoId}/`, {
      method: 'PUT',
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        verificado_evento: true,
        verificado_comprovante_inscricao: true,
        verificado_comprovante_programacao: true,
        verificado_dados_pessoais: true,
        verificado_dados_bancarios: true,
        contemplar: true,
        numero_processo: numeroProcesso
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Erro ao processar a solicitação');
      }
      aoSelecionar("");
      onClose();
      window.location.reload();
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  return (
    <div>
      <h1 className='mt-3 font-bold'>Número de processo SEI </h1>
      <h1 className='italic text' style={{ lineHeight: '1.1' }}>
        Usaremos como número de referência<br />caso seja necessário devolver o valor via GRU
      </h1>
      <input
        className='border border-gray-400 bg-gray-300 rounded-md w-full pl-1 py-1 mt-2'
        value={numeroProcesso}
        onChange={handleInputChange}
      />
      <div className='border-b border-gray-300 -mx-2 my-4'></div>
      <div className='flex flex-row items-end justify-end'>
        <button onClick={handleNaoContemplar} className='text-white bg-green-800 px-3 py-2 rounded-lg mb-4 mr-3'>Não contemplar</button>
        {numeroProcesso ? 
          (<button onClick={handleContemplar} className='text-white bg-green-800 px-3 py-2 rounded-lg mb-4'>Contemplar</button>) :
          (<button onClick={handleContemplar} className='text-white bg-green-800 opacity-50 px-3 py-2 rounded-lg mb-4' disabled={true}>Contemplar</button>)
        }
      </div>
    </div>
  );
};

const CorrecoesFeedback = ({ correcoesFeedback, aoSelecionar, peticaoId, idTipo, idModaliade, onClose, idUser, idAluno, idBanco }) => {
  const [aberto, setAberto] = useState(false);
  const [itensSelecionados, setItensSelecionados] = useState([]);
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (correcoesFeedback && correcoesFeedback.includes("Comprovante de inscrição") && !itensSelecionados.includes("Comprovante de inscrição")) {
      setItensSelecionados(prevItems => [...prevItems, "Comprovante de inscrição"]);
    }
    if (correcoesFeedback && correcoesFeedback.includes("Programação do evento") && !itensSelecionados.includes("Programação do evento")) {
      setItensSelecionados(prevItems => [...prevItems, "Programação do evento"]);
    }
    if (correcoesFeedback && correcoesFeedback.includes("Dados bancários") && !itensSelecionados.includes("Dados bancários")) {
      setItensSelecionados(prevItems => [...prevItems, "Dados bancários"]);
    }
  }, [correcoesFeedback, itensSelecionados]);

  const toggleMenuModal = () => {
    setAberto(!aberto);
  };

  const selecionarItem = (item) => {
    if (!itensSelecionados.includes(item)) {
      const updatedList = [...itensSelecionados, item];
      setItensSelecionados(updatedList);
      aoSelecionar(updatedList);
    }
    setAberto(false);
  };

  const removeItem = (index) => {
    const newList = itensSelecionados.filter((_, i) => i !== index);
    setItensSelecionados(newList);
    aoSelecionar(newList);
  };

  return (
    <div className='flex flex-col bg-white h-auto my-3 pt-5 px-7 rounded-lg shadow-[0px_0px_10px_7px_rgba(0,0,0,0.2)]'>
      <div>
        <h1 className='bg-[#E82F32] py-1 px-10 rounded-lg text-white text-center'>Informações a serem corrigidas</h1>
        <button onClick={toggleMenuModal} className='flex justify-between item font-bold mt-3 mb-2 w-full'>
          Selecionar uma correção <PiPlusLight className='-mt-1 -mr-[3px]' size={30} />
        </button>
        <MenuModal aberto={aberto} selecionarItem={selecionarItem} />
        {!aberto && <ItensSelecionados itensSelecionados={itensSelecionados} removeItem={removeItem} />}
        {itensSelecionados.length === 0 && (
          <div className='flex flex-row items-center justify-center pt-2 mb-6'>
            <PiCheck size={45} color='gray' />
            <span className='text-gray-500'>Todos os dados foram<br /> informados corretamente</span>
          </div>
        )}
      </div>
      <div className='border-b border-gray-300 -mx-2'></div>
      {itensSelecionados.length !== 0 ? (
        <SecaoSelecionados  itensSelecionados={itensSelecionados} aoSelecionar={aoSelecionar} peticaoId={peticaoId} token={token} idTipo={idTipo} idModaliade={idModaliade} onClose={onClose} idUser={idUser} idAluno={idAluno} idBanco={idBanco} />
      ) : (
        <ProcessarSelecao aoSelecionar={aoSelecionar} peticaoId={peticaoId} token={token} idTipo={idTipo} idModaliade={idModaliade} onClose={onClose} idUser={idUser} idAluno={idAluno} idBanco={idBanco} />
      )}
    </div>
  );
};

export default CorrecoesFeedback;