import React, { useEffect, useRef } from 'react';

const Card = ({ icone, titulo, conteudo, redondoTopo, blockMovimento }) => {
  const touchStartX = useRef(0);
  const touchStartY = useRef(0);

  useEffect(() => {
    const handleTouchStart = (e) => {
      touchStartX.current = e.touches[0].clientX;
      touchStartY.current = e.touches[0].clientY;
    };

    const handleTouchMove = (e) => {
      if (!blockMovimento) {
        return;
      }

      const touchCurrentX = e.touches[0].clientX;
      const touchCurrentY = e.touches[0].clientY;

      const deltaX = Math.abs(touchCurrentX - touchStartX.current);
      const deltaY = Math.abs(touchCurrentY - touchStartY.current);

      if (deltaX > deltaY) {
        e.preventDefault();
      }
    };

    const cardWrappers = document.querySelectorAll('.card-wrapper');

    cardWrappers.forEach((cardWrapper) => {
      cardWrapper.addEventListener('touchstart', handleTouchStart, { passive: true });
      cardWrapper.addEventListener('touchmove', handleTouchMove, { passive: false });
    });

    return () => {
      cardWrappers.forEach((cardWrapper) => {
        cardWrapper.removeEventListener('touchstart', handleTouchStart);
        cardWrapper.removeEventListener('touchmove', handleTouchMove);
      });
    };
  }, [blockMovimento]);

  const cardClassName = `w-full p-4 mb-4 bg-white shadow-md card-wrapper ${redondoTopo === false ? 'rounded-tr-lg rounded-b-lg' : 'rounded-lg'}`;

  return (
    <div className={cardClassName}>
      <div className="flex items-center">
        <div className="mr-1">{icone}</div>
        {React.isValidElement(titulo) ? (
          titulo
        ) : (
          <h2 className="text-lg font-bold">{titulo}</h2>
        )}
      </div>
      <div className="mt-4">
        {conteudo}
      </div>
    </div>
  );
};

export default Card;
