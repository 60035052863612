import React, { useState } from 'react';
import { PiStudent } from "react-icons/pi";
import { PiCircle, PiSpinner, PiCurrencyCircleDollar, PiX, PiCheck, PiDownloadSimple, PiPaperPlaneRight, PiBarcode } from "react-icons/pi";
import { TbExclamationMark } from "react-icons/tb";
import { PiPencilSimpleLine } from "react-icons/pi";
import { useNavigate } from "react-router-dom";

const CardPeticao = ({ data }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [expandido, setExpandido] = useState(false);
  const [comprovanteParticipacao, setComprovanteParticipacao] = useState(null);

  const handleAbrirClick = (peticao) => {

    console.log(peticao.visualizado_aluno)

    if(peticao.visualizado_aluno === false){
      fetch(`https://suapuno.app:8080/api/peticao/${peticao.id}/`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        },
        body: JSON.stringify({visualizado_aluno: true}),
      })
      .then(response => {
          if (!response.ok) {
              throw new Error('Erro na requisição: ' + response.statusText);
          }
      })
      .catch(error => {
          console.error('Erro:', error);
      });
    }

    setExpandido(!expandido)
    peticao.visualizado_aluno = true;
  };

  const handleCorrections = async () => {
    try {
      const response = await fetch(`https://suapuno.app:8080/api/peticao/${data.id}/`, {
        method: 'GET',
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'application/json',
        }
      });
      const responseData = await response.json();
  
      localStorage.setItem('peticao', JSON.stringify(responseData));
      localStorage.setItem('modo_edicao', true);
  
      const dadosEvento = JSON.parse(localStorage.getItem('peticao'));
  
      const comprovanteResponse = await fetch(dadosEvento.comprovante_inscricao);
      const comprovanteData = await comprovanteResponse.blob();
  
      const comprovanteResponse2 = await fetch(dadosEvento.comprovante_programacao);
      const comprovanteData2 = await comprovanteResponse2.blob();
  
      const reader = new FileReader();
      reader.readAsDataURL(comprovanteData);
      reader.onloadend = function() {
        let arquivo1 = reader.result;
        arquivo1 = arquivo1.replace(/^data:application\/pdf;base64,/, ''); // Remove o prefixo
        localStorage.setItem('arquivo1', arquivo1);
      };
  
      const reader2 = new FileReader();
      reader2.readAsDataURL(comprovanteData2);
      reader2.onloadend = function() {
        let arquivo2 = reader2.result;
        arquivo2 = arquivo2.replace(/^data:application\/pdf;base64,/, ''); // Remove o prefixo
        localStorage.setItem('arquivo2', arquivo2);
      };

      localStorage.setItem('id_peticao', dadosEvento.id)
      localStorage.setItem('dados_peticao_verificado', dadosEvento.evento.verificado)
      localStorage.setItem('comprovante_incricao_verificado', dadosEvento.verificado_comprovante_incricao)
      localStorage.setItem('comprovante_programacao_verificado', dadosEvento.verificado_comprovante_programacao)
  
      navigate('/sua-petição/nova');
    } catch (error) {
      console.error('Erro ao fazer chamada de api', error);
    }
  };  

  const handleComprovanteParticipa = (event) => {
    setComprovanteParticipacao(event.target.files[0]);
    console.log(event.target.files[0])
  }

  const comprovarParticipacao = async () => {
    if (comprovanteParticipacao) {
      
      const formData = new FormData();
      formData.append('comprovante_participacao', comprovanteParticipacao);
  
      try {
        const reader = new FileReader();
        reader.readAsDataURL(comprovanteParticipacao);
        reader.onloadend = async function() {
          const base64File = reader.result.split(',')[1]; // Ignorar a parte do cabeçalho
  
          const response = await fetch(`https://suapuno.app:8080/api/peticao/${data.id}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
            body: JSON.stringify({ comprovante_participacao: base64File }),
          });
  
          if (!response.ok) {
            throw new Error('Erro na requisição: ' + response.statusText);
          }

          window.location.reload();
        };
      } catch (error) {
        console.error('Erro:', error);
        
      }
    }
  }
  
  const handleComprovantePagamento = (event) => {
    const arquivo = event.target.files[0];
    if (arquivo) {
      comprovarPagamento(arquivo);
    }
  };
  
  const comprovarPagamento = async (comprovantePagamento) => {
    if (comprovantePagamento) {
      console.log('Comprovante de pagamento:', comprovantePagamento);
      console.log('ID da petição:', data.id);
      try {
        const formData = new FormData();
        formData.append('comprovante_pagamento_gru', comprovantePagamento);
  
        const reader = new FileReader();
  
        reader.onloadend = async function () {
          if (reader.result) {
            const base64File = reader.result.split(',')[1]; // Ignorar a parte do cabeçalho
  
            const response = await fetch(`https://suapuno.app:8080/api/peticao/${data.id}/`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
              },
              body: JSON.stringify({ comprovante_pagamento_gru: base64File }),
            });
  
            if (!response.ok) {
              throw new Error('Erro na requisição: ' + response.statusText);
            }
  
            window.location.reload();
          } else {
            console.error('O resultado do leitor de arquivos é nulo.');
          }
        };
  
        reader.readAsDataURL(comprovantePagamento);
      } catch (error) {
        console.error('Erro:', error);
      }
    } else {
      console.error('Nenhum arquivo selecionado para comprovar pagamento.');
    }
  };  

  const handleDownload = async () => {
    const url = data.status[data.status.length - 1].link_gru;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf'
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = downloadUrl;
      a.download = 'BOLETO_GRU.pdf';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Erro ao realizar o download do comprovante:', error);
    }
  };

  // Limitar o comprimento do texto da modalidade
  const modalidadeTexto =
    data.modalidade.nome.length > 28
      ? `${data.modalidade.nome.substring(0, 28)}...`
      : data.modalidade.nome;

  // Determinar a cor do texto com base no status
  let statusCor = '';
  switch (data.status[data.status.length - 1].nome_status) {
    case 'Em análise':
      statusCor = 'text-yellow-500';
      break;
    case 'Prestação de contas':
      statusCor = 'text-yellow-500';
      break;
    case 'Análise de prestação de contas':
      statusCor = 'text-yellow-500';
      break;
    case 'Aguardando pagamento da GRU':
      statusCor = 'text-yellow-500';
      break;
    case 'Análise de pagamento da GRU':
      statusCor = 'text-yellow-500';
      break;
    case 'Não contemplado':
      statusCor = 'text-red-500';
      break;
    case 'Aguardando correções':
        statusCor = 'text-red-500';
        break;
    case 'Contemplado':
      statusCor = 'text-green-500';
      break;
    case 'Concluído':
      statusCor = 'text-green-500';
      break;
    default:
      statusCor = 'text-yellow-500';
  }

  // Função para obter o ícone com base no status
  const getStatusIcon = (status) => {
    switch (status) {
      case 'Em análise':
        return (
            <>
                <PiCircle size={50} color="orange" className='z-30'/>
                <PiSpinner size={28} color="orange" className='absolute -mt-[39px] ml-[11px]'/>
                <div className='bg-white -mt-12 h-12 w-12 '></div>
            </>
        )
      case 'Prestação de contas':
        return (
            <>
                <PiCircle size={50} color="orange" className='z-30'/>
                <PiCurrencyCircleDollar size={28} color="orange" className='absolute -mt-[39px] ml-[11px]'/>
                <div className='bg-white -mt-12 h-12 w-12 '></div>
            </>
        )
      case 'Análise de prestação de contas':
        return (
            <>
                <PiCircle size={50} color="orange" className='z-30'/>
                <PiCurrencyCircleDollar size={28} color="orange" className='absolute -mt-[39px] ml-[11px]'/>
                <div className='bg-white -mt-12 h-12 w-12 '></div>
            </>
        )
        case 'Aguardando pagamento da GRU':
          return (
              <>
                  <PiCircle size={50} color="orange" className='z-30'/>
                  <PiCurrencyCircleDollar size={28} color="orange" className='absolute -mt-[39px] ml-[11px]'/>
                  <div className='bg-white -mt-12 h-12 w-12 '></div>
              </>
          )
        case 'Análise de pagamento da GRU':
          return (
              <>
                  <PiCircle size={50} color="orange" className='z-30'/>
                  <PiCurrencyCircleDollar size={28} color="orange" className='absolute -mt-[39px] ml-[11px]'/>
                  <div className='bg-white -mt-12 h-12 w-12 '></div>
              </>
          )
        case 'Não contemplado':
          return (
              <>
                  <PiCircle size={50} color="black" className='z-30'/>
                  <PiX size={28} color="black" className='absolute -mt-[39px] ml-[11px]'/>
                  <div className='bg-white -mt-12 h-12 w-12 '></div>
              </>
          )
        case 'Aguardando correções':
          return (
              <>
                  <PiCircle size={50} color="red" className='z-30'/>
                  <TbExclamationMark size={28} color="red" className='absolute -mt-[39px] ml-[11px]'/>
                  <div className='bg-white -mt-12 h-12 w-12 '></div>
              </>
          )
        case 'Concluído':
          return (
              <>
                  <PiCircle size={50} color="green" className='z-30'/>
                  <PiCheck size={28} color="green" className='absolute -mt-[39px] ml-[11px]'/>
                  <div className='bg-white -mt-12 h-12 w-12 '></div>
              </>
          )
        case 'Contemplado':
          return (
              <>
                  <PiCircle size={50} color="green" className='z-30'/>
                  <PiCheck size={28} color="green" className='absolute -mt-[39px] ml-[11px]'/>
                  <div className='bg-white -mt-12 h-12 w-12 '></div>
              </>
          )
      default:
        return <PiSpinner size={28} />;
    }
  };

  return (
    <div
      className={`flex flex-col w-full h-full bg-white p-4 rounded-lg shadow-[0px_1px_10px_3px_rgba(0,0,0,0.2)] ${expandido ? 'py-5' : ''}`}
      style={{ position: 'relative' }}
    >
      <div className="text-center flex flex-col">
        {data.visualizado_aluno === false ? <div className="absolute -top-2 right-3 bg-red-500 text-white rounded-full h-6 w-6 flex justify-center items-center">!</div> : <></>}
        <div onClick={() => handleAbrirClick(data)} className="text-center flex flex-row">
          <PiStudent size={50} />
          <div className="flex flex-col justify-start items-start">
            <h2 className="font-bold">{data.nome}</h2>
            <h2 className="text-xs">
              Modalidade: <span>{modalidadeTexto}</span>
            </h2>
            <h2 className="text-xs">
              Data de geração: <span>{data.status[0].data}</span>
            </h2>
            <h2 className={`text-xs ${statusCor} mb-1`}>
              • <span>{data.status[data.status.length - 1].nome_status}</span>
            </h2>
          </div>
        </div>
        {expandido && (
        <div>
            {data.status.map((status, index) => (
            <div key={index} className="flex flex-row items-center">
                <div className="relative">
                {getStatusIcon(status.nome_status)}
                </div>
                <div className="flex flex-col justify-start items-start text-xs ml-2">
                {status.comentario ? (
                    <div className='text-start mt-1 mb-1'>
                        <h2 className='font-bold'>{status.nome_status}</h2>
                        <h2 className='text-gray-500'>Data: {status.data.split(' ')[0]}</h2>
                        <h2 className='text-gray-500'>Hora: {status.data.split(' ')[1]}</h2>
                        <textarea 
                            className='p-3 bg-gray-300 rounded-lg mt-1 w-[250px] h-16 resize-none' 
                            defaultValue={status.comentario}
                        />
                    </div>
                ) : (
                    <>
                        <h2 className='font-bold'>{status.nome_status}</h2>
                        <h2 className='text-gray-500'>Data: {status.data.split(' ')[0]}</h2>
                        <h2 className='text-gray-500'>Hora: {status.data.split(' ')[1]}</h2>
                    </>
                )}
                </div>
            </div>
            ))}
            {data.status[data.status.length - 1].nome_status === 'Aguardando correções' && (
                <button
                onClick={handleCorrections}
                className={`bg-red-500 text-white px-4 py-2 rounded w-[250px] flex ml-[58px] flex-row items-center justify-center ${
                    data.status[data.status.length - 1].comentario == null || data.status[data.status.length - 1].comentario === "" ? 'mt-1' : '-mb-2'
                }`}
                >
                    <PiPencilSimpleLine />
                    Realizar correções
                </button>
            )}
            {expandido && data.status[data.status.length - 1].nome_status === 'Prestação de contas' && (
              <div className="flex flex-col ml-[58px] mt-1">
                  <div>
                    <input
                      type="file"
                      id="comprovanteParticipacao"
                      onChange={handleComprovanteParticipa}
                      style={{ display: 'none' }}
                    />
                    <label
                      htmlFor="comprovanteParticipacao"
                      className="bg-[#a99a18] text-white px-4 py-2 rounded w-[250px] flex flex-row items-center justify-center"
                    >
                      <PiDownloadSimple />
                      <h1 className='ml-2'>Comprovar participação</h1>
                    </label>
                  </div>
                {comprovanteParticipacao && (
                  <button
                    id="enviarComprovanteParticipacao"
                    onClick={comprovarParticipacao}
                    className={`bg-[#a99b18] text-white px-4 py-2 rounded w-[250px] flex flex-row items-center justify-center mt-1`}
                  >
                    <PiPaperPlaneRight />
                    <h1 className='ml-2'>Enviar comprovante</h1>
                  </button>
                )}
              </div>
            )}
            {data.status[data.status.length - 1].nome_status === 'Aguardando pagamento da GRU' && (
              <div className="flex flex-col ml-[58px] mt-1">
                  <div>
                    <button
                      onClick={handleDownload}
                      className="bg-[#c2b53e] text-white px-4 py-2 rounded w-[250px] flex flex-row items-center justify-center"
                    >
                      <PiBarcode />
                      <h1 className='ml-2'>Baixar GRU</h1>
                    </button>
                  </div>
                  <input
                      type="file"
                      id="comprovantePagamento"
                      onChange={handleComprovantePagamento}
                      style={{ display: 'none' }}
                    />
                    <label
                      htmlFor="comprovantePagamento"
                      className="bg-[#a99a18] text-white px-4 py-2 rounded w-[250px] flex flex-row items-center justify-center mt-1"
                    >
                      <PiPaperPlaneRight />
                      <h1 className='ml-2'>Enviar comprovante</h1>
                    </label>
              </div>
            )}
        </div>
        )}
      </div>
    </div>
  );
};

export default CardPeticao;