import Menu from '../components/ui/menu';
import DisciplinasProfessor from './professor/suaProgresao/Disciplinas';
import DisciplinasAluno from './aluno/suaProgressao/Disciplinas';

const Progressao = () => {
  let perfis = JSON.parse(localStorage.getItem('perfis') || '[]');
  perfis = perfis.map(perfil => perfil.toLowerCase());

  return (
    <div className="flex flex-col min-h-screen bg-[#e5e4e4] relative">
      <main className="flex-grow mb-10">
        {perfis.includes('professor') ? (<DisciplinasProfessor />) : (<DisciplinasAluno />)}
      </main>
      <footer className='z-10'>
        <Menu />
      </footer>
    </div>
  );
};

export default Progressao;
