import React, { useState, useEffect } from "react";
import { CiWarning } from "react-icons/ci";
import { useNavigate } from 'react-router-dom';
import { PiFilePdf } from "react-icons/pi";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import { LuCalendarDays } from "react-icons/lu";
import CurrencyInput from 'react-currency-input-field';
import Calendario from "../../../components/ui/calendario";

const PeticaoNova = () => {
    const [arquivoSelecionado, setArquivoSelecionado] = useState('');
    const [segundoArquivoSelecionado, setSegundoArquivoSelecionado] = useState(null);
    const [showDatePickerPopup, setShowDatePickerPopup] = useState(false);
    const navigate = useNavigate();
    const [formulario, setFormulario] = useState({
        nome: "",
        data_inicial: null,
        data_final: null,
        custo_inscricao: "",
        custo_hospedagem: "",
        custo_alimentacao: "",
        custo_deslocamento: "",
        custoDiarioOutros: "",
        outrosGastosDescricao: ""
    });

    useEffect(() => {
        const dadosEventoLocalStorage = localStorage.getItem('peticao');
        if (dadosEventoLocalStorage) {
            const dadosEvento = JSON.parse(dadosEventoLocalStorage);
            if (dadosEvento.evento) { 
                setFormulario(prevState => ({
                    ...prevState,
                    nome: dadosEvento.evento.nome || "",
                    data_inicial: dadosEvento.evento.data_inicial ? new Date(dadosEvento.evento.data_inicial) : null,
                    data_final: dadosEvento.evento.data_final ? new Date(dadosEvento.evento.data_final) : null,
                    custo_inscricao: parseFloat(dadosEvento.evento.custo_inscricao).toFixed(2).replace('.', ','),
                    custo_hospedagem: parseFloat(dadosEvento.evento.custo_hospedagem).toFixed(2).replace('.', ','),
                    custo_alimentacao: parseFloat(dadosEvento.evento.custo_alimentacao).toFixed(2).replace('.', ','),
                    custo_deslocamento: parseFloat(dadosEvento.evento.custo_deslocamento).toFixed(2).replace('.', ','),
                    custoDiarioOutros: dadosEvento.outros_gastos.length > 0 && typeof parseFloat(dadosEvento.outros_gastos[0].valor) === 'number' ? parseFloat(dadosEvento.outros_gastos[0].valor).toFixed(2).replace('.', ',') : "",
                    outrosGastosDescricao: dadosEvento.outros_gastos.length > 0 ? dadosEvento.outros_gastos[0].descricao : ""
                }));
                if (localStorage.getItem('arquivo1')) {
                    setArquivoSelecionado(base64ParaFile(localStorage.getItem('arquivo1'), 'Comprovante de inscrição'));
                }             
                if (localStorage.getItem('arquivo2')) {
                    setSegundoArquivoSelecionado(base64ParaFile(localStorage.getItem('arquivo2'), 'Progamação do evento'));
                }                          
            }            
        }
      }, []);


    const handleInputValor = (event) => {
        const { name, value } = event.target;
        setFormulario(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSelecionarData = (startDate, endDate) => {
        setFormulario(prevState => ({
            ...prevState,
            data_inicial: startDate,
            data_final: endDate
        }));
    };

    const mostrarCalendario = () => {
        setShowDatePickerPopup(!showDatePickerPopup);
    };

    const calcularCustoTotal = () => {
        const { custo_inscricao, custo_hospedagem, custo_alimentacao, custo_deslocamento, custoDiarioOutros, data_inicial, data_final } = formulario;
    
        const dias = data_final && data_inicial ? Math.ceil((new Date(data_final).getTime() - new Date(data_inicial).getTime()) / (1000 * 3600 * 24)) : 0;
    
        const custos = [custo_inscricao, custo_hospedagem, custo_alimentacao, custo_deslocamento, custoDiarioOutros];
    
        const totalCusto = custos.reduce((acc, custo) => {
            if (custo) {
                custo = custo.replace('.', '');
                custo = custo.replace(',', '.');
                const valor = parseFloat(custo);
                return isNaN(valor) ? acc : acc + valor * dias;
            }
            return acc;
        }, 0);
    
        return totalCusto;
    };
    

    const handleAlterarArquivo = (event) => {
        const file = event.target.files[0];
        setArquivoSelecionado(file);
    };

    const removerArquivoSelecionado = () => {
        setArquivoSelecionado(null);
    };

    const handleSegundoFileChange = (event) => {
        const file = event.target.files[0];
        setSegundoArquivoSelecionado(file);
    };

    const removerSegundoArquivoSelecionado = () => {
        setSegundoArquivoSelecionado(null);
    };

    const btnCancelar = () => {
        localStorage.removeItem('peticao');
        localStorage.removeItem('arquivo1');
        localStorage.removeItem('arquivo2');
        localStorage.removeItem('modo_edicao');
        navigate('/sua-petição', { replace: true });
        
        window.location.reload();
    };

    const btnSalvar = async () => {
        if (Object.values(formulario).some(value => value === null) || arquivoSelecionado === null || segundoArquivoSelecionado === null) {
            alert('Por favor, preencha todos os campos obrigatórios e selecione os documentos.');
            return;
        }
    
        const { nome, data_inicial, data_final, custo_inscricao, custo_hospedagem, custo_alimentacao, custo_deslocamento, custoDiarioOutros, outrosGastosDescricao } = formulario;
    
        // Formatando as datas para o formato YYYY-MM-DD
        const data_inicialFormatada = data_inicial.toISOString().split('T')[0];
        const data_finalFormatada = data_final.toISOString().split('T')[0];
    
        // Formatando outros gastos
        let outrosGastos = {}; 
    
        if (custoDiarioOutros !== "" && outrosGastosDescricao !== "") {
            outrosGastos = { 
                valor: parseFloat(custoDiarioOutros.replace('.', '').replace(',', '.')),
                descricao: outrosGastosDescricao
            };
        } else {
            // Adiciona valor padrão se nenhum valor foi inserido
            outrosGastos = {
                valor: 0,
                descricao: "Nenuma descrição fornecida"
            };
        }
    
        const primeiroArquivoBase64 = await convertToBase64(arquivoSelecionado);
        const segundoArquivoBase64 = await convertToBase64(segundoArquivoSelecionado);
    
        const peticaoFormatada = {
            id_tipo_auxilio: 1, // definir ainda isso dos tipos de auxilio e modalidade pegando da api, talvez nem precise
            id_modalidade_auxilio: 1, // cada tela dessa vai ser customizada para cada tipo de auxilio mesmo
            evento: {
                nome,
                data_inicial: data_inicialFormatada,
                data_final: data_finalFormatada,
                custo_deslocamento: parseFloat(custo_deslocamento.replace('.', '').replace(',', '.')),
                custo_inscricao: parseFloat(custo_inscricao.replace('.', '').replace(',', '.')),
                custo_alimentacao: parseFloat(custo_alimentacao.replace('.', '').replace(',', '.')),
                custo_hospedagem: parseFloat(custo_hospedagem.replace('.', '').replace(',', '.')),
            },
            outros_gastos: outrosGastos, // Alteração aqui
            comprovante_inscricao: primeiroArquivoBase64,
            comprovante_programacao: segundoArquivoBase64
        };
    
        localStorage.setItem('peticao', JSON.stringify(peticaoFormatada));
        localStorage.setItem('arquivo1', primeiroArquivoBase64)
        localStorage.setItem('arquivo2', primeiroArquivoBase64)
        navigate('/sua-petição/dados-pessoais');
    };         
    
    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            if (!file) {
                reject("Nenhum arquivo fornecido");
            }
    
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const base64String = reader.result.split(';base64,')[1];
                resolve(base64String);
            };
            reader.onerror = (error) => {
                reject(error);
            };
        });
    };      

    function base64ParaFile(base64String, filename) {
        
        // Separa o cabeçalho da string Base64
        const base64Data = base64String.split(';base64,').pop();
        
        // Cria um array de bytes a partir da string Base64
        const byteCharacters = atob(base64Data);
        
        // Converte cada caractere para um número de byte
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        
        // Cria um array de bytes usando a sequência de números de bytes
        const byteArray = new Uint8Array(byteNumbers);
        
        // Cria um Blob a partir do array de bytes
        const blob = new Blob([byteArray], { type: 'application/octet-stream' });
        
        // Cria um objeto de arquivo a partir do Blob
        const file = new File([blob], filename, { type: 'application/octet-stream' });
        
        return file;
    }

    const formCompleto = () => {
        const camposObrigatorios = ['nome', 'data_inicial', 'data_final', 'custo_inscricao', 'custo_hospedagem', 'custo_alimentacao', 'custo_deslocamento'];
        if(formulario.custoDiarioOutros !== ""){
            if(formulario.custoDiarioOutros !== undefined){
                camposObrigatorios.push('outrosGastosDescricao');
            }
        }
        if(formulario.outrosGastosDescricao !== ""){
            camposObrigatorios.push('custoDiarioOutros');
        }
        return camposObrigatorios.every(field => formulario[field] !== "" && formulario[field] !== undefined && formulario[field] !== null);
    };

    const liberarBotao = () => {
        if (formCompleto() && arquivoSelecionado && segundoArquivoSelecionado) {
            return true;
        } else {
            return false;
        }
    };
    
    return (
        <div className="flex flex-col h-full w-screen">
            <div className="flex flex-col items-center justify-center">
                <h1 className="font-bold mt-2">Tipo de Processo</h1>
                <h1 className="mx-16 text-center">Auxilio Acadêmico (evento de ensino, pesquisa e extensão)</h1>
            </div>
            <div className="flex px-3 items-center justify-center gap-3 mt-5 mx-3">
                <div>
                    <h1 className="text-white bg-black w-12 h-12 flex items-center justify-center rounded-full">1</h1>
                    <h1 className="w-14 text-center">Sobre o evento</h1>
                </div>
                <div className="border-b-2 border-gray-500 flex-grow"></div>
                <div>
                    <h1 className="text-white bg-gray-500 w-12 h-12 flex items-center justify-center rounded-full">2</h1>
                    <h1 className="w-12 text-center">Dados <span className="-ml-1">Pessoais</span></h1>
                </div>
                <div className="border-b-2 border-gray-500 flex-grow"></div>
                <div>
                    <h1 className="text-white bg-gray-500 w-12 h-12 flex items-center justify-center rounded-full">3</h1>
                    <h1 className="w-12 text-center">Dados <span className="-ml-2">Bancários</span></h1>
                </div>
            </div>
                <div className="grid m-5 gap-5">
                    <div className="justify-center items-center bg-green-500 text-center p-2 rounded-lg w-80 mx-auto text-white z-10">
                        Sobre o evento
                    </div>
                    <div className="bg-white p-4 -mt-10 rounded-lg shadow-[0px_0px_10px_7px_rgba(0,0,0,0.2)]">
                        <div className="font-bold text-sm mt-3">
                            <h1>Nome do evento</h1>
                            <input className="mt-1 bg-gray-300 border-gray-400 border rounded-md p-2 w-full" type="text" name="nome" onChange={handleInputValor} value={formulario.nome}/>
                        </div>
                        <div className="font-bold text-sm mt-3">
                            <div className="flex flex-row items-center">
                                <h1>Data do evento</h1>
                                <button className="" onClick={mostrarCalendario}>
                                    <LuCalendarDays size={25} />
                                </button>
                            </div>
                            {showDatePickerPopup && (
                                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
                                    <div className="bg-white p-4 rounded-lg shadow-lg">
                                        <Calendario onDateSelection={handleSelecionarData} />
                                        <button onClick={mostrarCalendario} className="mt-2 px-4 py-2 bg-green-500 text-white rounded-md">Fechar</button>
                                    </div>
                                </div>
                            )}
                            <div className="flex flex-row justify-between">
                                <div className="font-bold text-sm mt-3">
                                    <h1>Data inicial:</h1>
                                    <input
                                        className="mt-1 bg-gray-300 border-gray-400 border rounded-md p-2"
                                        type="date"
                                        name="data_inicial"
                                        onChange={handleInputValor}
                                        value={formulario.data_inicial ? formulario.data_inicial.toISOString().split('T')[0] : ''}
                                        disabled
                                    />
                                </div>
                                <div className="font-bold text-sm mt-3">
                                    <h1>Data final:</h1>
                                    <input
                                        className="mt-1 bg-gray-300 border-gray-400 border rounded-md p-2"
                                        type="date"
                                        name="data_final"
                                        onChange={handleInputValor}
                                        value={formulario.data_final ? formulario.data_final.toISOString().split('T')[0] : ''}
                                        disabled
                                    />
                                </div>
                            </div>
                    </div>
                </div>
                <div className="justify-center items-center bg-green-500 text-center p-2 rounded-lg w-80 mx-auto text-white z-10 text-xl">
                    Sobre Custos
                </div>
                <div className="bg-white p-4 -mt-10 rounded-lg shadow-[0px_0px_10px_7px_rgba(0,0,0,0.2)]">
                    <div className="font-bold text-sm mt-3 flex flex-row items-center">
                        <h1>Inscrição:</h1>
                        <CurrencyInput
                            className="ml-3 mt-1 bg-gray-300 border-gray-400 border rounded-md p-2 w-24 "
                            name="custo_inscricao"
                            placeholder="0.00"
                            decimalScale={2}  
                            groupSeparator = {'.'}
                            decimalSeparator = {','}  
                            onValueChange={(value) => handleInputValor({ target: { name: 'custo_inscricao', value } })}
                            prefix="R$"
                            value={formulario.custo_inscricao}
                        />
                    </div>
                    <div className="justify-center items-center bg-green-500 text-center p-2 rounded-lg mx-auto w-full text-white z-10 mt-5">
                        Custos diários <span>({(Math.ceil((new Date(formulario.data_final).getTime() - new Date(formulario.data_inicial).getTime()) / (1000 * 3600 * 24)))} dias)</span>
                    </div>
                    <div className="font-bold text-sm mt-3 flex flex-row items-center justify-between">
                        <></>
                        <h2>Valor</h2>
                        <h2 className="mr-12 -ml-5">Subtotal</h2>
                    </div>
                    <div className="font-bold text-sm mt-3 flex flex-row items-center">
                        <h1>Hospedagem:</h1>
                        <CurrencyInput
                            className="ml-[14px] mt-1 mr-5 bg-gray-300 border-gray-400 border rounded-md p-2 w-24 flex flex-row"
                            name="custo_hospedagem" 
                            placeholder="0.00"
                            decimalScale={2}  
                            groupSeparator = {'.'}
                            decimalSeparator = {','}  
                            onValueChange={(value) => handleInputValor({ target: { name: 'custo_hospedagem', value } })}
                            prefix="R$"
                            value={formulario.custo_hospedagem}
                        />
                        {formulario.custo_hospedagem && formulario.data_inicial && formulario.data_final && (
                           <span>R$ {(parseFloat(formulario.custo_hospedagem) * Math.ceil((new Date(formulario.data_final).getTime() - new Date(formulario.data_inicial).getTime()) / (1000 * 3600 * 24))).toFixed(2)}</span>
                        )}
                    </div>
                    <div className="font-bold text-sm mt-3 flex flex-row items-center">
                        <h1>Alimentação:</h1>
                        <CurrencyInput
                            className="ml-[19px] mt-1 mr-5 bg-gray-300 border-gray-400 border rounded-md p-2 w-24 flex flex-row"
                            name="custo_alimentacao" 
                            placeholder="0.00"
                            decimalScale={2}
                            groupSeparator = {'.'}
                            decimalSeparator = {','}    
                            onValueChange={(value) => handleInputValor({ target: { name: 'custo_alimentacao', value } })}
                            prefix="R$"
                            value={formulario.custo_alimentacao}
                        />
                        {formulario.custo_alimentacao && formulario.data_inicial && formulario.data_final && (
                            <span>R$ {(parseFloat(formulario.custo_alimentacao) * Math.ceil((new Date(formulario.data_final).getTime() - new Date(formulario.data_inicial).getTime()) / (1000 * 3600 * 24))).toFixed(2)}</span>
                        )}
                    </div>
                    <div className="font-bold text-sm mt-3 flex flex-row items-center">
                        <h1>Deslocamento:</h1>
                        <CurrencyInput
                            className="ml-2 mt-1 mr-5 bg-gray-300 border-gray-400 border rounded-md p-2 w-24 flex flex-row"
                            name="custo_deslocamento" 
                            placeholder="0.00"
                            decimalScale={2}  
                            groupSeparator = {'.'}
                            decimalSeparator = {','}    
                            onValueChange={(value) => handleInputValor({ target: { name: 'custo_deslocamento', value } })}
                            prefix="R$"
                            value={formulario.custo_deslocamento}
                        />
                        {formulario.custo_deslocamento && formulario.data_inicial && formulario.data_final && (
                           <span>R$ {(parseFloat(formulario.custo_deslocamento) * Math.ceil((new Date(formulario.data_final).getTime() - new Date(formulario.data_inicial).getTime()) / (1000 * 3600 * 24))).toFixed(2)}</span>
                        )}
                    </div>
                    <div className="font-bold text-sm mt-3 flex flex-row items-center">
                        <h1>Outros gastos:</h1>
                        <CurrencyInput
                            className="ml-[10px] mt-1 mr-5 bg-gray-300 border-gray-400 border rounded-md p-2 w-24 flex flex-row"
                            name="custoDiarioOutros" 
                            placeholder="0.00"
                            allowDecimals={true}
                            decimalScale={2}    
                            groupSeparator = {'.'}
                            decimalSeparator = {','} 
                            onValueChange={(value) => handleInputValor({ target: { name: 'custoDiarioOutros', value } })}
                            prefix="R$"
                            value={formulario.custoDiarioOutros}
                        />
                        {formulario.custoDiarioOutros && formulario.data_inicial && formulario.data_final && (
                           <span>R$ {(parseFloat(formulario.custoDiarioOutros) * Math.ceil((new Date(formulario.data_final).getTime() - new Date(formulario.data_inicial).getTime()) / (1000 * 3600 * 24))).toFixed(2)}</span>
                        )}
                    </div>
                    <div className="font-bold text-sm mt-1 flex flex-row justify-end ">
                        {formulario.data_inicial && formulario.data_final && (
                            <>
                                <h1>Total Geral: </h1>
                                <h1 className="ml-2">R$ {calcularCustoTotal().toFixed(2)}</h1>
                            </>
                        )}
                    </div>
                    <div className="font-bold text-sm mt-3">
                        <h1>Especifique os outros gastos:</h1>
                        <textarea className="mt-1 bg-gray-300 border-gray-400 border rounded-md p-2 w-full" name="outrosGastosDescricao" onChange={handleInputValor} value={formulario.outrosGastosDescricao}></textarea>
                    </div>
                </div>    
                               
                <div className="justify-center items-center bg-green-500 text-center p-2 rounded-lg w-80 mx-auto text-white z-10 text-lg">
                    Documentos
                </div>
                <div className="bg-white p-4 -mt-10 rounded-lg shadow-[0px_0px_10px_7px_rgba(0,0,0,0.2)] flex flex-col items-start">
                    <div className="flex items-center">
                        <CiWarning size={100}/>
                        <div className="text-sm font-bold mt-3">Os documentos devem ser carregados abaixo, sendo de sua exclusiva responsabilidade a conformidade entre os dados informados e os documentos.</div>
                    </div>
                    <div className="justify-center items-center bg-green-500 text-center p-2 rounded-lg mx-auto w-full text-white z-10 mt-5">
                        Documentos complementares
                    </div>
                    <div className="flex flex-row mt-4 items-center">
                        {arquivoSelecionado ? (
                            <div className="flex items-center gap-28" onClick={removerArquivoSelecionado}>
                                <span className="font-bold">Comprovante de inscrição</span>
                                <AiFillMinusCircle size={30} color="red"/>
                            </div>
                        ) : (
                            <>
                                <input type="file" onChange={handleAlterarArquivo} className="hidden" id="fileInput" />
                                <label htmlFor="fileInput" className="flex flex-row items-center gap-28">
                                    <span className="font-bold">Comprovante de inscrição</span>
                                    <AiFillPlusCircle htmlFor="fileInput" size={30} color="green"/>
                                </label>
                            </>
                        )}
                    </div>
                        {arquivoSelecionado && 
                        <div className="flex flex-row items-center mt-2">
                            <PiFilePdf size={50} color="red"/>
                            <span className="ml-2">{(arquivoSelecionado && arquivoSelecionado.name) || 'Comprovante de inscrição'}</span>
                        </div>}
                        <div className="flex flex-row mt-4 items-center">
                            {segundoArquivoSelecionado ? (
                                <div className="flex items-center" onClick={removerSegundoArquivoSelecionado}>
                                    <span className="font-bold mr-[133px]">Progamação do evento</span>
                                    <AiFillMinusCircle size={30} color="red"/>
                                </div>
                            ) : (
                                <>
                                    <input type="file" onChange={handleSegundoFileChange} className="hidden" id="segundoFileInput" />
                                    <label htmlFor="segundoFileInput" className="flex flex-row items-center">
                                        <span className="font-bold mr-[133px]">Progamação do evento</span>
                                        <AiFillPlusCircle htmlFor="segundoFileInput" size={30} color="green"/>
                                    </label>
                                </>
                            )}
                        </div>
                        {segundoArquivoSelecionado && 
                            <div className="flex flex-row items-center mt-2">
                                <PiFilePdf size={50} color="red"/>
                                <span className="ml-2">{(segundoArquivoSelecionado && segundoArquivoSelecionado.name) || 'Progamação do evento'}</span>
                            </div>}
                </div>
                <div className="flex justify-end gap-5">
                    <button className='px-3 py-1 w-30 bg-red-600 rounded-lg text-white' onClick={btnCancelar}>Cancelar</button>
                    <button className={`px-3 py-1 w-30 bg-green-600 rounded-lg text-white ${!liberarBotao() ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={btnSalvar} disabled={!liberarBotao()}>Proseguir</button>
                </div>
            </div>
        </div>
    );
};

export default PeticaoNova;