import { PiEyeClosed, PiEye, PiUser, PiLock } from "react-icons/pi";
import { FiAlertTriangle } from "react-icons/fi";

const LoginMobile = ({ login, setLogin, senha, setSenha, mostrarSenha, setMostrarSenha, erro, onSubmit }) => {
  return (
    <form className='flex-col w-full h-full' onSubmit={onSubmit} autoComplete="off">
      <div className="relative">
        <img src='/imagens/group-teenagers-discussing-university-project 2.png' alt='logo' className="h-72 w-full"/>
        <img src='/imagens/suapUNO-logo_branca.png' alt='logo' className="absolute top-0 ml-[25%] mt-16"/>
      </div>

      <h1 className="text-center my-6 text-xl text-white">Acesse com os seus dados <br/><span className="font-bold">Q-acadêmico</span> </h1>
      
      <div className="mx-5">
        <div className="relative mb-4">
          <PiUser className="absolute left-2 top-3 text-white" size={20}/>
          <input 
            type="text" 
            value={login} 
            onChange={(e) => setLogin(e.target.value)} 
            className="border-b-2 border-white bg-transparent pl-8 p-2 w-full text-white placeholder-white" 
            placeholder="Usuário"
            autoComplete="new-username"
          />
        </div>
        
        <div>
          <div className="relative mb-4 flex items-center">
            <PiLock className="absolute left-2 top-3 text-white" size={20}/>
            <input 
              type={mostrarSenha ? "text" : "password"} 
              value={senha} 
              onChange={(e) => setSenha(e.target.value)} 
              className="border-b-2 border-white bg-transparent pl-8 p-2 w-full text-white placeholder-white" 
              placeholder="Senha"
              autoComplete="new-password"
            />
            <button 
              type="button" 
              onClick={() => setMostrarSenha(!mostrarSenha)} 
              className="absolute right-2 top-2 text-white"
            >
              {mostrarSenha ? <PiEye /> : <PiEyeClosed />}
            </button>
          </div>
        </div>

        <div className="ml-2">
          {erro && (
            <div className="flex flex-row items-center mt-2">
              <FiAlertTriangle className="text-red-500" />
              <p className="text-red-500 text-sm ml-1">Usuário ou senha inválidos</p>
            </div>
          )}
        </div>

        <div className='flex items-center justify-center mt-7'>
          <button type="submit" className="bg-white rounded-lg w-full p-3 text-green-700">
            Entrar
          </button>
        </div>
      </div>
    </form>
  );
};

export default LoginMobile;