import React, { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { PiArrowRight, PiPlus, PiTrash } from 'react-icons/pi';

const Entidade = () => {
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [termoBusca, setTermoBusca] = useState('');
    const token = localStorage.getItem('token');
    const [entidades, setEntidades] = useState([]);
    const [statusSelecionado, setStatusSelecionado] = useState('');
    const [novaEntidade, setnovaEntidade] = useState('');
    const [novaDescricao, setnovaDescricao] = useState('');
    const [itensPorPagina, setItensPorPagina] = useState(() => {
        if(window.innerHeight > 900){
          return Math.floor(((window.innerHeight - 100) / 90) / 1.5);
        } else if (window.innerHeight < 850) {
          return Math.floor((((window.innerHeight - 100) / 100) - 1) / 1.5);
        } else {
          return Math.floor(((window.innerHeight - 100) / 100) / 1.5);
        }
    })
    const itensFiltrados = entidades.filter ( item => item.entidade.toLowerCase().includes(termoBusca.toLowerCase()) || item.descricao.toLowerCase().includes(termoBusca.toLowerCase())
    );

    useEffect(() => {
        const handleResize = () => {
        if(window.innerHeight > 900){
            setItensPorPagina(Math.floor(((window.innerHeight - 100) / 90)));
        } else if (window.innerHeight < 850) {
            setItensPorPagina(Math.floor(((window.innerHeight - 100) / 100) - 1));
        } else {
            setItensPorPagina(Math.floor((window.innerHeight - 100) / 100));
        }
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        fetchDados();
        // eslint-disable-next-line
    }, [token]);

    const fetchDados = async () => {
        try {
        const entidades = await fetch('https://suapuno.app:8080/api/assistente-virtual/entidades/', {
            method: 'GET',
            headers: {
            'Authorization': `Token ${token}`
            }
        });
        if (entidades.ok) {
            const resposta = await entidades.json();
            setEntidades(resposta);
        } else {
            console.log('Api não retornou o ok:', entidades.status);
        }
        } catch (error) {
        console.log('Erro ao tentar capturar historico de chat.', error);
        }
    };

    const adicionarItem = () => {
        if (novaEntidade.trim() && novaDescricao.trim()) {
        const novoItem = {
            entidade: novaEntidade,
            descricao: novaDescricao,
        };
        fetch(`https://suapuno.app:8080/api/assistente-virtual/entidades/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
            body: JSON.stringify(novoItem)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Erro na requisição: ' + response.statusText);
            }else{
            fetchDados();
            setnovaEntidade('');
            setnovaDescricao('');
            }
        })
    }}     
    
    const removerItem = (id_entidade) => {
        fetch(`https://suapuno.app:8080/api/assistente-virtual/entidades/${id_entidade}/`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        },
        })
        .then(response => {
        if (!response.ok) {
            throw new Error('Erro na requisição: ' + response.statusText);
        }
        })
        setEntidades(entidades.filter(item => item.id_entidade !== id_entidade));
    }; 

    const paginasVisiveis = () => {
        const paginas = [];

        if(paginaAtual - 1 > 0){
        paginas.push(paginaAtual - 1)
        }
        paginas.push(paginaAtual)
        if(itensFiltrados.slice((paginaAtual - 1) * itensPorPagina, paginaAtual * itensPorPagina).length === itensPorPagina){
        paginas.push(paginaAtual + 1)
        }

        return paginas;
    };

    const handleInputChange = (id_entidade, field, value) => {
        setEntidades(prevEntidades => prevEntidades.map(item => 
            item.id_entidade === id_entidade ? {...item, [field]: value } : item
        ));
    };
    
    const handleKeypress = (e, id_entidade) => {
        if(e.key === 'Enter'){
            const itemAtualizado = entidades.find(item => item.id_entidade === id_entidade);
            if(itemAtualizado){
                atualizaEntidade(itemAtualizado);
            }
        }
    };
    
    const handleBlur = (id_entidade) => {
        const itemAtualizado = entidades.find(item => item.id_entidade === id_entidade);
        if(itemAtualizado){
            atualizaEntidade(itemAtualizado);
        }
    };
    
    const atualizaEntidade = (itemAtualizado) => {
        fetch(`https://suapuno.app:8080/api/assistente-virtual/entidades/${itemAtualizado.id_entidade}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
            body: JSON.stringify(itemAtualizado)
        })
        .then(response => {
            if (!response.ok){
                throw new Error('Erro na requisição: ' + response.status);
            } else {
                fetchDados();  // Recarrega os dados atualizados
            }
        })
        .catch(error => {
            console.log('Erro ao salvar alterações.', error);
        });
    };    

  return (
    <div className='flex h-fit'>
        <div className="flex flex-col flex-grow">
            <div className="flex flex-col h-auto">
                <div className='bg-white py-2 px-5 rounded-b-lg rounded-tr-lg'>
                    <div className="flex flex-row gap-3 mt-2 items-center w-full">
                        <h1 className='font-semibold text-center'>Filtros: </h1>
                        <div className="flex flex-col w-full relative">
                            <input
                                className="border border-gray-400 bg-gray-300 rounded-md w-full pl-10 py-1"
                                placeholder="Busque por entidades"
                                value={termoBusca}
                                onChange={(e) => {
                                setTermoBusca(e.target.value);
                                setPaginaAtual(1);
                                }}
                            />
                            <span className="absolute mt-[8px] ml-2 text-gray-400"><FaSearch size={20} /></span>
                        </div>
                    </div>
                    <div className="mt-8 w-full flex gap-2">
                        <div className="flex flex-row gap-2 w-[85%]">
                            <div className='relative border border-[#1e731d] bg-white rounded-md p-2 w-1/2'>
                                <input
                                type="text"
                                className="w-full outline-none"
                                value={novaEntidade}
                                onChange={(e) => setnovaEntidade(e.target.value)}
                                />
                                <h1 className='absolute -mt-11 bg-white p-1 text-xs text-[#1e731d]'>NOVA ENTIDADE</h1>
                            </div>
                            <input
                                type="text"
                                className="border border-[#1e731d] bg-white rounded-md p-2 w-1/2 outline-none"
                                placeholder="DESCRIÇÃO DA ENTIDADE"
                                value={novaDescricao}
                                onChange={(e) => setnovaDescricao(e.target.value)}
                            />
                            </div>
                            <div className='w-[15%] flex'>
                            <button
                                className="bg-[#1e731d] text-white py-1 px-3 rounded-xl w-full flex items-center gap-2 justify-center"
                                onClick={adicionarItem}
                                >
                                <PiPlus size={20}/> Adicionar nova entidade
                            </button>
                        </div>
                    </div>
                    { itensFiltrados.length > 0 ? (
                        <div className='flex flex-col items-center justify-center'>
                            {itensFiltrados.slice((paginaAtual - 1) * itensPorPagina, paginaAtual * itensPorPagina).map(item => (
                                <div key={item.id_entidade} className='flex w-full justify-start gap-5 mt-3 p-5 border border-black rounded-lg'>
                                    <div className='flex justify-between items-center w-[25%]'>
                                        {item.tipo !== "usuário" ? (
                                            <h1 
                                                className='bg-green-700 text-white font-semibold py-1 px-3 rounded-lg'
                                            >
                                                {item.entidade}
                                            </h1>
                                        ):(
                                            <input 
                                                type='text'
                                                value={item.entidade}
                                                onChange={(e) => handleInputChange(item.id_entidade, 'entidade', e.target.value)}
                                                onKeyDown={(e) => handleKeypress(e, item.id_entidade)}
                                                onBlur={() => handleBlur(item.id_entidade)}
                                                className='bg-green-700 text-white font-semibold py-1 px-3 rounded-lg'
                                                style={{ width: `${item.entidade.length + 3}ch` }}
                                            />
                                        )}
                                        <PiArrowRight size={20} className='mr-6 '/>
                                    </div>
                                    <div className='flex justify-between items-center w-[75%]'>
                                        
                                        {item.tipo !== "usuário" ? (
                                            <h1 className='ml-5'>{item.descricao}</h1>
                                        ):(
                                            <input 
                                                type='text'
                                                value={item.descricao}
                                                onChange={(e) => handleInputChange(item.id_entidade, 'descricao', e.target.value)}
                                                onKeyDown={(e) => handleKeypress(e, item.id_entidade)}
                                                onBlur={() => handleBlur(item.id_entidade)}
                                                style={{ width: `${item.descricao.length + 3}ch` }}
                                            />
                                        )}
                                        {item.tipo === "usuário" && (
                                            <div className='flex items-center gap-3'>
                                                <span className='border-l border-l-black h-6'></span>
                                                <PiTrash onClick={() => removerItem(item.id_entidade)} color='white' className='bg-red-700 p-1 rounded-full' size={30}/>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ):(
                        <div className='flex mt-4 w-full justify-center items-center'>
                            <img className='' src='/imagens/loading.gif'></img>
                        </div>
                    )}
                    <div className="flex justify-center mt-4 gap-2 -ml-12">
                        {paginaAtual > 2 && (
                        <div className=' flex'>
                            <button onClick={() => setPaginaAtual(1)} className={`px-3 py-1 rounded-full bg-gray-400`}>{1}</button>
                            <h1 className='ml-2 mt-2 font-semibold'>. . .</h1>
                        </div>
                        )}
                        {paginasVisiveis().map((pagina, index) => (
                        <button key={index} onClick={() => setPaginaAtual(pagina)} className={`px-3 py-1 rounded-full ${pagina === paginaAtual ? 'bg-green-600 text-white' : 'bg-gray-400'}`}>{pagina}</button>
                        ))}
                    </div>                    
                </div>
            </div>
        </div>
    </div>
  );
};

export default Entidade;