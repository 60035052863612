import React, { useState, useEffect, useCallback } from 'react';
import CardPeticao from "../../../components/ui/cardPeticao";
import { useNavigate } from "react-router-dom";

const PeticoesAluno = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const id_user = localStorage.getItem('id_user');
    const [peticoes, setPeticoes] = useState([]);
    const [carregando, setCarregando] = useState(true);

    const fetchPeticoes = useCallback(async () => {
        try {
            const response = await fetch('https://suapuno.app:8080/api/peticao/', {
                method: 'GET',
                headers: {
                    Authorization: `Token ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Erro ao acessar a API de petições');
            }

            const data = await response.json();
            setPeticoes(data || []);
            console.log(peticoes)
        } catch (error) {
            console.error('Error fetching peticoes:', error);
        } finally {
            setCarregando(false);
        }
    }, [token, peticoes]);

    const fetchUserDetails = useCallback(async () => {
        try {
            const response = await fetch(`https://suapuno.app:8080/api/usuarios/${id_user}/`, {
                method: 'GET',
                headers: {
                    Authorization: `Token ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Erro ao acessar a API de usuários');
            }

            const data = await response.json();
            localStorage.setItem('dados_pessoais', JSON.stringify(data.aluno));

            const dados = JSON.parse(localStorage.getItem('dados_pessoais'));
            localStorage.setItem('dados_aluno_verificado', dados.verificado);

        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    }, [token, id_user]);

    const fetchBankDetails = useCallback(async () => {
        try {
            const response = await fetch('https://suapuno.app:8080/api/dados-bancarios/', {
                method: 'GET',
                headers: {
                    Authorization: `Token ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Erro ao acessar a API de dados bancários');
            }

            const data = await response.json();
            localStorage.setItem('dados_bancarios', JSON.stringify(data));

            const dados = JSON.parse(localStorage.getItem('dados_bancarios'));
            localStorage.setItem('dados_bancario_verificado', dados.verificado);

            if (data.comprovante) {
                const comprovanteResponse = await fetch(data.comprovante);
                const comprovanteData = await comprovanteResponse.blob();

                const reader = new FileReader();
                reader.onloadend = () => {
                    let arquivo3 = reader.result.replace(/^data:application\/pdf;base64,/, '');
                    localStorage.setItem('arquivo3', arquivo3);
                };
                reader.readAsDataURL(comprovanteData);
            }
        } catch (error) {
            console.error('Error fetching bank details:', error);
        }
    }, [token]);

    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([fetchPeticoes(), fetchUserDetails(), fetchBankDetails()]);
        };

        fetchData();
    }, [fetchPeticoes, fetchUserDetails, fetchBankDetails]);

    return (
        <div className="grid mx-4">
            <div className='flex flex-col items-end justify-end my-2'>
                <button className='px-5 py-2 mb-2 bg-green-500 rounded-lg' onClick={() => navigate('/sua-petição/nova')}>
                    +
                </button>
            </div>
            <div className="flex flex-col justify-center items-center gap-3">
                {carregando ? (
                    <img src="/imagens/loading.gif" alt="Carregando..." />
                ) : (
                    peticoes.reverse().map((peticao) => (
                        <CardPeticao
                            key={peticao.id}
                            data={{
                                id: peticao.id,
                                nome: peticao.nome_evento,
                                data_inicial: peticao.data_inicial,
                                data_final: peticao.data_final,
                                tipo: peticao.tipo,
                                status: peticao.status,
                                comprovantes: peticao.comprovantes,
                                modalidade: peticao.modalidade_auxilio,
                                visualizado_aluno: peticao.visualizado_aluno,
                            }}
                        />
                    ))
                )}
            </div>
        </div>
    );
};

export default PeticoesAluno;