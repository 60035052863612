import React, { useEffect, useRef, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { IoPersonCircle } from 'react-icons/io5';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const Conversas = () => {
    const [termoBusca, setTermoBusca] = useState('');
    const token = localStorage.getItem('token');
    const [chats, setChats] = useState([]);
    const [expandidoChat, setExpandidoChat] = useState(null);
    const [mensagens, setMensagens] = useState([]);
    const chatEnd = useRef(null);
    const chatContainerRef = useRef(null);
    const [linkNext, setLinkNext] = useState('');
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [itensPorPagina, setItensPorPagina] = useState(() => {
        if (window.innerHeight > 900) {
            return Math.floor((window.innerHeight - 200) / 90);
        } else if (window.innerHeight < 850) {
            return Math.floor((window.innerHeight - 200) / 100) - 1;
        } else {
            return Math.floor((window.innerHeight - 200) / 100);
        }
    });

    useEffect(() => {
        const handleResize = () => {
            if (window.innerHeight > 900) {
                setItensPorPagina(Math.floor((window.innerHeight - 200) / 90));
            } else if (window.innerHeight < 850) {
                setItensPorPagina(Math.floor((window.innerHeight - 200) / 100) - 1);
            } else {
                setItensPorPagina(Math.floor((window.innerHeight - 200) / 100));
            }
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        fetchDados();
    }, [token]);

    const fetchDados = async () => {
        try {
            const response = await fetch('https://suapuno.app:8080/api/assistente-virtual/chat-aluno/', {
                method: 'GET',
                headers: {
                    'Authorization': `Token ${token}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                setChats(data.results);
                setLinkNext(data.next);
            } else {
                console.log('Api não retornou o ok:', response.status);
            }
        } catch (error) {
            console.log('Erro ao tentar capturar chats dos alunos.', error);
        }
    };

    const toggleChat = async (index, id_aluno) => {
        const expandido = expandidoChat !== index;
        setExpandidoChat(expandido ? index : null);
    
        if (expandido) {
            let allMessages = [];
            let nextUrl = `https://suapuno.app:8080/api/assistente-virtual/listar-mensagens/?id_aluno=${id_aluno}`;
            
            try {
                while (nextUrl) {
                    const response = await fetch(nextUrl, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Token ${token}`
                        }
                    });
    
                    if (response.ok) {
                        const data = await response.json();
                        allMessages = [...allMessages, ...data.results];
                        nextUrl = data.next; // Atualiza a URL para a próxima página
                    } else {
                        console.log('Api não retornou o ok:', response.status);
                        break;
                    }
                }
    
                setMensagens(allMessages);
            } catch (error) {
                console.log('Erro ao tentar capturar mensagens do aluno.', error);
            }
        } else {
            setMensagens([]);
        }
    };    

    const exibirPaginas = () => {
        const maisPaginas = async () => {
            try {
                const perguntas = await fetch(linkNext, {
                method: 'GET',
                headers: {
                    'Authorization': `Token ${token}`
                }
                });
                if (perguntas.ok) {
                const data = await perguntas.json();
                setChats(prevChats=> [...data.results.reverse(), ...prevChats])
                setLinkNext(data.next);
                } else {
                console.log('Api não retornou o ok:', perguntas.status);
                }
            } catch (error) {
                console.log('Erro ao tentar obter mais chats.', error);
            }
          };

        const paginas = [];

        if(paginaAtual - 1 > 0){
          paginas.push(paginaAtual - 1)
        }

        paginas.push(paginaAtual)

        if(chats.slice((paginaAtual - 1) * itensPorPagina, paginaAtual * itensPorPagina)){
          paginas.push(paginaAtual + 1)
        } else {
            maisPaginas()
        }

        return paginas;
    };

    const chatsVisiveis = chats.slice((paginaAtual - 1) * itensPorPagina, paginaAtual * itensPorPagina);

    return (
        <div className='flex h-fit'>
            <div className="flex flex-col flex-grow">
                <div className="flex flex-col h-auto">
                    <div className='bg-white py-2 px-5 rounded-b-lg rounded-tr-lg'>
                        <div className="flex flex-row gap-3 mt-2 items-center w-full">
                            <h1 className='font-semibold text-center'>Filtros: </h1>
                            <div className="flex flex-col w-full relative">
                                <input
                                    className="border border-gray-400 bg-gray-300 rounded-md w-full pl-10 py-1"
                                    placeholder="Busque por entidades"
                                    value={termoBusca}
                                    onChange={(e) => setTermoBusca(e.target.value)}
                                />
                                <span className="absolute mt-[8px] ml-2 text-gray-400"><FaSearch size={20} /></span>
                            </div>
                        </div>
                        <div className='flex flex-col mt-5'>
                            {expandidoChat === null ? (
                                <div>
                                    {chatsVisiveis.map((item, index) => (
                                        <div key={index} className='flex flex-col w-full border border-gray-400 rounded-lg mb-2'>
                                            <div className='flex justify-between items-center p-3 cursor-pointer' onClick={() => toggleChat(index, item.id_aluno)}>
                                                <div className='flex items-center'>
                                                    <IoPersonCircle size={50} />
                                                    <div className='flex flex-col justify-center ml-2'>
                                                        <h1 className='font-semibold text-xl'>{item.nome_aluno}</h1>
                                                        <h1 className='italic text-gray-400'>Aluno: {item.curso}</h1>
                                                    </div>
                                                </div>
                                                <div>
                                                    <FaChevronDown size={20} />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <div className="flex justify-center mt-4 gap-2 -ml-12">
                                        {paginaAtual > 2 && (
                                            <div className=' flex'>
                                                <button onClick={() => setPaginaAtual(1)} className={`px-3 py-1 rounded-full bg-gray-400`}>{1}</button>
                                                <h1 className='ml-2 mt-2 font-semibold'>. . .</h1>
                                            </div>
                                        )}
                                        {exibirPaginas().map((pagina, index) => (
                                            <button key={index} onClick={() => setPaginaAtual(pagina)} className={`px-3 py-1 rounded-full ${pagina === paginaAtual ? 'bg-green-600 text-white' : 'bg-gray-400'}`}>{pagina}</button>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <div key={expandidoChat} className='flex flex-col w-full border border-gray-400 rounded-lg mb-2 h-[calc(100vh-320px)] overflow-auto'>
                                    <div className='flex justify-between items-center p-3 cursor-pointer' onClick={() => toggleChat(expandidoChat)}>
                                        <div className='flex items-center'>
                                            <IoPersonCircle size={50} />
                                            <div className='flex flex-col justify-center ml-2'>
                                                <h1 className='font-semibold text-xl'>{chats[expandidoChat].nome_aluno}</h1>
                                                <h1 className='italic text-gray-400'>Aluno: {chats[expandidoChat].curso}</h1>
                                            </div>
                                        </div>
                                        <div>
                                            <FaChevronUp size={20} />
                                        </div>
                                    </div>
                                    <div 
                                        ref={chatContainerRef} 
                                        className="flex-1 overflow-y-auto p-4 bg-[#898989] rounded-b-lg" 
                                    >
                                        {mensagens.map((mensagem, index) => (
                                            <div key={index}>
                                                <div className='flex mb-2 justify-end'>
                                                    <div className="flex items-start">
                                                        <div className='p-2 rounded-md text-sm relative bg-[#7eda7d] mr-2 text-left'>
                                                            <div style={{ whiteSpace: 'pre-wrap' }}>{mensagem.pergunta}</div>
                                                            <div className='absolute w-0 h-0 border-t-[6px] border-t-transparent border-b-[6px] border-b-transparent border-l-[12px] border-l-[#7eda7d] mt-2 right-0 top-0 -mr-2' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='flex mb-2 justify-start'>
                                                    <div className="flex items-start">
                                                        <div className='p-2 rounded-md text-sm relative bg-white ml-2 text-left'>
                                                            <div style={{ whiteSpace: 'pre-wrap' }}>{mensagem.resposta}</div>
                                                            <div className='absolute w-0 h-0 border-t-[6px] border-t-transparent border-b-[6px] border-b-transparent border-r-[12px] border-r-white mt-2 top-0 -ml-4' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <div ref={chatEnd} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Conversas;
