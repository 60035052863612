import React, { useState } from 'react';

const Calendario = ({ onDateSelection }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const handleDateClick = (date) => {
    if (!startDate) {
      setStartDate(date);
    } else if (!endDate && date > startDate) {
      setEndDate(date);
      onDateSelection(startDate, date);
    } else {
      setStartDate(date);
      setEndDate(null);
    }
  };

  const isWithinRange = (date) => {
    return startDate && endDate && date >= startDate && date <= endDate;
  };

  const isStartDate = (date) => {
    return startDate && startDate.getTime() === date.getTime();
  };

  const isEndDate = (date) => {
    return endDate && endDate.getTime() === date.getTime();
  };

  const isBetweenDates = (date) => {
    return startDate && endDate && date > startDate && date < endDate;
  };

  const renderCalendar = () => {
    const days = [];
    const month = currentMonth.getMonth();
    const year = currentMonth.getFullYear();
    const firstDayOfMonth = new Date(year, month, 1);
    const lastDayOfMonth = new Date(year, month + 1, 0);
    const startingDay = firstDayOfMonth.getDay();
    const numDays = lastDayOfMonth.getDate();

    for (let i = 0; i < startingDay; i++) {
      days.push(<div key={`empty-${i}`} className="empty-day" />);
    }

    for (let i = 1; i <= numDays; i++) {
      const date = new Date(year, month, i);
      let classNames = ['p-1', 'text-center', 'cursor-pointer'];

      if (isBetweenDates(date)) {
        classNames.push('bg-gray-800 text-white');
      } else if (isWithinRange(date) || isStartDate(date) || isEndDate(date)) {
        classNames.push('bg-green-800 text-white');
      }

      days.push(
        <div
          key={i}
          className={classNames.join(' ')}
          onClick={() => handleDateClick(date)}
        >
          {i}
        </div>
      );
    }

    return days;
  };

  const goToPreviousMonth = () => {
    setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1));
  };

  const goToNextMonth = () => {
    setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1));
  };

  return (
    <div className="date-range-picker">
      <div className="w-72 border border-gray-300 p-2 rounded">
        <div className="month-nav flex justify-between items-center mb-2">
          <button onClick={goToPreviousMonth} className="text-gray-600 p-1 rounded">{"<"}</button>
          <div>{currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}</div>
          <button onClick={goToNextMonth} className="text-gray-600 p-1 rounded">{">"}</button>
        </div>
        <div className="weekdays grid grid-cols-7 border-b border-gray-300">
          <div className="p-1 text-center">Dom</div>
          <div className="p-1 text-center">Seg</div>
          <div className="p-1 text-center">Ter</div>
          <div className="p-1 text-center">Qua</div>
          <div className="p-1 text-center">Qui</div>
          <div className="p-1 text-center">Sex</div>
          <div className="p-1 text-center">Sab</div>
        </div>
        <div className="days grid grid-cols-7 gap-1">{renderCalendar()}</div>
      </div>
    </div>
  );
};

export default Calendario;
