export default function enviarImagemParaServidor({ dadosImagem, disc_id, aula_id, setDadosApi, setCarregando }) {
    if (dadosImagem && dadosImagem.arquivoImagem) {

        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append("imagem", dadosImagem.arquivoImagem);

        fetch(`https://suapuno.app:8080/api/minhas-disciplinas/${disc_id}/fotochamada/${aula_id}/`, {
            method: 'POST',
            headers: {
                Authorization: `Token ${token}`,
            },
            body: formData,
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Erro ao enviar imagem: ' + response.statusText);
            }
        })
        .then(data => {
            setDadosApi(data);
            setCarregando(false);
        })
        .catch(error => console.error('Erro ao carregar dados de chamada:', error));
    }
}
