import { useEffect, useState } from "react";
import Chamada from "../suaPresenca/Chamada";
import { IoReturnUpBack } from "react-icons/io5";

const Aulas = ({ titulo, id }) => {
  const [indexAberto, setIndexAberto] = useState(null);
  const [aulasInfo, setAulasInfo] = useState([]);
  const [alunos,] = useState([]);
  const [, setDisciplina] = useState("");
  const [tabelaVisivel, setTabelaVisivel] = useState(true);
  const [chamadaVisivel, setChamadaVisivel] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://suapuno.app:8080/api/diarios/${id}/aulas/`, {
          method: 'GET',
          headers: {
            'accept': 'application/json',
            'Authorization': `Token ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) {
          throw new Error('Falha ao acessar arquivos');
        }
        const data = await response.json();

        if (data && Array.isArray(data)) {
          const aulas = data;
          if (aulas.length > 0 && aulas[0].disciplina) {
            setDisciplina(aulas[0].disciplina);
            setAulasInfo(aulas);
          } else {
            throw new Error('Disciplina não encontrada na API');
          }
        } else {
          throw new Error('Formato de dados inválido');
        }
      } catch (error) {
        console.error('Erro ao carregar aulas', error);
      }
    };

    fetchData();
  }, [id]);

  const toggleEstado = (index) => {
    setIndexAberto(prevIndex => (prevIndex === index ? null : index));
    setTabelaVisivel(false);
    setChamadaVisivel(true);
  };

  const handleVoltarAulas = () => {
    setTabelaVisivel(true);
    setChamadaVisivel(false);
    setIndexAberto(null); 
  };
  
  return (
    <div>
      <div className={`${chamadaVisivel ? "text-xl font-bold border-b-2 border-b-gray-400 w-full" : "text-xl font-bold border-b-2 border-b-gray-400 pb-5 w-full"}`}>
        {titulo}
        {chamadaVisivel && (
          <div className="flex flex-row justify-start">
            <button onClick={handleVoltarAulas}><IoReturnUpBack size={30}/></button>
          </div>
        )}
      </div>


      <div className="flex justify-center items-center">
        {tabelaVisivel && (
          <table>
            <thead>
              <tr>
                <th className="p-4">Descrição</th>
                <th className="p-4">Data</th>
                <th className="p-4">Chamada</th>
              </tr>
            </thead>
            <tbody className="justify-center items-center">
              {aulasInfo.map((aula, index) => (
                <tr key={aula.id_aula}>
                  <td className="p-4">{aula.descricao_aula}</td>
                  <td className="p-4">{aula.data_aula}</td>
                  <td className="p-4">
                    <button onClick={() => toggleEstado(index)}>Ver Chamada</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {indexAberto !== null && chamadaVisivel && (
          <Chamada
            id_disc={id}
            id_aula={aulasInfo[indexAberto].id_aula}
            alunos={alunos || []}
            setTabelaVisivel={setTabelaVisivel}
          />
        )}
      </div>
    </div>
  );
};

export default Aulas;
