import React, { useState, useEffect, useRef } from 'react';
import { PiPaperPlane, PiDot } from 'react-icons/pi';
import MenuTopo from "../components/ui/menuTopo";
import Menu from '../components/ui/menu';
import notificationSound from './mensagem.mp3';

const Chat = () => {
  const [mensagens, setMensagens] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [digitando, setDigitando] = useState(false);
  const chatEnd = useRef(null);
  const chatContainerRef = useRef(null);
  const token = localStorage.getItem('token');
  const [historico, setHistorico] = useState([]);
  const [linkNext, setLinkNext] = useState('');
  const [, setHistoricoCarregado] = useState(false);
  const mensagensBoasVindas = [
    "Olá, eu sou Suapinho! Estou aqui para ajudar e responder a qualquer dúvida que você possa ter sobre o sistema SUAPuno.",
    "Oi! Eu sou o Suapinho. Como posso te ajudar hoje?",
    "Bem-vindo! Eu sou Suapinho. Se precisar de qualquer ajuda com o SuapUno, estou aqui.",
    "Olá! Sou Suapinho, seu assistente virtual. Qualquer dúvida sobre o SuapUno, pergunte-me!",
    "Oi, sou Suapinho! Estou aqui para tirar suas dúvidas sobre o SUAPuno. Como posso ajudar?"
  ];

  const audio = new Audio(notificationSound);

  useEffect(() => {
    const fetchHistorico = async () => {
      try {
        const historico = await fetch('https://suapuno.app:8080/api/assistente-virtual/listar-mensagens/', {
          method: 'GET',
          headers: {
            'Authorization': `Token ${token}`
          }
        });
        if (historico.ok) {
          const resposta = await historico.json();
          if (resposta.results.length === 0) {
            const mensagemAleatoria = mensagensBoasVindas[Math.floor(Math.random() * mensagensBoasVindas.length)];
            setMensagens([...mensagens, { remetente: 'Suapinho', texto: mensagemAleatoria }]);
          } else {
            setHistorico(resposta.results.reverse());
            setLinkNext(resposta.next);
          }
        } else {
          console.log('Api não retornou o ok:', historico.status);
        }
      } catch (error) {
        console.log('Erro ao tentar capturar historico de chat.', error);
      }
    };

    fetchHistorico();
    setTimeout(() => {
      setHistoricoCarregado(true);
    }, 1000);
    // eslint-disable-next-line
  }, [mensagens, token]);

  useEffect(() => {
    if (historico.length === 5) {
      chatEnd.current?.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line
  }, [historico, mensagens]);

  const fetchMaisHistorico = async () => {
    try {
      const historico = await fetch(linkNext, {
        method: 'GET',
        headers: {
          'Authorization': `Token ${token}`
        }
      });
      if (historico.ok) {
        const resposta = await historico.json();
        if (resposta.results.length > 0) {
          // armazena altura atual no componente de scroll
          const scrollHeigthAntes = chatContainerRef.current.scrollHeight;
          
          setHistorico(prevHistorico => [...resposta.results.reverse(), ...prevHistorico]);
          setLinkNext(resposta.next);

          // Manter o scroll no mesmo lugar
          setTimeout(() => {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight - scrollHeigthAntes;
          }, 0);
        } else {
          setLinkNext(null);
        }
      } else {
        console.log('Api não retornou o ok:', historico.status);
      }
    } catch (error) {
      console.log('Erro ao tentar capturar historico de chat.', error);
    }
  };

  const handleSendMensagem = async () => {
    if (inputValue.trim()) {
      const newMensagens = [...mensagens, { remetente: 'Você', texto: inputValue }];
      setMensagens(newMensagens);
      setInputValue('');
      setDigitando(true);

      try {
        const response = await fetch('https://suapuno.app:8080/api/assistente-virtual/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
          },
          body: JSON.stringify({ pergunta: inputValue }),
        });

        if (response.ok) {
          const data = await response.json();
          const resposta = data.resposta;

          if (resposta) {
            setMensagens([...newMensagens, { remetente: 'Suapinho', texto: resposta }]);
          } else {
            setMensagens([...newMensagens, { remetente: 'Suapinho', texto: 'Desculpe, não foi possível computar sua pergunta, tente novamente mais tarde.' }]);
          }
        } else {
          setMensagens([...newMensagens, { remetente: 'Suapinho', texto: 'Desculpe, ocorreu um erro ao processar sua mensagem, tente novamente mais tarde.' }]);
        }
      } catch (error) {
        setMensagens([...newMensagens, { remetente: 'Suapinho', texto: 'Desculpe, ocorreu um erro ao se comunicar com a nossa API, tente novamente mais tarde.' }]);
      } finally {
        audio.play();
        setDigitando(false);
      }
    }
  };

  const exibirTempo = (currentIndex, messages) => {
    if (currentIndex === 0) {
      return true;
    }
    const tempoAtual = new Date(messages[currentIndex].criacao).getTime();
    const tempoAnterior = new Date(messages[currentIndex - 1].criacao).getTime();
    const diferenca = (tempoAtual - tempoAnterior) / 1000 / 60;
    return diferenca >= 4;
  };

  return (
    <div className="flex flex-col bg-[#898989] h-screen">
      <div className="fixed top-0 left-0 w-full z-20"><MenuTopo /></div>
      <div ref={chatContainerRef} className="flex-1 overflow-y-auto p-4 mb-32 mt-12">
        {linkNext && (
          <div className="flex justify-center mb-2">
            <button
              onClick={fetchMaisHistorico}
              className="px-4 py-2 bg-black text-white rounded-lg text-sm"
            >
              Carregar mais mensagens
            </button>
          </div>
        )}
        {historico.map((mensagem, index) => (
          <React.Fragment key={index}>
            {exibirTempo(index, historico) && (
              <div className='flex justify-center items-center m-5'>
                <div className='bg-black px-3 py-1 rounded-lg text-white text-sm text-center italic'>
                  {new Date(mensagem.criacao).toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false }).replace(',', ' às')}
                </div>
              </div>
            )}
            <div className="flex mb-2 justify-end">
              <div className="flex items-start">
                <div className="p-2 rounded-md text-sm relative bg-[#7eda7d] mr-2">
                  <div className="font-bold">Você</div>
                  <div style={{ whiteSpace: 'pre-wrap' }}>{mensagem.pergunta}</div>
                  <div className="absolute w-0 h-0 border-t-[6px] border-t-transparent border-b-[6px] border-b-transparent border-l-[12px] border-l-[#7eda7d] mt-2 right-0 top-0 -mr-2" />
                </div>
              </div>
            </div>
            <div className="flex mb-2 justify-start">
              <div className="flex items-start">
                <div className="bg-green-700 min-w-10 min-h-10 rounded-full flex items-center justify-center mr-1">
                  <img src='/imagens/SuaPinho.png' alt="Suapinho" style={{ width: '28px', height: '28px' }} />
                </div>
                <div className="p-2 rounded-md text-sm relative bg-white ml-2 text-left">
                  <div className="font-bold">Suapinho</div>
                  <div style={{ whiteSpace: 'pre-wrap' }}>{mensagem.resposta}</div>
                  <div className="absolute w-0 h-0 border-t-[6px] border-t-transparent border-b-[6px] border-b-transparent border-r-[12px] border-r-white mt-2 top-0 -ml-4" />
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
        {mensagens.map((mensagem, index) => (
          <div key={index} className={`flex mb-2 ${mensagem.remetente === 'Você' ? 'justify-end' : 'justify-start'}`}>
            <div className="flex items-start">
              {mensagem.remetente === 'Suapinho' && (
                <div className="bg-green-700 min-w-10 min-h-10 rounded-full flex items-center justify-center mr-1">
                  <img src='/imagens/SuaPinho.png' alt="Suapinho" style={{ width: '28px', height: '28px' }} />
                </div>
              )}
              <div className={`p-2 rounded-md text-sm relative ${mensagem.remetente === 'Suapinho' ? 'bg-white ml-2' : 'bg-[#7eda7d] mr-2'} ${mensagem.remetente === 'Você' ? '' : 'text-left'}`}>
                <div className="font-bold">{mensagem.remetente}</div>
                <div style={{ whiteSpace: 'pre-wrap' }}>{mensagem.texto}</div>
                <div className={`${mensagem.remetente === 'Suapinho' ? 'absolute w-0 h-0 border-t-[6px] border-t-transparent border-b-[6px] border-b-transparent border-r-[12px] border-r-white mt-2 top-0 -ml-4' : 'absolute w-0 h-0 border-t-[6px] border-t-transparent border-b-[6px] border-b-transparent border-l-[12px] border-l-[#7eda7d] mt-2 right-0 top-0 -mr-2'}`} />
              </div>
            </div>
          </div>
        ))}
        {digitando && (
          <div className="flex mb-2 justify-start">
            <div className="flex items-start">
              <div className="p-2 rounded-md text-sm bg-white text-left">
                <div className="font-bold">Suapinho</div>
                <div className='flex -mb-6 -mt-4'>
                  <PiDot size={60} className='animate-bounce -mr-10 -ml-5' />
                  <PiDot size={60} className='animate-bounce -mr-10' style={{ animationDelay: '0.2s' }} />
                  <PiDot size={60} className='animate-bounce -mr-4' style={{ animationDelay: '0.4s' }} />
                </div>
              </div>
            </div>
          </div>
        )}
        <div ref={chatEnd} />
      </div>
      <div className="fixed bottom-1 p-4 flex flex-col w-full mb-12 bg-black rounded-t-xl">
        <input
          type="text"
          placeholder="Digite uma mensagem..."
          className="w-full p-2 pr-[34px] rounded-md bg-black text-white border-[#138d12] border-2 mb-1"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') handleSendMensagem();
          }}
        />
        <PiPaperPlane size={23} color='#138d12' className="absolute right-0 mr-5 mt-2 rotate-45" onClick={handleSendMensagem} />
        <h1 className='text-[10px] text-center italic text-gray-400'>Suapinho pode cometer erros. Certifique-se de revisar suas informações</h1>
      </div>
      <div className="absolute bottom-0 w-full">
        <Menu />
      </div>
    </div>
  );
};

export default Chat;
