import React, { useState, useEffect, useRef } from 'react';
import { PiWarning, PiPlusCircleFill, PiScroll, PiCaretUp, PiCaretDown, PiCheck, PiTrash, PiListChecks, PiPaperPlaneRight, PiArrowCounterClockwise } from "react-icons/pi";
import Avaliacao from './avaliacao';

const Etapas = ({ index, dados, etapaId, discId, handleLancarNotasClick, calcularEtapaClick }) => {
    const [metodoSelecionado, setMetodoSelecionado] = useState(dados.calculo_etapa || 'Média ponderada');
    const [avaliacoes, setAvaliacoes] = useState(dados.avaliacoes || []);
    const [menuDropdownAberto, setMenuDropdownAberto] = useState(false);
    const [removerAvaliacaoId, setRemoverAvaliacaoId] = useState(null);
    const [rascunhoAvaliacao, setRascunhoAvaliacao] = useState(null);
    const [cardAberto, setCardAberto] = useState(false);
    const [timeoutModificacao, setTimeoutModificacao] = useState({});
    const [infoEtapas, setInfoDisciplina] = useState([{}]);
    const token = localStorage.getItem('token');
    const dropdownRef = useRef(null);
    const contentRef = useRef(null);
    const [contentHeight, setContentHeight] = useState('0px');
    const [modalAberto, setModalAberto] = useState(false);
    const [comentarioSolicitacao, setComentarioSolicitacao] = useState('');
    
    useEffect(() => {
        atualizarAvaliações();
        fetch('https://suapuno.app:8080/api/informacoes/etapas-disciplina/', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        .then((resposta) => {
            if (!resposta.ok) {
                throw new Error('Falha ao acessar a API');
            }
            return resposta.json();
        })
        .then((dados) => {
            setInfoDisciplina(dados);
        })
        .catch((erro) => console.error(erro));

        let etapa = dados.etapa;

        fetch(`https://suapuno.app:8080/api/diarios/${discId}/avaliacoes/`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        .then((resposta) => {
            if (!resposta.ok) {
                throw new Error('Falha ao acessar a API');
            }
            return resposta.json();
        })
        .then((dados) => {
            for(let i=0; i<dados.length; i++){
                if(dados[i].etapa === etapa){
                    setMetodoSelecionado(dados[i].calculo_etapa);
                }
            }
        })
        .catch((erro) => console.error(erro));
        // eslint-disable-next-line
    },[]);

    useEffect(() => {
        setContentHeight(cardAberto ? `${contentRef.current.scrollHeight}px` : '0px');
    }, [cardAberto, avaliacoes, rascunhoAvaliacao]);
    
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    useEffect(() => {
        // Atualiza a altura do conteúdo quando rascunhoAvaliacao muda
        if (rascunhoAvaliacao) {
            setContentHeight(`${contentRef.current.scrollHeight}px`);
        }
    }, [rascunhoAvaliacao]);

    const handleMetodoSelecionado = (metodo) => {
        setMetodoSelecionado(metodo);

        let infoEtapaId;

        infoEtapas.map((infoEtapa) => {
            if(infoEtapa.etapa === dados.etapa){
                infoEtapaId = infoEtapa.id_etapa;
            }
            return infoEtapaId;
        })

        fetch(`https://suapuno.app:8080/api/diarios/${discId}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify({
                media_etapa: {
                    id_etapa: infoEtapaId,
                    descricao_calculo_media: metodo
                }
            })
        })
        .then((resposta) => {
            if (!resposta.ok) {
                throw new Error('Falha ao acessar a API');
            }
            return resposta.json();
        })
        .catch((erro) => console.error(erro));

        setMenuDropdownAberto(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setMenuDropdownAberto(false);
        }
    };

    const adicionarAvaliacao = () => {
        const novaAvaliacaoData = {
            id_diario: discId,
            id_etapa: etapaId,
            peso_avaliacao: 1,
            nota_max_avaliacao: 10,
            descricao_avaliacao: '',
            data_avaliacao: new Date().toISOString().split('T')[0]
        };
        setRascunhoAvaliacao(novaAvaliacaoData);
        setCardAberto(true);
    };

    const atualizarAvaliações = () => {
        fetch(`https://suapuno.app:8080/api/diarios/${discId}/avaliacoes/`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        .then((resposta) => {
            if (!resposta.ok) {
                throw new Error('Erro ao buscar avaliações. Por favor, tente novamente mais tarde.');
            }
            return resposta.json();
        })
        .then((dados) => {
            setAvaliacoes(dados[index].avaliacoes);
        })
        .catch((erro) => console.error('Erro ao buscar avaliações:', erro.message));
    };
    
    const removerAvaliacao = (id) => {
        fetch(`https://suapuno.app:8080/api/avaliacao/${id}/`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Token ${token}`
            }
        })
        .then((resposta) => {
            if (!resposta.ok) {
                throw new Error('Erro ao excluir avaliação. Por favor, tente novamente mais tarde.');
            }
            setAvaliacoes(avaliacoes.filter(avaliacao => avaliacao.id_avaliacao !== id));
            setRemoverAvaliacaoId(null);
        })
        .catch((erro) => console.error('Erro ao excluir avaliação:', erro.message));
    };

    const salvarAvaliacao = () => {
        console.log(JSON.stringify(rascunhoAvaliacao))
        fetch(`https://suapuno.app:8080/api/avaliacao/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify(rascunhoAvaliacao)
        })
        .then((resposta) => {
            if (!resposta.ok) {
                throw new Error('Falha ao acessar a API');
            }
            return resposta.json();
        })
        .then(() => {
            setRascunhoAvaliacao(null);
            atualizarAvaliações(); // Busca novamente as avaliações após criação
        })
        .catch((erro) => console.error(erro));
    };

    const handleAvaliacaoChange = (dados) => {
        if (timeoutModificacao[dados.id]) {
            clearTimeout(timeoutModificacao[dados.id]);
        }

        const timeout = setTimeout(() => {
            console.log(`Avaliação modificada: ID=${dados.id_avaliacao}, Dados=${JSON.stringify(dados)}`);

            fetch(`https://suapuno.app:8080/api/avaliacao/${dados.id_avaliacao}/`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
                body: JSON.stringify(dados)
            })
            .then((resposta) => {
                if (!resposta.ok) {
                    throw new Error('Falha ao acessar a API');
                }
            })
            .catch((erro) => console.error(erro));

            setTimeoutModificacao(prev => {
                const updated = { ...prev };
                delete updated[dados.id];
                return updated;
            });
        }, 500);

        setTimeoutModificacao(prev => ({
            ...prev,
            [dados.id]: timeout
        }));
    };

    const handleRascunhoChange = (e) => {
        const { name, value } = e.target;
        setRascunhoAvaliacao(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const abrirModal = () => {
        setModalAberto(true);
    };

    const fecharModal = () => {
        fetch(`https://suapuno.app:8080/api/diarios/${discId}/solicitar-devolucao/${etapaId}/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify({ mensagem: comentarioSolicitacao })
        })
        .then((resposta) => {
            if (!resposta.ok) {
                throw new Error('Falha ao acessar a API');
            }
            setModalAberto(false);
        })
        .catch((erro) => console.error('Erro ao enviar solicitação:', erro));
    };
    
    return (
        <div className="bg-white border-2 p-2 rounded-xl w-full mx-4 mt-2 shadow-lg">
            <div onClick={() => setCardAberto(!cardAberto)} className="flex flex-row font-bold gap-2 cursor-pointer">
                <h1 className="text-2xl ml-1 flex items-center"><PiScroll size={30}/></h1>
                <div className='flex flex-col gap-1'>
                    <h1> {dados.etapa} </h1>
                    <h1 className='text-xs font-semibold text-gray-500'>Método de cálculo: {metodoSelecionado}</h1>
                    <div className='flex items-center'>
                    {avaliacoes.length === 0 ? (
                        <>
                            <div className='h-[10px] w-[10px] bg-black rounded-full'></div>
                            <h1 className='text-xs ml-1 text-black 600 font-semibold'>Sem avaliação</h1>
                        </>
                        ):(
                            <>
                                <div className={`h-[10px] w-[10px] ${dados.status === "Entregue" ? 'bg-green-700' : 'bg-yellow-500'} rounded-full`}></div>
                                <h1 className={`text-xs ml-1 text-black ${dados.status === "Entregue" ? 'text-green-700' : 'text-yellow-500'} 600 font-semibold`}>{dados.status}</h1>
                            </>
                        )
                    }
                    </div>
                </div>
                <h1 className="text-2xl ml-auto flex items-center">
                    {cardAberto ? <PiCaretUp size={30}/> : <PiCaretDown size={30}/>}
                </h1>
            </div>
            <div ref={contentRef} style={{ maxHeight: contentHeight }} className="transition-max-height duration-500 ease-in-out overflow-hidden">
                {cardAberto && (<div className="border-b-[1px] ml-[5px] w-[calc(100%-10px)] my-2 border-gray-400"></div>)}
                {cardAberto && (
                    <div>
                        <div className='flex justify-between'>    
                            <div className='flex items-center'>
                              <div 
                                    className={`${dados.status === "Entregue" ? 'bg-gray-400' : 'bg-green-600' } px-3 py-[2px] ml-1 rounded-xl text-white text-xs`} 
                                    onClick={() => {if(!dados.status === "Entregue") setMenuDropdownAberto(!menuDropdownAberto)}} 
                                    aria-expanded={menuDropdownAberto}
                                >
                                    <div className='flex flex-row'>
                                        {metodoSelecionado}
                                        <PiCaretDown className='mt-[2px] ml-1'/>
                                    </div>
                                </div>
                            </div>
                            <h1 className="text-4xl font-normal"  onClick={() => {if(!dados.status === "Entregue") adicionarAvaliacao()}}><PiPlusCircleFill size={35} color={`${dados.status === "Entregue" ? 'gray' : 'green' }`}/></h1>
                        </div>
                        {menuDropdownAberto && (
                            <div ref={dropdownRef}
                                className={`absolute bg-white border shadow-[0px_0px_10px_3px_rgba(0,0,0,0.2)] -mt-1 p-2 rounded-md z-10`}>
                                {['Média ponderada', 'Média aritmética', 'Soma', 'Soma com pesos', 'Maior nota'].map((metodo) => (
                                    <p
                                        key={metodo}
                                        onClick={() => handleMetodoSelecionado(metodo)}
                                        className="cursor-pointer hover:bg-gray-200"
                                    >
                                        {metodo}
                                    </p>
                                ))}
                            </div>
                        )}
                        <div>
                            {avaliacoes.map((avaliacao, index) => (
                                <div className='flex' key={avaliacao.id}>
                                    <div className="mx-[15px] border-l-[1px] border-black">
                                        <div className='-ml-[10px] -mt-[1px] h-[18px] w-[18px] bg-black rounded-full'>
                                            <h1 className='text-white text-xs ml-[6px] '>{index + 1}</h1>
                                        </div>
                                    </div>
                                    <Avaliacao
                                        dadosAvaliacao={avaliacao}
                                        removerAvaliacao={() => setRemoverAvaliacaoId(avaliacao.id_avaliacao)}
                                        handleLancarNotasClick={handleLancarNotasClick}
                                        handleAvaliacaoChange={handleAvaliacaoChange}
                                        disabled={dados.status === "Entregue"}
                                    />
                                </div>
                            ))}
                            {rascunhoAvaliacao && (
                                <div className="relative text-gray-500 bg-gray-100 p-3 rounded-lg mt-3">
                                    <div className='flex flex-col ml-12 w-[calc(100%-48px)]'>
                                        <h1 className="font-semibold my-1 text-xs">Avaliação:</h1>
                                        <div className="flex">
                                            <input
                                                type="text"
                                                name="descricao_avaliacao"
                                                value={rascunhoAvaliacao.descricao_avaliacao}
                                                onChange={handleRascunhoChange}
                                                className="border-gray-500 border px-3 py-[8px] font-semibold text-sm rounded-lg w-full"
                                                placeholder="Descrição da Avaliação"
                                            />
                                        </div>
                                    </div>
                                    <div className='flex gap-3 ml-12 w-[calc(100%-48px)]'>
                                        <div className='w-full'>
                                            <h1 className="font-semibold my-1 text-xs">Nota máxima:</h1>
                                            <div className="flex">
                                                <input
                                                    type="number"
                                                    name="nota_max_avaliacao"
                                                    value={rascunhoAvaliacao.nota_max_avaliacao}
                                                    onChange={handleRascunhoChange}
                                                    className="border-gray-500 border px-3 py-[8px] font-semibold text-sm rounded-lg w-full"
                                                    placeholder="Nota Máxima"
                                                />
                                            </div>
                                        </div>
                                        <div className='w-full'>
                                            <h1 className="font-semibold my-1 text-xs">Peso da avaliação:</h1>
                                            <div className="flex">
                                                <input
                                                    type="number"
                                                    name="peso_avaliacao"
                                                    value={rascunhoAvaliacao.peso_avaliacao}
                                                    onChange={handleRascunhoChange}
                                                    className="border-gray-500 border px-3 py-[8px] font-semibold text-sm rounded-lg w-full"
                                                    placeholder="Peso da Avaliação"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex flex-col ml-12 w-[calc(100%-48px)]'>
                                        <h1 className="font-semibold my-1 text-xs">Data de avaliação:</h1>
                                        <div className="flex">
                                            <input
                                                type="date"
                                                name="data_avaliacao"
                                                value={rascunhoAvaliacao.data_avaliacao}
                                                onChange={handleRascunhoChange}
                                                className="border-gray-500 border px-3 py-[8px] font-semibold text-sm rounded-lg w-full"
                                            />
                                        </div>
                                    </div>
                                    <div className='flex ml-12 w-[calc(100%-48px)] text-sm font-semibold justify-end'>
                                        <button onClick={() => {setRascunhoAvaliacao(null)}} className="border-2 py-2 px-10 rounded-xl bg-red-600 text-white mt-3 flex items-center justify-center"><PiTrash size={25}/></button>
                                        <button onClick={salvarAvaliacao} className="border-2 py-2 px-10 rounded-xl bg-green-600 text-white mt-3 flex items-center justify-center"><PiCheck size={25}/></button>
                                    </div>
                                </div>
                            )}
                            {(avaliacoes.length > 0 && dados.status === 'Não apropriado') && (<div onClick={() => calcularEtapaClick(dados)} className="text-sm border-2 py-2 px-10 rounded-xl bg-green-700 text-white mt-3 flex items-center justify-center gap-1"><PiListChecks size={20}/> Visualizar para apropriar etapa</div>)}
                            {(avaliacoes.length > 0 && dados.status === 'Apropriado') && (<div onClick={() => calcularEtapaClick(dados)} className="text-sm border-2 py-2 px-10 rounded-xl bg-green-700 text-white mt-3 flex items-center justify-center gap-1"><PiPaperPlaneRight size={20}/> Visualizar para entregar etapa</div>)}
                            {(avaliacoes.length > 0 && (dados.status === 'Entregue' || dados.status === 'Devolução solicitada')) && (<div onClick={() => abrirModal()} className="text-sm border-2 py-2 px-10 rounded-xl bg-red-700 text-white mt-3 flex items-center justify-center gap-1"> <PiArrowCounterClockwise size={20}/> Solicitar acesso à etapa </div> )}
                        </div>
                    </div>
                )}
            </div>
            {removerAvaliacaoId && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <div className='flex flex-row justify-center gap-3 mb-3'>
                            <PiWarning size={45} className='-mt-1'/>
                            <h1 className="text-2xl font-bold">{dados.etapa}</h1>
                        </div>
                        <p className="text-lg font-bold mb-4">Excluir permanentemente esta avaliação?</p>
                        <div className="flex justify-center gap-3">
                            <button className="bg-red-500 text-white px-4 py-2 rounded-md" onClick={() => setRemoverAvaliacaoId(null)}>Cancelar</button>
                            <button className="bg-green-500 text-white px-4 py-2 rounded-md" onClick={() => removerAvaliacao(removerAvaliacaoId)}>Deletar</button>
                        </div>
                    </div>
                </div>
            )}
            {modalAberto && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center z-50" onClick={fecharModal}>
                    <div className='py-2 px-16 bg-[#ff797b] text-white font-semibold rounded-lg z-10'>Comentário de solicitação</div>
                    <div className="bg-white p-4 rounded-lg shadow-lg flex flex-col w-[90%] items-center max-w-md -mt-3" onClick={(e) => e.stopPropagation()}>
                        <textarea
                            className="text-lg font-semibold mb-4 w-full p-2 border border-gray-300 rounded-lg h-52 resize-none"
                            placeholder="Explique o seu motivo para pedir acesso à etapa novamente..."
                            value={comentarioSolicitacao}
                            onChange={(e) => setComentarioSolicitacao(e.target.value)}
                        ></textarea>
                        <button onClick={fecharModal} className='py-2 w-full bg-[#1e731d] text-white font-semibold rounded-lg'>Concluir pedido</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Etapas;