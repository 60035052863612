import React, { useState, useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import { PiEye, PiEyeClosed, PiListBullets, PiPlus, PiTrash } from "react-icons/pi";
import ModalSimilares from '../modalSimilares';

const Contextos = () => {
    const [perguntas, setPerguntas] = useState([]);
    const [linkNext, setLinkNext] = useState('')
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [termoBusca, setTermoBusca] = useState('');
    const [statusSelecionado, setStatusSelecionado] = useState('');
    const [, setVariavel] = useState([]);
    const [novaPergunta, setNovaPergunta] = useState('');
    const [novaResposta, setNovaResposta] = useState('');
    const token = localStorage.getItem('token');
    const [idPerguntaSelecionada, setIdPerguntaSelecionada] = useState(null);
    const [respostaAntiga, setRespostaAntiga] = useState('');
    const [itensPorPagina, setItensPorPagina] = useState(() => {
        if(window.innerHeight > 900){
        return Math.floor(((window.innerHeight - 100) / 90));
        } else if (window.innerHeight < 850) {
        return Math.floor(((window.innerHeight - 100) / 100) - 1);
        } else {
        return Math.floor((window.innerHeight - 100) / 100);
        }
    })

    useEffect(() => {
        const handleResize = () => {
        if(window.innerHeight > 900){
            setItensPorPagina(Math.floor(((window.innerHeight - 100) / 90)));
        } else if (window.innerHeight < 850) {
            setItensPorPagina(Math.floor(((window.innerHeight - 100) / 100) - 1));
        } else {
            setItensPorPagina(Math.floor((window.innerHeight - 100) / 100));
        }
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const itensFiltrados = perguntas.filter ( item => item.pergunta.toLowerCase().includes(termoBusca.toLowerCase()) || item.resposta.toLowerCase().includes(termoBusca.toLowerCase())
    ).filter ( item => statusSelecionado ? item.ocultado === true : true
    ).reverse();

    useEffect(() => {
        fetchDados();
        // eslint-disable-next-line
    }, [token]);

    const fetchDados = async () => {
        try {
        const perguntas = await fetch('https://suapuno.app:8080/api/assistente-virtual/pergunta-resposta/', {
            method: 'GET',
            headers: {
            'Authorization': `Token ${token}`
            }
        });
        if (perguntas.ok) {
            const resposta = await perguntas.json();
            setPerguntas(resposta.results.reverse());
            setLinkNext(resposta.next);
        } else {
            console.log('Api não retornou o ok:', perguntas.status);
        }
        } catch (error) {
        console.log('Erro ao tentar capturar historico de chat.', error);
        }
    };

    const paginasVisiveis = () => {
        const maisPerguntas = async () => {
          try {
              const perguntas = await fetch(linkNext, {
              method: 'GET',
              headers: {
                  'Authorization': `Token ${token}`
              }
              });
              if (perguntas.ok) {
              const resposta = await perguntas.json();
              setPerguntas(prevPeticoes => [...resposta.results.reverse(), ...prevPeticoes])
              setLinkNext(resposta.next);
              } else {
              console.log('Api não retornou o ok:', perguntas.status);
              }
          } catch (error) {
              console.log('Erro ao tentar obter mais contextos.', error);
          }
        };

        const paginas = [];

        if(paginaAtual - 1 > 0){
          paginas.push(paginaAtual - 1)
        }
        paginas.push(paginaAtual)
          if(itensUnicos.slice((paginaAtual - 1) * itensPorPagina, paginaAtual * itensPorPagina).length === itensPorPagina){
          paginas.push(paginaAtual + 1)
        } else {
          maisPerguntas()
        }

        return paginas;
    };

    const adicionarItem = () => {
        if (novaPergunta.trim() && novaResposta.trim()) {
        const novoItem = {
            pergunta: novaPergunta,
            resposta: novaResposta,
        };
        fetch(`https://suapuno.app:8080/api/assistente-virtual/pergunta-resposta/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
            body: JSON.stringify(novoItem)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Erro na requisição: ' + response.statusText);
            }else{
            setPerguntas([...perguntas, novoItem]);
            setNovaPergunta('');
            setNovaResposta('');
            }
        })
    }}
    
    const toggleAtivo = (id_pergunta, resposta) => {
        fetch(`https://suapuno.app:8080/api/assistente-virtual/alterar-visibilidade-contexto/${id_pergunta}/`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        },
        })
        .then(response => {
        if (!response.ok) {
            throw new Error('Erro na requisição: ' + response.statusText);
        }
        })
        setPerguntas(perguntas.map(item => item.resposta === resposta ? { ...item, ocultado: !item.ocultado } : item));
    };
    
    const removerItem = (id_pergunta) => {
        fetch(`https://suapuno.app:8080/api/assistente-virtual/deletar-pergunta/${id_pergunta}/`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        },
        })
        .then(response => {
        if (!response.ok) {
            throw new Error('Erro na requisição: ' + response.statusText);
        }
        })
        setPerguntas(perguntas.filter(item => item.id_pergunta !== id_pergunta));
    }; 
    
    const fecharModal = () => {
        setIdPerguntaSelecionada(null);
    }

    const filtrarRespostasDuplicadas = (itens) => {
        const respostasVistas = new Set();
        return itens.filter(item => {
        const correspondeBusca = termoBusca 
            ? item.pergunta.toLowerCase().includes(termoBusca.toLowerCase()) || item.resposta.toLowerCase().includes(termoBusca.toLowerCase())
            : false;
    
        if (respostasVistas.has(item.resposta) && !correspondeBusca) {
            return false;
        } else {
            respostasVistas.add(item.resposta);
            return true;
        }
        });
    };

    // Função para filtrar itens duplicados
    const filtrarDuplicados = (itens) => {
    const idsVistos = new Set();
    return itens.filter(item => {
        if (idsVistos.has(item.id_pergunta)) {
            return false;
        } else {
            idsVistos.add(item.id_pergunta);
            return true;
        }
    });
    };
  
    // Aplica o filtro antes de mapear
    const itensUnicos = filtrarDuplicados(filtrarRespostasDuplicadas(itensFiltrados));

    const handleInputChange = (id, field, value) => {
        setPerguntas(perguntas.map(item => item.id_pergunta === id ? {...item, [field]: value}: item))
    };

    const handleKeypress = (e, id_pergunta, resposta) => {
        if(e.key === 'Enter'){
        const itemAtualizado = perguntas.find(item => item.id_pergunta === id_pergunta)
        if(itemAtualizado){
            atualizaPergunta(itemAtualizado, id_pergunta, resposta);
        }
        }
    }

    const handleBlur = (id_pergunta, resposta) => {
        const itemAtualizado = perguntas.find(item => item.id_pergunta === id_pergunta)
        if(itemAtualizado){
        atualizaPergunta(itemAtualizado, id_pergunta, resposta);
        }
    }

    const atualizaPergunta = (itemAtualizado, id_pergunta) => {
        const novoItem = {
        pergunta: itemAtualizado.pergunta,
        resposta: itemAtualizado.resposta,
        };
        fetch(`https://suapuno.app:8080/api/assistente-virtual/pergunta-resposta/${id_pergunta}/`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        },
        body: JSON.stringify(novoItem)
        })
        .then(response => {
        if (!response.ok){
            throw new Error('Erro na requisição: '+ response.status);
        } else {
            setPerguntas(prevPerguntas => prevPerguntas.map(item => item.resposta === respostaAntiga ? { ...item, resposta: itemAtualizado.resposta } : item));
        }
        })
        .catch(error => {
        console.log('Erro ao salvar alterações na pergunta.', error);
        })
    };

  return (
    <div className='flex h-fit'>
      { idPerguntaSelecionada && 
        <div className='absolute h-full w-full z-10 -inset-0'>
          <ModalSimilares 
            id_pergunta={idPerguntaSelecionada} 
            fecharModal={fecharModal} 
            itensPorPagina={itensPorPagina} 
            paginasVisiveis={paginasVisiveis} 
            remover={removerItem}
            handleInputChangeOutro={handleInputChange}
          />
        </div>
      }
      <div className="flex flex-col flex-grow">
        <div className="flex flex-col h-auto">
            <div className='bg-white py-2 px-5 rounded-b-lg rounded-tr-lg'>
              <div className="flex flex-row gap-3 mt-2 items-center w-full">
                <h1 className='font-semibold text-center'>Filtros: </h1>
                <div className="flex flex-col w-full relative">
                  <input
                    className="border border-gray-400 bg-gray-300 rounded-md w-full pl-10 py-1"
                    placeholder="Busque por perguntas/contextos..."
                    value={termoBusca}
                    onChange={(e) => {
                      setTermoBusca(e.target.value);
                      setPaginaAtual(1);
                    }}
                  />
                  <span className="absolute mt-[8px] ml-2 text-gray-400"><FaSearch size={20} /></span>
                </div>
                <h1>Variáveis:</h1>
                <div className="flex flex-col">
                  <select
                    className="border border-gray-400 bg-gray-300 rounded-md w-[100px] py-[5px]"
                    value={statusSelecionado}
                    onChange={(e) => setStatusSelecionado(e.target.value)}
                  >
                    <option value=""></option>
                    <option value="Ocultado">Ocultado</option>
                  </select>
                </div>
                <button
                  className="bg-red-700 text-white py-1 w-56 px-3 rounded-xl"
                  onClick={() => {
                    setTermoBusca('');
                    setStatusSelecionado('');
                    setVariavel([]);
                    setPaginaAtual(1)
                  }}
                >
                  REMOVER FILTROS
                </button>
              </div>
              <div className="mt-8 w-full flex gap-2">
                <div className="flex flex-row gap-2 w-[85%]">
                  <div className='relative border border-[#1e731d] bg-white rounded-md p-2 w-1/2'>
                      <input
                      type="text"
                      className="w-full outline-none"
                      value={novaPergunta}
                      onChange={(e) => setNovaPergunta(e.target.value)}
                      />
                      <h1 className='absolute -mt-11 bg-white p-1 text-xs text-[#1e731d]'>NOVA PERGUNTA/TEMA</h1>
                  </div>
                  <input
                    type="text"
                    className="border border-[#1e731d] bg-white rounded-md p-2 w-1/2 outline-none"
                    placeholder="NOVA RESPOSTA/CONTEXTO"
                    value={novaResposta}
                    onChange={(e) => setNovaResposta(e.target.value)}
                  />
                </div>
                <div className='w-[15%] flex'>
                  <button
                    className="bg-[#1e731d] text-white py-1 px-3 rounded-xl w-full flex items-center gap-2 justify-center"
                    onClick={adicionarItem}
                    >
                    <PiPlus size={20}/> Adicionar novo contexto
                  </button>
                </div>
              </div>
              { itensUnicos.length > 0 ? ( 
                <div className="mt-4 rounded-lg overflow-hidden border-[1px] border-[#1E731D]">
                  <table className="w-full">
                    <thead className="bg-[#1E731D] text-white   ">
                      <tr>
                        <th className="w-2/10 text-center text-[#1E731D]">........</th>
                        <th className="w-3/10 text-center py-5">Pergunta/Tema</th>
                        <th className="w-4/10 text-center">Resposta/Contexto</th>
                        <th className="w-1/10 text-center">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {itensUnicos.slice((paginaAtual - 1) * itensPorPagina, paginaAtual * itensPorPagina).map(item => (
                        <tr key={item.id_pergunta} className={`${item.ocultado ? 'bg-gray-300' : 'bg-white'}`}>
                          <td className="text-center py-2">
                            <button onClick={() => setIdPerguntaSelecionada(item.id_pergunta)}>
                              <PiListBullets color='white' className='bg-[#1E731D] rounded-full p-1' size={25} />
                            </button>
                          </td>
                          <td>
                            <input
                              type='text'
                              value={item.pergunta}
                              onChange={(e) => handleInputChange(item.id_pergunta, 'pergunta', e.target.value)}
                              onKeyDown={(e) => handleKeypress(e, item.id_pergunta, item.resposta)}
                              onBlur={() => handleBlur(item.id_pergunta, item.resposta)}
                              className={`text-center py-2 truncate w-full ${item.ocultado ? 'line-through bg-gray-300' : ''}`}
                            />
                          </td>
                          <td>
                            <input
                              type='text'
                              value={item.resposta}
                              onFocus={() => setRespostaAntiga(item.resposta)}
                              onChange={(e) => handleInputChange(item.id_pergunta, 'resposta', e.target.value)}
                              onKeyDown={(e) => handleKeypress(e, item.id_pergunta, item.resposta)}
                              onBlur={() => handleBlur(item.id_pergunta, item.resposta)}
                              className={`text-center py-2 truncate w-full ${item.ocultado ? 'line-through bg-gray-300' : ''}`}
                            />
                          </td>
                          <td className="text-center py-2">
                            <button onClick={() => toggleAtivo(item.id_pergunta, item.resposta)} className="text-blue-500 mx-1">
                              {!item.ocultado ? (
                                <PiEye color='white' className='bg-[#b2a10a] rounded-full p-1' size={25} />
                              ) : (
                                <PiEyeClosed color='white' className='bg-black rounded-full p-1' size={25} />
                              )}
                            </button>
                            <button onClick={() => removerItem(item.id_pergunta)} className="text-red-500 mx-1">
                              <PiTrash color='white' className='bg-[#a30002] rounded-full p-1' size={25} />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                ):(
                  <div className='flex mt-4 w-full justify-center items-center'>
                    <img className='' src='/imagens/loading.gif'></img>
                  </div>
                )}
              <div className="flex justify-center mt-4 gap-2 -ml-12">
                {paginaAtual > 2 && (
                  <div className=' flex'>
                    <button onClick={() => setPaginaAtual(1)} className={`px-3 py-1 rounded-full bg-gray-400`}>{1}</button>
                    <h1 className='ml-2 mt-2 font-semibold'>. . .</h1>
                  </div>
                )}
                {paginasVisiveis().map((pagina, index) => (
                  <button key={index} onClick={() => setPaginaAtual(pagina)} className={`px-3 py-1 rounded-full ${pagina === paginaAtual ? 'bg-green-600 text-white' : 'bg-gray-400'}`}>{pagina}</button>
                ))}
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Contextos;