import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import cursos from './cursos.json';
import anos from './anos.json';
import MaskedInput from 'react-text-mask'

const DadosAluno = () => {
    const navigate = useNavigate();
    const [telefone, setTelefone] = useState('');
    const [telefoneValido, setTelefoneValido] = useState(true);
    const [email, setEmail] = useState('');
    const [emailValido, setEmailValido] = useState(true);
    const [listaCursos] = useState(cursos);
    const [listaAnos] = useState(anos);
    const [formulario, setFormulario] = useState({
        nomeAluno: '',
        IdadeAluno: '',
        Matricula: '',
        Curso: '',
        anoIngresso: ''
    });

    useEffect(() => {
        const dadosPessoaisArmazenados = localStorage.getItem('dados_pessoais');
        if (dadosPessoaisArmazenados) {
            const dadosFormatados = JSON.parse(dadosPessoaisArmazenados);
            setFormulario({
                nomeAluno: dadosFormatados.nome_aluno || '',
                IdadeAluno: dadosFormatados.data_nascimento || '',
                Matricula: dadosFormatados.matricula_aluno || '',
                Curso: dadosFormatados.id_curso || '',
                anoIngresso: dadosFormatados.id_ano_semestre_ingresso || ''
            });
            setTelefone(dadosFormatados.contato_aluno || '');
            setEmail(dadosFormatados.e_mail_aluno || '');
        }
    }, []);    
    
    const btnCancelar = () => {
        localStorage.removeItem('peticao');
        localStorage.removeItem('arquivo1');
        localStorage.removeItem('arquivo2');
        navigate('/sua-petição', { replace: true });
        window.location.reload();
    };


    function btnVoltar() {
        salvaParaLocastorage();
        navigate('/sua-petição/nova');
    }

    const btnSalvar = () => {
        if (!formCompleto()) {
            alert('Por favor, preencha todos os campos obrigatórios.');
            return;
        }
        salvaParaLocastorage();
    };

    const salvaParaLocastorage = () => {
        if (camposPreenchidos()) {
            const dadosPessoais = {
                nome_aluno: formulario.nomeAluno,
                data_nascimento: formulario.IdadeAluno,
                id_curso: formulario.Curso,
                id_ano_semestre_ingresso: formulario.anoIngresso,
                contato_aluno: telefone,
                e_mail_aluno: email,
                matricula_aluno: formulario.Matricula
            };
            localStorage.setItem('dados_pessoais', JSON.stringify(dadosPessoais));
            navigate('/sua-petição/dados-bancarios');
        } 
    }

    function handleEmailChange(event) {
        const inputEmail = event.target.value;
        const valido = validarEmail(inputEmail);

        setEmail(inputEmail);
        setEmailValido(valido);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormulario(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const camposPreenchidos = () => {
        return (
            telefone.trim() !== '' &&
            email.trim() !== ''
        );
    };

    function handleTelefoneChange(event) {
        const inputTelefone = event.target.value;
        const telefoneFormatado = inputTelefone
            .replace(/\D/g, '') // Remover caracteres não numéricos
            .replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3'); // Formatar para (XX) XXXXX-XXXX
    
        const valido = validarTelefone(telefoneFormatado);
        
        setTelefone(telefoneFormatado);
        setTelefoneValido(valido);
    }
    
    function validarTelefone(telefone) {
        const telefoneRegex = /^\([1-9]{2}\)\s[9]?[0-9]{4}-[0-9]{4}$/;
        const apenasDigitos = telefone.replace(/\D/g, ''); // Remover formatação para verificar se há apenas dígitos
        
        return telefoneRegex.test(telefone) && apenasDigitos.length === 11; // Verificar se a formatação e o número de dígitos estão corretos
    }
    

    function validarEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    const formCompleto = () => {
        if (Object.values(formulario).every(value => value !== "") && emailValido && telefoneValido){
            return true;
        }
    };

    return (
        <div className="flex flex-col h-full w-screen">
            <div className="flex flex-col items-center justify-center">
                <h1 className="font-bold mt-2">Tipo de Processo</h1>
                <h1 className="mx-16 text-center">Auxilio Acadêmico (evento de ensino, pesquisa e extensão)</h1>
            </div>
            <div className="flex px-3 items-center justify-center gap-3 mt-5 mx-3">
                <div>
                    <h1 className="text-white bg-green-500 w-12 h-12 flex items-center justify-center rounded-full">1</h1>
                    <h1 className="w-14 text-center">Sobre o evento</h1>
                </div>
                <div className="border-b-2 border-gray-500 flex-grow"></div>
                <div>
                    <h1 className="text-white bg-black w-12 h-12 flex items-center justify-center rounded-full">2</h1>
                    <h1 className="w-12 text-center">Dados <span className="-ml-1">Pessoais</span></h1>
                </div>
                <div className="border-b-2 border-gray-500 flex-grow"></div>
                <div>
                    <h1 className="text-white bg-gray-500 w-12 h-12 flex items-center justify-center rounded-full">3</h1>
                    <h1 className="w-12 text-center">Dados <span className="-ml-2">Bancários</span></h1>
                </div>
            </div>
            <div className="grid m-5 gap-5">
                <div className="justify-center items-center bg-green-500 text-center p-2 rounded-lg w-80 mx-auto text-white z-10 font-bold text-lg">
                    Sobre seus dados pessoais
                </div>
                <div className="bg-white p-4 -mt-10 rounded-lg shadow-[0px_0px_10px_7px_rgba(0,0,0,0.2)]">
                    <div className="font-bold text-sm mt-3">
                        <h1>Nome:</h1>
                        <input className="mt-1 bg-gray-300 border-gray-400 border rounded-md p-2 w-full" type="text" name="nomeAluno" value={formulario.nomeAluno} onChange={handleChange}/>
                    </div>
                    <div className="font-bold text-sm mt-3">
                        <h1>Data Nascimento:</h1>
                        <input className="mt-1 bg-gray-300 border-gray-400 border rounded-md p-2 w-full" type="date" name="IdadeAluno" value={formulario.IdadeAluno} onChange={handleChange}/>
                    </div>
                    <div className="font-bold text-sm mt-3">
                        <h1>Matricula:</h1>
                        <input className="mt-1 bg-gray-300 border-gray-400 border rounded-md p-2 w-full" type="number" name="Matricula" value={formulario.Matricula} onChange={handleChange}/>
                    </div>
                    <div className="font-bold text-sm mt-3">
                        <h1>Curso:</h1>
                        <select name='Curso' className="mt-1 bg-gray-300 border-gray-400 border rounded-md p-2 w-full" value={formulario.Curso} onChange={handleChange}>
                            <option value=''>Selecione</option>
                            {listaCursos.map(curso => (
                                <option key={curso.id} value={curso.id}>{curso.nome}</option>
                            ))}
                        </select>
                    </div>
                    <div className="font-bold text-sm mt-3">
                        <h1>Ano de ingresso:</h1>
                        <select name='anoIngresso' className="mt-1 bg-gray-300 border-gray-400 border rounded-md p-2 w-full" value={formulario.anoIngresso} onChange={handleChange}>
                            <option value=''>Selecione</option>
                            {listaAnos.map(ano => (
                                <option key={ano.id} value={ano.id}>{ano.ano_semestre}</option>
                            ))}
                        </select>
                    </div>
                    <div className="font-bold text-sm mt-3">
                        <h1>Telefone:</h1>
                        <MaskedInput
                            className={`mt-1 bg-gray-300 border ${telefoneValido ? 'border-gray-400' : 'border-red-500'} rounded-md p-2 w-full`}
                            mask={['(', /[1-9]/, /\d/, ')', ' ', /[9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                            value={telefone}
                            onChange={handleTelefoneChange}
                        />
                        {!telefoneValido && <p className="text-red-500 text-sm mt-1">Por favor, insira um número de telefone válido.</p>}
                    </div>
                    <div className="font-bold text-sm mt-3">
                        <h1>E-Mail:</h1>
                        <input 
                            className={`mt-1 bg-gray-300 border ${emailValido ? 'border-gray-400' : 'border-red-500'} rounded-md p-2 w-full`} 
                            type="email" 
                            name="Email" 
                            value={email} 
                            onChange={handleEmailChange} 
                        />
                        {!emailValido && <p className="text-red-500 text-sm mt-1">Por favor, insira um e-mail válido.</p>}
                    </div>
                </div>
                <div className="flex justify-end gap-5">
                    <button className='px-3 py-1 w-30 bg-red-600 rounded-lg text-white' onClick={btnCancelar}>Cancelar</button>
                    <button className='px-3 py-1 w-30 bg-green-600 rounded-lg text-white' onClick={btnVoltar}>Anterior</button>
                    <button className={`px-3 py-1 w-30 bg-green-600 rounded-lg text-white ${!formCompleto() ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={!formCompleto()} onClick={btnSalvar}>Proseguir</button>
                </div>
            </div>
        </div>
    );
};

export default DadosAluno;
