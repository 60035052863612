import React, { useEffect, useState } from 'react';
import { PiWarningCircle } from "react-icons/pi";
import { isBrowser } from 'react-device-detect';
import Menu from "../../../components/ui/menuTopo";
import GerenciarPeticoes from './gerenciarPeticoes';
import SuaPinho from './suaPinho/suaPinho';

const Peticao = () => {
  const [renderAba, setRenderAba] = useState(1);

  useEffect(() => {
    const abaSelecionada = localStorage.getItem('abaSelecionada');
    if (abaSelecionada) {
      setRenderAba(Number(abaSelecionada));
    }
  }, []);

  const aba = (selecao) => {
    localStorage.setItem('abaSelecionada', selecao);
    setRenderAba(selecao);
  }

  if (!isBrowser) {
    return (
      <div className="flex flex-col items-center justify-center h-full text-center">
        <div className="top-0 left-0 w-full"><Menu /></div>
        <div className='flex flex-col justify-center items-center mt-[calc(50%)]'>
          Site não disponível para dispositivos móveis, por favor acesse de um computador.
          <PiWarningCircle size={100} color='red' />
        </div>
      </div>
    );
  }

  return (
    <div className='flex h-screen'>
      <div className="max-w-60 min-w-60 bg-[#afdeaf] text-black flex flex-col">
        <div className="logo-area bg-white border-r border-gray-400">
          <img src='./imagens/suapUNO.png' alt='LogoSuap'/>
        </div>
        <nav className="flex items-center flex-col p-4 relative">
          <div onClick={() => aba(1)} className={`absolute py-2 w-full item-center text-center font-semibold text-lg ${renderAba === 1 ? 'bg-[#66a866] text-white' : 'bg-transparent'}`} >
            Gerenciar petições
          </div>
          <div onClick={() => aba(2)} className={`absolute mt-14 py-2 w-full item-center text-center font-semibold text-lg  ${renderAba === 2 ? 'bg-[#66a866] text-white' : 'bg-transparent'}`} >
            SuaPinho
          </div>
        </nav>
      </div>
      <div className="flex flex-col flex-grow bg-gray-200">
        <Menu />
        { renderAba === 1 && <GerenciarPeticoes/> }
        { renderAba === 2 && <SuaPinho/> }
      </div>
    </div>
  );
};

export default Peticao;
