import React, { useEffect, useState } from 'react';
import { FaSearch, FaRegFileAlt } from 'react-icons/fa';
import { PiArrowLeft, PiArrowRight, PiClipboardText, PiWarningCircle } from "react-icons/pi";
import { isBrowser } from 'react-device-detect';
import Menu from "../../../components/ui/menuTopo";

const Painel = () => {
  const token = localStorage.getItem('token');
  const id = localStorage.getItem('id_user');
  const [peticoes, setPeticoes] = useState([]);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [termoBusca, setTermoBusca] = useState('');
  const [showDetails, setShowDetails] = useState(false);
  const [selectedPeticao, setSelectedPeticao] = useState(null);
  const [, setDadosUser] = useState([]);
  const [assistenteFilter, setAssistenteFilter] = useState('nenhuma');
  const itensPorPagina = Math.floor(window.innerHeight / 200);
  const itensPorBloco = 5;
  const [renderAba, setRenderAba] = useState(1)

  useEffect(() => {
    fetch(`https://suapuno.app:8080/api/solicitacoes-etapas/`, {
      method: 'GET',
      headers: {
        'Authorization': `Token ${token}`,
      }
    })
    .then(response => response.json())
    .then((data) => {
      setPeticoes(data);
      console.log(data)
    })
    .catch(error => {
      console.error('Erro ao carregar dados da tabela:', error);
    });

    fetch(`https://suapuno.app:8080/api/usuarios/${id}/`, {
      method: 'GET',
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Erro ao acessar a API');
      }
      return response.json();
    })
    .then(data => {
      setDadosUser(data);
    })
    .catch(error => {
      console.error('Erro ao buscar dados:', error);
    });
  },);

  const indexUltimo = paginaAtual * itensPorPagina;
  const indexOfPrimeiroItem = indexUltimo - itensPorPagina;

  const itensFiltrados = peticoes.filter(item => {
    const matchCurso = item.curso.toLowerCase().includes(termoBusca.toLowerCase()) || item.disciplina.toLowerCase().includes(termoBusca.toLowerCase()) || item.etapa.toLowerCase().includes(termoBusca.toLowerCase()) || item.professor.toLowerCase().includes(termoBusca.toLowerCase());
    const matchAssistente = assistenteFilter === 'nenhuma' || (assistenteFilter === 'assistente' && item.assistente);
    return matchCurso && matchAssistente;
  }).reverse();

  const currentItems = itensFiltrados.slice(indexOfPrimeiroItem, indexUltimo);

  const paginasTotais = Math.ceil(itensFiltrados.length / itensPorPagina);

  const paginasVisiveis = () => {
    const paginas = [];
    let inicio = paginaAtual - Math.floor(itensPorBloco / 2);
    inicio = Math.max(inicio, 1);
    const fim = Math.min(inicio + itensPorBloco - 1, paginasTotais);

    for (let i = inicio; i <= fim; i++) {
      paginas.push(i);
    }

    return paginas;
  };

  const handleAnalisarClick = (peticao) => {
    setSelectedPeticao(peticao);
    setShowDetails(true);
  };

  const handleCloseWidget = () => {
    setShowDetails(false);
    setSelectedPeticao(null);
  };

  const handleResetFilters = () => {
    setTermoBusca('');
    setAssistenteFilter('nenhuma');
    setPaginaAtual(1);
  };

  const liberarEtapa = () => {
    fetch(`https://suapuno.app:8080/api/solicitacoes-etapas/${selectedPeticao.id_devolucao_etapa}/`, {
      method: 'PUT',
      headers: {
        'Authorization': `Token ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ status: 'aceito' }),
    })
    .then(response => response.json())
    .catch(error => {
      console.error('Erro ao atualizar estado da etapa:', error);
    });
  };

  const negarEtapa = () => {
    fetch(`https://suapuno.app:8080/api/solicitacoes-etapas/${selectedPeticao.id_devolucao_etapa}/`, {
      method: 'PUT',
      headers: {
        'Authorization': `Token ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ status: 'rejeitado' }),
    })
    .then(response => response.json())
    .catch(error => {
      console.error('Erro ao atualizar estado da etapa:', error);
    });
  };

  if (!isBrowser) {
    return (
      <div className="flex flex-col items-center justify-center h-full text-center">
        <div className="top-0 left-0 w-full"><Menu /></div>
        <div className='flex flex-col justify-center items-center mt-[calc(50%)]'>
          Site não disponível para dispositivos móveis, por favor acesse de um computador.
          <PiWarningCircle size={100} color='red' />
        </div>
      </div>
    );
  }
  
  return (
    <div className="flex h-screen">
      <div className="max-w-60 min-w-60 bg-[#afdeaf] text-black flex flex-col">
        <div className="logo-area bg-white border-r border-gray-400">
          <img src='./imagens/suapUNO.png' alt='LogoSuap'/>
        </div>
        <nav className="flex items-center flex-col p-4 relative">
          <div onClick={() => setRenderAba(1)} className={`absolute py-2 w-full item-center text-center font-semibold text-lg ${renderAba === 1 ? 'bg-[#66a866] text-white' : 'bg-transparent'}`} >
            Pedidos
          </div>
        </nav>
      </div>
      <div className="flex flex-col flex-grow bg-gray-200">
        <Menu />
        { renderAba===1 &&
          <div className="flex flex-col h-auto px-10 pt-5">
            <div className='flex'>
              <h1 className='text-gray-500 text-sm'>
                Auxílio acadêmico &gt; Gerenciar mensagens {showDetails && `> ${selectedPeticao.disciplina}`}
              </h1>
            </div>
            {!showDetails ? (
              <>
                <h1 className="text-[40px] font-semibold mb-1">Solicitações de Relançamento</h1>
                <div className="flex flex-row gap-3 mt-2 items-center">
                  <h1 className='font-semibold text-center'>Filtros: </h1>
                  <div className="flex flex-col">
                    <input
                      className="border border-gray-400 bg-gray-300 rounded-md w-full pl-10 py-1"
                      placeholder="Busque por solicitação..."
                      value={termoBusca}
                      onChange={(e) => {
                        setTermoBusca(e.target.value);
                        setPaginaAtual(1);
                      }}
                    />
                    <span className="absolute mt-[8px] ml-2 text-gray-400"><FaSearch size={20} /></span>
                  </div>
                  <h1>Resolvidas por:</h1>
                  <div className="flex flex-col">
                    <select
                      className="border border-gray-400 bg-gray-300 rounded-md w-[200px] pl-10 py-[5px]"
                      value={assistenteFilter}
                      onChange={(e) => {
                        setAssistenteFilter(e.target.value);
                        setPaginaAtual(1);
                      }}
                    >
                      <option value="nenhuma"></option>
                      <option value="assistente">Assistente</option>
                    </select>
                  </div>
                  <button
                    className="bg-red-700 text-white py-1 w-56 px-3 rounded-xl"
                    onClick={handleResetFilters}
                  >
                    REMOVER FILTROS
                  </button>
                </div>
                <div className="mt-4">
                <table className="w-full rounded-lg overflow-hidden">
                  <thead className="bg-[#1E731D] text-white">
                    <tr>
                      <th className="w-1/5 text-center py-5">Curso</th>
                      <th className="w-1/5 text-center">Disciplina</th>
                      <th className="w-1/5 text-center">Professor</th>
                      <th className="w-1/5 text-center">Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((peticao, index) => (
                      <tr key={index} className={`${peticao.status === "pendente" ? 'bg-[#d4d4d4] font-semibold' :'bg-[#c0c0c0] text-black '}`}>
                        <td className='flex ml-16 py-6 text-start items-center'>{peticao.curso}</td>
                        <td className=''>
                          <span className='flex flex-row text-center justify-center'>
                            {peticao.disciplina} - {peticao.etapa}
                          </span>
                        </td>
                        <td className='text-center'>{peticao.professor}</td>
                        <td className='text-center'>
                          <button className='py-2 px-5 rounded-md border-2 border-black' onClick={() => handleAnalisarClick(peticao)}>
                            <span className='flex flex-row justify-center gap-1'>
                              <FaRegFileAlt size={30} color='black'/>
                              Analisar
                            </span>
                          </button>   
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                </div>
                <div className="flex justify-center mt-4 gap-2">
                  {paginasVisiveis().map((pagina, index) => (
                    <button key={index} onClick={() => setPaginaAtual(pagina)} className={`px-3 py-1 rounded-full ${pagina === paginaAtual ? 'bg-green-600 text-white' : 'bg-gray-400'}`}>{pagina}</button>
                  ))}
                </div>
              </>
            ) : (
              <div className="mt-4">
                <button className="mb-4" onClick={handleCloseWidget}>
                  <PiArrowLeft size={20}/>
                </button>
                <h2 className="text-4xl font-semibold mb-1">{selectedPeticao.disciplina} - {selectedPeticao.ano_semestre}</h2>
                <p className="text-gray-400 font-semibold mb-2 italic">{selectedPeticao.curso}</p>
                <p className="text-gray-400 font-semibold italic text-lg mb-4"><strong className="text-black font-semibold not-italic">Solicitação feita por:</strong> {selectedPeticao.professor}</p>
                <p className='text-black font-semibold text-xl'>Comentario</p>
                <p className='text-gray-400 mt-2 mb-4 text-xl'>{selectedPeticao.mensagem}</p>
                <p className='font-semibold text-xl flex items-center'>Etapa <span className='mt-1 mx-2'><PiArrowRight size={20}/></span><span className='text-sm text-white py-2 px-5 rounded-lg bg-green-500 mt-1'>{selectedPeticao.etapa}</span> </p>
                {selectedPeticao.status === "pendente" && <div className="flex flex-col justify-center gap-6 mx-6">
                  <button onClick={() => liberarEtapa()} className='flex w-full items-center justify-center mt-10 bg-green-700 text-white py-3 rounded-lg text-lg gap-3'><PiClipboardText/> Liberar Etapa</button>
                  <button onClick={() => negarEtapa()} className='flex w-full items-center justify-center mt-2 bg-red-700 text-white py-3 rounded-lg text-lg gap-3'><PiClipboardText/> Rejeitar Etapa</button>
                </div>}
              </div>
            )}
          </div>
        }
      </div>
    </div>
  );
};

export default Painel;